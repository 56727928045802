import { firestore } from "../configurations/firebaseConfig";
import { collection, getDocs, query, where, addDoc } from "firebase/firestore";
import { getTimelinePointById } from "./timelinePoints";

const createMeasurement = async ({
  name,
  type,
  timelinePointId,
  drawingPoints,
  values,
  measurementTerrainData,
}) => {
  if (timelinePointId) {
    const timelinePointExists = await getTimelinePointById(timelinePointId);
    if (!timelinePointExists) {
      throw new Error("Timeline Point not found");
    }
    const measurementNameExists = await getTimelineMeasurementByName({
      name: name,
      timelinePointId,
    });
    if (measurementNameExists) {
      throw new Error("Measurement with the same name already exists");
    }
    return measurementTerrainData
      ? addDoc(collection(firestore, "measurements"), {
          name,
          type,
          timelinePointId,
          drawingPoints,
          values,
          measurementTerrainData,
          date: new Date(),
        })
      : addDoc(collection(firestore, "measurements"), {
          name,
          type,
          timelinePointId,
          drawingPoints,
          values,
          date: new Date(),
        });
  }
};

const getTimelineMeasurementByName = async ({ timelinePointId, name }) => {
  let foundTimelinePoint;
  const timelineQuery = query(
    collection(firestore, "measurements"),
    where("timelinePointId", "==", timelinePointId),
    where("name", "==", name)
  );
  const querySnapshot = await getDocs(timelineQuery);
  querySnapshot.forEach((doc) => {
    foundTimelinePoint = {
      id: doc.id,
      ...doc.data(),
    };
  });
  return foundTimelinePoint;
};

const fetchMeasurementsByTimelinePointId = async (timelinePointId) => {
  const foundMeasurements = [];
  const q = query(
    collection(firestore, "measurements"),
    where("timelinePointId", "==", timelinePointId)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundMeasurements.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return foundMeasurements;
};

export { createMeasurement, fetchMeasurementsByTimelinePointId };
