import { useEffect, useState } from "react";
import { Button, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const TimelineMenu = ({ timelinePoints, timelapseData }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isTwoDAvailable, setIsTwoDAvailable] = useState(false);
  const [isThreeDAvailable, setIsThreeDAvailable] = useState(false);
  const [isScanDataAvailable, setIsScanDataAvailable] = useState(false);
  const [isTimelapseDataAvailable, setIsTimelapseDataAvailable] =
    useState(false);

  useEffect(() => {
    if (timelinePoints && timelinePoints.length) {
      timelinePoints.forEach((timelinePoint) => {
        if (timelinePoint.twoDData && timelinePoint.twoDData.length) {
          setIsTwoDAvailable(true);
        }
        if (timelinePoint.threeDData && timelinePoint.threeDData.length) {
          setIsThreeDAvailable(true);
        }
        if (timelinePoint.scanData && timelinePoint.scanData.length) {
          setIsScanDataAvailable(true);
        }
        if (timelapseData && timelapseData.length) {
          setIsTimelapseDataAvailable(true);
        }
      });
    }
  }, [timelinePoints, timelapseData]);

  return (
    <div>
      <Menu
        items={[
          {
            label: (
              <div className="timeline-menu-container">
                {!pathname.endsWith("timeline/2D") && (
                  <div>
                    <Button
                      disabled={!isTwoDAvailable}
                      type="text"
                      className="timeline-button"
                      onClick={() => {
                        const newRoute = pathname.includes("deliverables")
                          ? pathname.substring(
                              0,
                              pathname.indexOf("deliverables")
                            ) + `timeline/2D`
                          : pathname.substring(
                              0,
                              pathname.indexOf("timeline")
                            ) + `timeline/2D`;
                        navigate(newRoute);
                      }}
                    >
                      2D Timeline
                    </Button>
                  </div>
                )}
                {!pathname.endsWith("timeline/3D") && (
                  <div>
                    <Button
                      disabled={!isThreeDAvailable}
                      type="text"
                      className="timeline-button"
                      onClick={() => {
                        const newRoute = pathname.includes("deliverables")
                          ? pathname.substring(
                              0,
                              pathname.indexOf("deliverables")
                            ) + `timeline/3D`
                          : pathname.substring(
                              0,
                              pathname.indexOf("timeline")
                            ) + `timeline/3D`;
                        navigate(newRoute);
                      }}
                    >
                      3D Timeline
                    </Button>
                  </div>
                )}
                {!pathname.endsWith("timeline/3DScan") && (
                  <div>
                    <Button
                      disabled={!isScanDataAvailable}
                      type="text"
                      className="timeline-button"
                      onClick={() => {
                        const newRoute = pathname.includes("deliverables")
                          ? pathname.substring(
                              0,
                              pathname.indexOf("deliverables")
                            ) + `timeline/3DScan`
                          : pathname.substring(
                              0,
                              pathname.indexOf("timeline")
                            ) + `timeline/3DScan`;
                        navigate(newRoute);
                      }}
                    >
                      3D Scan
                    </Button>
                  </div>
                )}
                {!pathname.endsWith("timeline/timelapse") && (
                  <div>
                    <Button
                      disabled={!isTimelapseDataAvailable}
                      type="text"
                      className="timeline-button"
                      onClick={() => {
                        const newRoute = pathname.includes("deliverables")
                          ? pathname.substring(
                              0,
                              pathname.indexOf("deliverables")
                            ) + `timeline/timelapse`
                          : pathname.substring(
                              0,
                              pathname.indexOf("timeline")
                            ) + `timeline/timelapse`;
                        navigate(newRoute);
                      }}
                    >
                      Real-time Data
                    </Button>
                  </div>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export { TimelineMenu };
