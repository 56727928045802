import { useContext, useState } from "react";
import {
  DownloadOutlined,
  DeleteOutlined,
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
import { DownloadProgressBar } from "../loading";
import { Alert } from "../alert";
import { deleteObject, getDownloadURL, listAll, ref } from "firebase/storage";
import {
  storage,
  sendNewDeliverableNotification,
  sendApprovedDeliverableNotification,
  sendDeliverableRejectionNotification,
} from "../../configurations/firebaseConfig";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { AuthContext } from "../../contexts";
import {
  deleteDeliverableById,
  updateStatus,
} from "../../queries/deliverables";
import {
  getOrganisationProjectByProjectId,
  getProjectById,
} from "../../queries/projects";
import { getOrganisationsByIdArray } from "../../queries/organisations";
import { UploadDriveLink } from "../forms";
import { useNavigate } from "react-router-dom";

const DeliverableMenu = ({
  storagePath,
  menuLevel,
  selectedDeliverable,
  itemName,
  setNeedsRefetch,
}) => {
  const { userInfo } = useContext(AuthContext);
  const [downloadMessage, setDownloadMessage] = useState("");
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [abortController, setAbortController] = useState();
  const [actionName, setActionName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const handleCloseAlert = () => setShowAlertModal(false);
  const handleShowAlert = () => setShowAlertModal(true);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleCloseUpload = () => setShowUploadModal(false);
  const handleShowUpload = () => setShowUploadModal(true);

  const getItemsRecursive = async (storagePath) => {
    const listRef = ref(storage, storagePath);
    const { prefixes, items } = await listAll(listRef);
    const returnableItems = [...items];
    if (prefixes && prefixes.length !== 0) {
      for (let i = 0; i < prefixes.length; i++) {
        const returnedItems = await getItemsRecursive(prefixes[i].fullPath);
        returnableItems.push(...returnedItems);
      }
    }
    return returnableItems;
  };

  const deleteDeliverable = async () => {
    if (menuLevel === "deliverable" || menuLevel === "folder") {
      const allItems = await getItemsRecursive(storagePath);
      await Promise.all(
        allItems.map((file) => {
          return deleteObject(ref(storage, `${file.fullPath}`));
        })
      );
      if (menuLevel === "deliverable") {
        await deleteDeliverableById(selectedDeliverable.id);
      }
    } else if (menuLevel === "file") {
      await deleteObject(ref(storage, `${storagePath}`));
    }
  };

  const updateDeliverableStatus = async ({ status, emails }) => {
    await updateStatus(selectedDeliverable.id, status);
    if (status === "PENDING") {
      const project = await getProjectById(selectedDeliverable.projectId);
      const organisationsProjects = await getOrganisationProjectByProjectId(
        selectedDeliverable.projectId
      );
      const organisationIds = [];
      organisationsProjects.forEach((organisationProject) => {
        organisationIds.push(organisationProject.organisationId);
      });
      const organisations = await getOrganisationsByIdArray(organisationIds);
      if (emails && emails.length) {
        await sendNewDeliverableNotification({
          emails: emails,
          submitterName: userInfo.firstName + " " + userInfo.lastName,
          projectName: project.name,
          organisationNames: organisations[0].name,
          deliverableLink: `https://${process.env.REACT_APP_BASE_URL}/supervisorDashboard/organisations/${organisations[0].id
            }/projects/${selectedDeliverable.projectId}/deliverables/${selectedDeliverable.id
            }/${String(selectedDeliverable.name).replace(" ", "%20")}`,
        });
      }
    } else if (status === "APPROVED") {
      const project = await getProjectById(selectedDeliverable.projectId);
      const organisationsProjects = await getOrganisationProjectByProjectId(
        selectedDeliverable.projectId
      );
      const organisationIds = [];
      organisationsProjects.forEach((organisationProject) => {
        organisationIds.push(organisationProject.organisationId);
      });
      if (emails && emails.length) {
        await sendApprovedDeliverableNotification({
          emails: emails,
          projectName: project.name,
          deliverableLink: `https://${process.env.REACT_APP_BASE_URL}`,
        });
      }
    } else if (status === "REJECTED") {
      const project = await getProjectById(selectedDeliverable.projectId);
      const organisationsProjects = await getOrganisationProjectByProjectId(
        selectedDeliverable.projectId
      );
      const organisationIds = [];
      organisationsProjects.forEach((organisationProject) => {
        organisationIds.push(organisationProject.organisationId);
      });
      const organisations = await getOrganisationsByIdArray(organisationIds);
      if (emails && emails.length) {
        await sendDeliverableRejectionNotification({
          emails: emails,
          submitterName: userInfo.firstName + " " + userInfo.lastName,
          projectName: project.name,
          organisationNames: organisations[0].name,
          deliverableLink: `https://${process.env.REACT_APP_BASE_URL}/dataprocessorDashboard/organisations/${organisations[0].id
            }/projects/${selectedDeliverable.projectId}/deliverables/${selectedDeliverable.id
            }/${String(selectedDeliverable.name).replace(" ", "%20")}`,
          deliverableName: selectedDeliverable.name,
        });
      }
    }
  };

  const zipAndDownload = async (abortControllerObject) => {
    try {
      if (menuLevel === "deliverable" || menuLevel === "folder") {
        // step 1: getting all files' ref for the specified "storagePath"
        setDownloadMessage("Preparing download ...");
        const allItems = await getItemsRecursive(storagePath);

        const downloadables = [];

        // step 2: obtaining download-urls & downloading file by file (async-await)
        // and saving downloaded blob data to "downloadables" array
        for (let i = 0; i < allItems.length; i++) {
          setDownloadMessage(`Downloading ${allItems[i].name}`);
          const filePath = allItems[i].fullPath.substring(
            allItems[i].fullPath.indexOf(selectedDeliverable.id) +
            `${selectedDeliverable.id}`.length +
            1,
            allItems[i].fullPath.length
          );
          const downloadURL = await getDownloadURL(allItems[i]);

          const downloadTask = axios({
            url: downloadURL,
            method: "GET",
            responseType: "blob",
            signal: abortControllerObject.signal,
            onDownloadProgress: (progressEvent) => {
              let percentCompleted =
                (progressEvent.loaded * 100) / progressEvent.total;
              setProgressPercentage(
                (
                  Number((i + percentCompleted / 100) / allItems.length) * 100
                ).toFixed(2)
              );
            },
          }).then((response) => {
            downloadables.push({
              data: response.data,
              filePath: filePath,
            });
          });

          await downloadTask;

          setProgressPercentage(Math.floor(((i + 1) / allItems.length) * 100));
        }

        // step 3: zipping & saving downloadables under the deliverable's name folder
        if (downloadables && downloadables.length) {
          setDownloadMessage("Zipping ...");
          const zip = new JSZip();
          downloadables.forEach((item) => {
            zip.file(item.filePath, item.data);
          });
          zip
            .generateAsync({
              type: "blob",
              chunkSize: 1024 * 1024 * 10, // 10MB
              compression: "DEFLATE",
            })
            .then((content) => {
              setDownloadMessage("Done");
              setProgressPercentage(100);
              // Trigger the browser to download the zip file
              saveAs(content, `${itemName}.zip`);
            });
        }
      } else {
        const listRef = ref(storage, storagePath);
        setDownloadMessage(`Downloading ${listRef.name}`);
        const downloadURL = await getDownloadURL(listRef);
        const response = await axios({
          url: downloadURL,
          method: "GET",
          responseType: "blob",
          signal: abortControllerObject.signal,
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = (
              (progressEvent.loaded * 100) /
              progressEvent.total
            ).toFixed(2);
            setProgressPercentage(percentCompleted);
          },
        });
        saveAs(response.data, listRef.name);
        setDownloadMessage("Done");
        setProgressPercentage(100);
      }
    } catch (error) {
      if (error.message === "canceled") {
        setDownloadMessage("Cancelled");
        setProgressPercentage(0);
      } else if (error.message === "Network Error") {
        setDownloadMessage("Network Error");
      } else {
        setDownloadMessage("Error downloading file(s)");
      }
    }
  };

  return (
    <div className="menu-options" id="deliverable-menu-step">
      <span
        className="download-button"
        onClick={() => {
          if (selectedDeliverable.downloadLink) {
            window.open(selectedDeliverable.downloadLink);
          } else {
            handleShow();
            const newAbortController = new AbortController();
            setAbortController(newAbortController);
            zipAndDownload(newAbortController);
          }
        }}
      >
        <DownloadOutlined /> Download
      </span>
      {userInfo &&
        userInfo.role &&
        userInfo.role !== "SENIOR" &&
        userInfo.role !== "SUBORDINATE" && (
          <span
            className="upload-drive-link-button"
            onClick={() => {
              if (
                userInfo.role !== "SENIOR" &&
                userInfo.role !== "SUBORDINATE"
              ) {
                handleShowUpload();
              }
            }}
          >
            <UploadOutlined /> Set Google Drive Link
          </span>
        )}
      {selectedDeliverable &&
        (selectedDeliverable.status === "NOT-READY" ||
          selectedDeliverable.status === "REJECTED") &&
        userInfo &&
        userInfo.role &&
        userInfo.role === "DATA-PROCESSOR" && (
          <span
            className="delete-button"
            onClick={() => {
              if (
                selectedDeliverable &&
                (selectedDeliverable.status === "NOT-READY" ||
                  selectedDeliverable.status === "REJECTED")
              ) {
                setActionName("Delete");
                handleShowAlert();
              }
            }}
          >
            <DeleteOutlined /> Delete
          </span>
        )}
      {userInfo && userInfo.role && userInfo.role === "SUPERVISOR" && (
        <span
          className="delete-button"
          onClick={() => {
            setActionName("Delete");
            handleShowAlert();
          }}
        >
          <DeleteOutlined /> Delete
        </span>
      )}
      {userInfo &&
        userInfo.role &&
        userInfo.role === "DATA-PROCESSOR" &&
        selectedDeliverable &&
        selectedDeliverable.status &&
        (selectedDeliverable.status === "NOT-READY" ||
          selectedDeliverable.status === "REJECTED") &&
        menuLevel &&
        menuLevel === "deliverable" && (
          <span
            className="submit-button"
            onClick={async () => {
              setActionName("Submit");
              handleShowAlert();
            }}
          >
            <CheckOutlined /> Submit
          </span>
        )}

      {userInfo &&
        userInfo.role &&
        userInfo.role === "SUPERVISOR" &&
        selectedDeliverable &&
        selectedDeliverable.status &&
        selectedDeliverable.status === "PENDING" && (
          <div className="menu-options">
            {menuLevel && menuLevel === "deliverable" && (
              <span
                className="approve-button"
                onClick={() => {
                  setActionName("Approve");
                  handleShowAlert();
                }}
              >
                <CheckOutlined /> Approve
              </span>
            )}
            {menuLevel && menuLevel === "deliverable" && (
              <span
                className="reject-button"
                onClick={() => {
                  setActionName("Reject");
                  handleShowAlert();
                }}
              >
                <CloseOutlined /> Reject
              </span>
            )}
          </div>
        )}
      <Modal
        className="download-progress-bar-modal"
        open={showModal}
        mask={false}
        footer={false}
        onCancel={handleClose}
        maskClosable={false}
        closable={false}
        wrapClassName="download-progress-bar-modal-wrap"
      >
        <DownloadProgressBar
          downloadMessage={downloadMessage}
          progressPercentage={progressPercentage}
          selfClose={handleClose}
          setDownloadMessage={setDownloadMessage}
          setProgressPercentage={setProgressPercentage}
          abortController={abortController}
        />
      </Modal>
      <Modal
        className="delete-popup-container"
        open={showAlertModal}
        centered
        footer={false}
        onCancel={handleCloseAlert}
        maskClosable={false}
        closable={false}
      >
        <Alert
          handleCloseAlert={handleCloseAlert}
          itemName={itemName}
          deleteDeliverable={deleteDeliverable}
          updateDeliverableStatus={updateDeliverableStatus}
          actionName={actionName}
          setNeedsRefetch={setNeedsRefetch}
          selectedDeliverable={selectedDeliverable}
        />
      </Modal>
      <Modal
        title="Upload Google Drive Link"
        open={showUploadModal}
        footer={false}
        onCancel={handleCloseUpload}
        maskClosable={false}
      >
        <UploadDriveLink deliverableId={selectedDeliverable.id} />
      </Modal>
    </div>
  );
};

export { DeliverableMenu };
