import React, { useEffect, useState } from "react";
import { Button, Cascader, Col, Divider, Form, Row } from "antd";
import moment from "moment";

const LayerComparisonForm = ({
  twoDPoints,
  initializeLayerComparison,
  setLayerComparisonActive,
}) => {
  const [form] = Form.useForm();
  const [layerOptions, setLayerOptions] = useState();

  useEffect(() => {
    if (twoDPoints && twoDPoints.length) {
      const imageryOptions = [];
      twoDPoints.forEach((point) => {
        if (point.twoDData && point.twoDData.length) {
          point.twoDData.forEach((src) => {
            const imagery = point.twoDData.filter(
              (twoD) => twoD.sourceType === "IMAGERY"
            );
            if (src.sourceType === "IMAGERY") {
              const label = `${src.sourceName} | ${moment(
                point.date.toDate()
              ).format("Do MMM YYYY")}`;
              const value = JSON.stringify({
                ...src, imagery, date:
                  moment(
                    point.date.toDate()
                  ).format("Do MMM YYYY")
              });
              imageryOptions.push({
                value,
                label,
              });
            }
          });
        }
      });
      setLayerOptions(imageryOptions);
    }
  }, [twoDPoints]);

  const handleVolumeComparisonFinish = () => {
    const { leftSideLayer, rightSideLayer } = form.getFieldsValue();
    initializeLayerComparison({
      leftLayer: JSON.parse(leftSideLayer),
      rightLayer: JSON.parse(rightSideLayer),
    });
    form.resetFields();
    setLayerComparisonActive(true);
  };

  return (
    <Form form={form} onFinish={handleVolumeComparisonFinish}>
      <Row>
        <Col span={11}>
          <label>Left Side Layer</label>
          <Form.Item
            name="leftSideLayer"
            rules={[
              {
                required: true,
                message: "Please select left side layer",
              },
            ]}
          >
            <Cascader
              placeholder="select left layer"
              maxTagCount="responsive"
              style={{ width: "100%" }}
              options={layerOptions}
            />
          </Form.Item>
        </Col>
        <Col span={2} style={{ display: "flex", justifyContent: "center" }}>
          <Divider
            type="vertical"
            style={{
              height: "100%",
              width: "1px",
              backgroundColor: "#242063",
            }}
          />
        </Col>
        <Col span={11}>
          <label>Right Side Layer</label>
          <Form.Item
            name="rightSideLayer"
            rules={[
              {
                required: true,
                message: "Please select a right layer",
              },
            ]}
          >
            <Cascader
              placeholder="select right layer"
              maxTagCount="responsive"
              style={{ width: "100%" }}
              options={layerOptions}
            />
          </Form.Item>
        </Col>
      </Row>
      <Button
        htmlType="submit"
        className="login-button"
        style={{ width: "100%", marginTop: 10 }}
      >
        Compare
      </Button>
    </Form>
  );
};

export { LayerComparisonForm };
