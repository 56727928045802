import { firestore } from "../configurations/firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { getProjectsByIdArray } from "./projects";

const projectAccessExists = async ({ userId, projectId }) => {
  const foundUserProjectAccess = [];
  const q = query(
    collection(firestore, "user_project_access"),
    where("userId", "==", userId),
    where("projectId", "==", projectId)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundUserProjectAccess.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return foundUserProjectAccess;
};

const createUserProjectAccess = async ({ userId, projectId }) => {
  const userProjectAccessExists = await projectAccessExists({
    userId,
    projectId,
  });
  if (userProjectAccessExists && userProjectAccessExists.length) {
    throw new Error("Project access exists already");
  }
  return addDoc(collection(firestore, "user_project_access"), {
    userId,
    projectId,
  });
};

const removeUserProjectAccess = async ({ userId, projectId }) => {
  const userProjectAccessExists = await projectAccessExists({
    userId,
    projectId,
  });
  if (userProjectAccessExists && userProjectAccessExists.length) {
    return deleteDoc(
      doc(firestore, "user_project_access", userProjectAccessExists[0].id)
    );
  }
  throw new Error("Access not found");
};

const getUserProjectAccessByUserId = async ({ userId }) => {
  const foundUserProjectAccess = [];
  const q = query(
    collection(firestore, "user_project_access"),
    where("userId", "==", userId)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundUserProjectAccess.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return foundUserProjectAccess;
};

const getUserAccessibleProjects = async ({ userId }) => {
  const userProjectAccess = await getUserProjectAccessByUserId({ userId });
  const projectIdsArray = userProjectAccess.map((object) => object.projectId);
  return await getProjectsByIdArray(projectIdsArray);
};

const getUsersIdsAccessingProject = async ({ projectId }) => {
  const foundUserIds = [];
  const q = query(
    collection(firestore, "user_project_access"),
    where("projectId", "==", projectId)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundUserIds.push({
      userId: doc.data().userId,
    });
  });
  return foundUserIds;
};

export {
  createUserProjectAccess,
  getUserProjectAccessByUserId,
  getUserAccessibleProjects,
  removeUserProjectAccess,
  getUsersIdsAccessingProject,
};
