import { Alert, Button, Cascader, Form, Input } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getOrganisationById } from "../../queries/organisations";
import {
  createOrganisationProject,
  createProject,
  createProjectType,
  getProjectTypes,
} from "../../queries/projects";
import "./forms.css";

const NewOrganisationProject = ({ refetchProjects }) => {
  const [form] = Form.useForm();
  const { organisationId } = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState();
  const [projectType, setProjectType] = useState("");

  const { data: projectOptions, isLoading } = useQuery(
    "newOrganisationTypes",
    getProjectTypes
  );

  const handleSubmit = async ({ name, description, type, otherType }) => {
    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    try {
      const organisationExists = await getOrganisationById(organisationId);
      if (!organisationExists) {
        throw new Error("Organization not found");
      }
      if (type[0] === "Other") {
        let anotherType = String(otherType).trim().toUpperCase();
        const exists = projectOptions.filter(
          (option) => option.value === anotherType
        );
        if (exists.length) {
          type[0] = exists[0].value;
        } else {
          await createProjectType(anotherType);
          type[0] = anotherType;
        }
      }
      const newProject = await createProject({
        name,
        description,
        type: type[0],
      });

      await createOrganisationProject({
        organisationId,
        projectId: newProject.id,
      });

      refetchProjects();
      setSuccessMessage("Project Created Successfully");
      form.resetFields();
    } catch (error) {
      if (error.message === "Organization not found") {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(
          "Sorry! we are unable to save the project at the moment"
        );
      }
    }
    setLoading(false);
  };

  return (
    <div>
      <p>
        {errorMessage && <Alert type="error" message={errorMessage} />}
        {successMessage && <Alert type="success" message={successMessage} />}
      </p>
      <Form className="form" form={form} onFinish={handleSubmit}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Please add an organisation's name" },
          ]}
          style={{ display: "inline-block", width: "calc(50%)" }}
        >
          <Input />
        </Form.Item>
        {projectOptions && (
          <Form.Item
            label="Type"
            name="type"
            wrapperCol={{ offset: 1 }}
            labelCol={{ offset: 1 }}
            rules={[
              {
                required: true,
                message: "Please select a type",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50%)",
            }}
          >
            <Cascader
              options={[...projectOptions, { value: "Other", label: "Other" }]}
              loading={isLoading}
              placeholder="Please select"
              onChange={(e) => {
                if (e) {
                  setProjectType(e[0]);
                } else {
                  setProjectType("");
                }
              }}
            />
          </Form.Item>
        )}
        {projectType && projectType === "Other" && (
          <Form.Item
            name="otherType"
            rules={[
              { required: true, message: "Please add another type" },
              {
                message:
                  "Must contain atleast 3 character with no special character, numbers or space",
                validator: (_, value) => {
                  if (value && /^[A-Za-z]+$/.test(value) && value.length > 2) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Name not valid");
                  }
                },
              },
            ]}
          >
            <Input placeholder="Other Organisation's type" />
          </Form.Item>
        )}
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please add a description",
            },
          ]}
          style={{
            width: "calc(100%)",
          }}
        >
          <Input.TextArea style={{ height: 100 }} />
        </Form.Item>
        <Form.Item
          style={{
            width: "calc(100%)",
          }}
        >
          <Button
            style={{ width: "100%" }}
            className="login-button"
            htmlType="submit"
            loading={loading}
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { NewOrganisationProject };
