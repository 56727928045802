import { Cartesian3, Color, defined, HeightReference } from "cesium";
import React, { useEffect, useState } from "react";
import {
  VictoryLine,
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  createContainer,
} from "victory";

const ProfileChart = ({
  activeTerrain,
  chartData,
  secondTerrainName,
  chartRef,
  drawingPoints,
  viewer,
}) => {
  const [distanceDomain, setDistanceDomain] = useState();
  const [heightDomain, setHeightDomain] = useState();
  const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");
  const [floatingPoint, setFloatingPoint] = useState();

  useEffect(() => {
    if (
      chartData &&
      chartData.heightIndices &&
      chartData.heightIndices.length > 0 &&
      chartData.distanceIndices &&
      chartData.distanceIndices.length > 0
    ) {
      const heightDifference =
        Math.max(...chartData.heightIndices) -
        Math.min(...chartData.heightIndices);
      const distanceDifference =
        Math.max(...chartData.distanceIndices) -
        Math.min(...chartData.distanceIndices);
      if (heightDifference < distanceDifference) {
        const lengthDifference = distanceDifference - heightDifference;
        setHeightDomain([
          chartData.heightIndices[0],
          chartData.heightIndices[chartData.heightIndices.length - 1] +
          lengthDifference / 2,
        ]);
        setDistanceDomain([
          chartData.distanceIndices[0],
          chartData.distanceIndices[chartData.distanceIndices.length - 1] +
          lengthDifference / 2,
        ]);
      } else {
        const lengthDifference = heightDifference - distanceDifference;
        setHeightDomain([
          chartData.heightIndices[0],
          chartData.heightIndices[chartData.heightIndices.length - 1] +
          lengthDifference * 2,
        ]);
        setDistanceDomain([
          chartData.distanceIndices[0],
          chartData.distanceIndices[chartData.distanceIndices.length - 1] +
          lengthDifference * 2,
        ]);
      }
      // initializing the floating point
      if (drawingPoints && drawingPoints.length && viewer) {
        const cartesian3 = Cartesian3.fromDegrees(
          drawingPoints[0].longitude,
          drawingPoints[0].latitude,
          drawingPoints[0].height + 0.01
        );
        const point = viewer.entities.add({
          position: cartesian3,
          point: {
            color: Color.BLUE,
            pixelSize: 6,
            heightReference: HeightReference.NONE,
            outlineColor: Color.WHITE, // default: BLACK
            outlineWidth: 3, // default: 0
          },
        });
        point.position.setValue();
        setFloatingPoint(point);
      }
    }
  }, [chartData]);

  useEffect(() => {
    if (floatingPoint && viewer) {
      return () => {
        viewer.entities.remove(floatingPoint);
      };
    }
  }, [floatingPoint, viewer]);

  return (
    <div className="cross-section-container">
      <div ref={chartRef} className="chart-container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <label
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {activeTerrain}
            <span
              style={{
                height: "15px",
                width: "40px",
                backgroundColor: "#fbb31c",
                borderRadius: 4,
                marginLeft: 5,
              }}
            >
              {""}
            </span>
          </label>
          {chartData && chartData.secondaryHeightIndices && (
            <label
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
            >
              {secondTerrainName}
              <span
                style={{
                  height: "15px",
                  width: "40px",
                  backgroundColor: "#242063",
                  borderRadius: 4,
                  marginLeft: 5,
                }}
              >
                {""}
              </span>
            </label>
          )}
        </div>

        {chartData &&
          chartData.distanceIndices &&
          chartData.distanceIndices.length > 0 &&
          heightDomain &&
          distanceDomain && (
            <VictoryChart
              animate={{
                duration: 20,
                onLoad: { duration: 20 },
              }}
              theme={VictoryTheme.material}
              width={1000}
              height={500}
              scale={"linear"}
              domainPadding={{ x: 30, y: 30 }}
              containerComponent={
                <VictoryZoomVoronoiContainer
                  labels={({ datum }) => {
                    if (datum.index && drawingPoints && drawingPoints.length) {
                      const cartesian3 = Cartesian3.fromDegrees(
                        drawingPoints[datum.index].longitude,
                        drawingPoints[datum.index].latitude,
                        drawingPoints[datum.index].height + 0.01
                      );
                      try {
                        if (floatingPoint && defined(floatingPoint)) {
                          floatingPoint.position.setValue(cartesian3);
                        } else {
                        }
                      } catch (error) { }
                    }
                    return `   Distance: ${Number(datum.x).toFixed(
                      2
                    )}m, Elevation: ${Number(datum.y).toFixed(2)}m   `;
                  }}
                />
              }
            >
              <VictoryAxis
                domain={distanceDomain}
                theme={VictoryTheme.material}
                standalone={false}
                crossAxis={false}
              />
              <VictoryAxis
                dependentAxis
                domain={heightDomain}
                theme={VictoryTheme.material}
                standalone={false}
                crossAxis={false}
              />
              {chartData.heightIndices &&
                chartData.heightIndices.length > 0 && (
                  <VictoryLine
                    style={{
                      data: { stroke: "#fbb31c", strokeWidth: 2 },
                      parent: { border: "1px solid #ccc" },
                    }}
                    data={[
                      ...chartData.distanceIndices.map((distance, index) => ({
                        x: distance,
                        y: chartData.heightIndices[index],
                        index,
                      })),
                    ]}
                  />
                )}
              {chartData.secondaryHeightIndices &&
                chartData.secondaryHeightIndices.length > 0 && (
                  <VictoryLine
                    style={{
                      data: { stroke: "#242063", strokeWidth: 2 },
                      parent: { border: "1px solid #ccc" },
                    }}
                    data={[
                      ...chartData.distanceIndices.map((distance, index) => ({
                        x: distance,
                        y: chartData.secondaryHeightIndices[index],
                        index,
                      })),
                    ]}
                  />
                )}
            </VictoryChart>
          )}
      </div>
    </div>
  );
};

export { ProfileChart };
