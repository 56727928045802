
const DeviceDetection = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <div>
                <lottie-player
                    src="https://firebasestorage.googleapis.com/v0/b/intel-construction.appspot.com/o/gifs%2Fnot-accessible-on-phone.json?alt=media&token=66514fcf-9470-4acd-a50d-49360eb9ec9e&_gl=1*1oc8n10*_ga*MTcyMTQ0NjI3MC4xNjc5OTk4MzUw*_ga_CW55HF8NVT*MTY5ODczNzY3Ni41NS4xLjE2OTg3Mzg1NDUuOC4wLjA."
                    background="transparent"
                    speed="1.5"
                    loop
                    autoplay
                ></lottie-player>
            </div>
            <div
                className="access-denied-text"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                }}
            >
                <span style={{ fontSize: 16, fontWeight: "bold", marginBottom: 20 }}>
                    🌟 Hey there! Don't worry! 🌟
                </span>
                <span>Our platform is optimized for laptop/desktop access.</span>
                <span>
                    To enjoy the full experience of the Charis Analytics platform, please use a laptop or
                    desktop computer.
                </span>
            </div>
        </div>
    )
}

export { DeviceDetection };