import { useContext, useEffect, useState } from "react";
import { Avatar, Button, Cascader, Divider, Form, List, Skeleton } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import "./assign.css";
import Identicon from "react-identicons";
import {
  createUserProjectAccess,
  getUserAccessibleProjects,
  removeUserProjectAccess,
} from "../../queries/userProjectAccess";
import { useMutation } from "react-query";
import { getOrganisationProjects } from "../../queries/projects";
import { AuthContext } from "../../contexts";

const AssignUsers = ({ chosenAccount }) => {
  const [form] = Form.useForm();

  const { userInfo } = useContext(AuthContext);

  const [projects, setProjects] = useState();

  const {
    isLoading: isAccessibleProjectsLoading,
    data: accessibleProjects,
    mutate: findAccessibleProjects,
  } = useMutation(async (userId) => {
    const projects = await getUserAccessibleProjects({ userId });
    const allProjects = await getOrganisationProjects(
      chosenAccount.organisationId
    );
    setProjects(allProjects);
    form.setFieldsValue({
      projects: projects.map((project) => [project.id]),
    });
    return projects;
  });

  const handleAssignment = async ({ projects }) => {
    if (
      userInfo &&
      (userInfo.role === "SUPERVISOR" || userInfo.role === "SENIOR")
    ) {
      const removedIds = accessibleProjects.filter((project) => {
        const stillPresent = projects.filter(
          (selectedProject) => selectedProject[0] === project.id
        );
        return stillPresent && stillPresent.length === 0;
      });

      projects = projects.filter((selectedProject) => {
        const newIds = accessibleProjects.filter(
          (project) => project.id === selectedProject[0]
        );
        return newIds && newIds.length === 0;
      });

      if (removedIds && removedIds.length) {
        for (let i = 0; i < removedIds.length; i++) {
          try {
            await removeUserProjectAccess({
              projectId: removedIds[i].id,
              userId: chosenAccount.userId,
            });
          } catch (error) { }
        }
      }

      if (projects && projects.length) {
        for (let i = 0; i < projects.length; i++) {
          try {
            await createUserProjectAccess({
              projectId: projects[i][0],
              userId: chosenAccount.userId,
            });
          } catch (error) { }
        }
      }
      findAccessibleProjects(chosenAccount.userId);
    }
  };

  const { isLoading: isProjectAssignmentLoading, mutate: assignProject } =
    useMutation(handleAssignment);

  useEffect(() => {
    if (chosenAccount && chosenAccount.userId) {
      findAccessibleProjects(chosenAccount.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenAccount]);

  useEffect(() => {
    if (projects && accessibleProjects) {
      const formValues = form.getFieldsValue();
      if (
        formValues &&
        formValues.projects &&
        formValues.projects.length === 0
      ) {
        form.setFieldsValue({
          projects: accessibleProjects.map((project) => [project.id]),
        });
      }
    }
  });

  return (
    <div className="assign-container">
      <h2>
        <span style={{ color: "#242063", fontWeight: "bold" }}>
          {chosenAccount.names !== "undefined undefined"
            ? chosenAccount.names
            : chosenAccount.email}
        </span>
        's assigned Projects
      </h2>
      <Divider />
      <div style={{ height: "200px", overflow: "auto" }}>
        <Form
          style={{ marginBottom: -20 }}
          onFinish={assignProject}
          form={form}
        >
          {isAccessibleProjectsLoading && (
            <Skeleton.Input
              active
              block={true}
              style={{
                backgroundColor: "#fff",
                marginBottom: 10,
                borderRadius: 4,
              }}
            />
          )}
          {!isAccessibleProjectsLoading && (
            <Form.Item
              name="projects"
              style={{ display: "inline-block", width: "82%" }}
            >
              <Cascader
                multiple
                options={projects}
                showSearch={true}
                showCheckedStrategy={true}
                placeholder="Add a project"
                style={{ width: "calc(100%)" }}
              />
            </Form.Item>
          )}
          <Form.Item
            wrapperCol={{ offset: 1 }}
            style={{
              display: "inline-block",
              width: "auto",
              marginLeft: 5,
            }}
          >
            {!isAccessibleProjectsLoading && (
              <Button
                className="add-member-button"
                htmlType="submit"
                icon={<SaveOutlined />}
                loading={isProjectAssignmentLoading}
              >
                Save
              </Button>
            )}
          </Form.Item>
        </Form>
        <List>
          <Skeleton
            avatar
            title={false}
            loading={isAccessibleProjectsLoading}
            active
          ></Skeleton>
          {accessibleProjects &&
            accessibleProjects.length > 0 &&
            accessibleProjects.map((accessibleProject) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      icon={<Identicon string={accessibleProject.type} />}
                    />
                  }
                  title={accessibleProject.name}
                  description={accessibleProject.type}
                />
              </List.Item>
            ))}
        </List>
      </div>
    </div>
  );
};

export { AssignUsers };
