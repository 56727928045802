import { Button, Progress } from "antd";

const DownloadProgressBar = ({
  downloadMessage,
  progressPercentage,
  selfClose,
  setDownloadMessage,
  setProgressPercentage,
  abortController,
}) => {
  return (
    <div className="progress-bar-container">
      <span>{downloadMessage}</span>
      <Progress percent={progressPercentage} status="active" />
      {downloadMessage &&
      (downloadMessage === "Done" || downloadMessage === "Cancelled") ? (
        <Button
          style={{ width: "100%", marginTop: 10 }}
          className="login-button"
          onClick={() => {
            selfClose();
            setDownloadMessage("");
            setProgressPercentage(0);
          }}
        >
          Close
        </Button>
      ) : (
        <Button
          style={{ width: "100%", marginTop: 10 }}
          className="login-button"
          onClick={() => {
            abortController.abort();
          }}
        >
          Cancel
        </Button>
      )}
    </div>
  );
};

export { DownloadProgressBar };
