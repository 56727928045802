import {
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  ColorMaterialProperty,
  defined,
  CallbackProperty,
  PolygonHierarchy,
  Color,
  HeightReference,
} from "cesium";
import "./main.css";
import "./loading.svg";

class VolumeController {
  constructor({
    tileset,
    viewer,
    setVolumeProgress,
    topTerrainId,
    authorizationToken,
    baseTerrainId,
    setNetVolume,
    setCutVolume,
    setFillVolume,
    setMeasurementReady,
    setDrawingGridPoints,
    requestMeasurementCalculation,
  }) {
    this.tileset = tileset;
    this.viewer = viewer;
    //secondaryViewer for comparison volume
    this.baseTerrainId = baseTerrainId;
    this.topTerrainId = topTerrainId;
    this.authorizationToken = authorizationToken;
    this.points = [];
    // this.floatingPoint;
    // this.floatingShape;
    this.activeShapePoints = [];
    this.running = false;
    this.setVolumeProgress = setVolumeProgress;
    this.setNetVolume = setNetVolume;
    this.setCutVolume = setCutVolume;
    this.setFillVolume = setFillVolume;
    this.setMeasurementReady = setMeasurementReady;
    this.setDrawingGridPoints = setDrawingGridPoints;
    this.requestMeasurementCalculation = requestMeasurementCalculation;

    viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
      ScreenSpaceEventType.LEFT_CLICK
    );
  }

  activate() {
    this.setNetVolume(0);
    this.setCutVolume(0);
    this.setFillVolume(0);
    this.setMeasurementReady(false);
    this.setDrawingGridPoints();
    this.handler = new ScreenSpaceEventHandler(this.viewer.canvas);
    this.handler.setInputAction(
      this._addPoint(this),
      ScreenSpaceEventType.LEFT_CLICK
    );
    this.handler.setInputAction(
      this._mouseMove(this),
      ScreenSpaceEventType.MOUSE_MOVE
    );
    this.handler.setInputAction(
      this._terminate(this),
      ScreenSpaceEventType.RIGHT_CLICK
    );
    // set eventHandler for the escape button
    this._addEscapeKeyListener();
  }

  destroy() {
    this.handler?.destroy();
    this.setMeasurementReady(false);
    this.setDrawingGridPoints();
    // removing drawings and other datasources eg: kmls (if any), mesaurement gridMesh
    // this.viewer.dataSources.removeAll();
    this.viewer.dataSources._dataSources.forEach((ds) => {
      if (ds.name === "volume") {
        ds.entities.removeAll();
      }
    });
    this.viewer.entities.removeAll();
    this._removeEscapeKeyListener();
  }

  _handleEscapeKey = (event) => {
    if (event.key === "Escape" || event.keyCode === 27) {
      // Your code to handle the "Escape" key press goes here
      this.cancelShape();
    }
  };

  _addEscapeKeyListener() {
    document.addEventListener("keydown", this._handleEscapeKey);
  }

  _removeEscapeKeyListener() {
    document.removeEventListener("keydown", this._handleEscapeKey);
  }

  cleanup(self) {
    document.getElementById("progress").style.display = "none";
    // document.getElementById("sampling").style.pointerEvents = "all";
    // document.getElementById("calculatedVolumeHeight").style.pointerEvents = "all";
    self.running = false;
  }
  _drawShape(positionData, perPositionHeight, color) {
    return {
      polygon: {
        hierarchy: positionData,
        material: new ColorMaterialProperty(color),
        perPositionHeight: perPositionHeight,
      },
    };
  }

  _addPoint(self) {
    return (event) => {
      if (self.running) return;
      let earthPosition = self.viewer.scene.pickPosition(event.position);
      /* let cartographicPosition = Cartographic.fromCartesian(earthPosition);
            //height through sampleHeight is a little more precise
            cartographicPosition.height = self.viewer.scene.sampleHeight(cartographicPosition, [], 0.1);
            earthPosition = Cartesian3.fromDegrees(cartographicPosition.longitude * 57.295779513082320876798154814105, cartographicPosition.latitude * 57.295779513082320876798154814105, cartographicPosition.height); */

      if (defined(earthPosition)) {
        if (self.activeShapePoints.length === 0) {
          this.setNetVolume(0);
          this.setCutVolume(0);
          this.setFillVolume(0);
          this.setMeasurementReady(false);
          this.setDrawingGridPoints();
          self.floatingPoint = self._createPoint(earthPosition);
          self.activeShapePoints.push(earthPosition);
          const dynamicPositions = new CallbackProperty(function () {
            return new PolygonHierarchy(self.activeShapePoints);
          }, false);
          self.activeShape = self._drawShape(
            dynamicPositions,
            true,
            Color.WHITE.withAlpha(0.7)
          );
          self.viewer.entities.add(self.activeShape);
        }
        self.activeShapePoints.push(earthPosition);
        self.points.push(self._createPoint(earthPosition));
        /* if (self.activeShapePoints.length === 5) {
                    self.terminateShape();
                } */
      }
    };
  }
  _mouseMove(self) {
    return (event) => {
      if (defined(self.floatingPoint)) {
        const newPosition = self.viewer.scene.pickPosition(event.endPosition);
        if (defined(newPosition)) {
          self.floatingPoint.position.setValue(newPosition);
          self.activeShapePoints.pop();
          self.activeShapePoints.push(newPosition);
        }
      }
    };
  }

  _terminate(self) {
    return (event) => {
      if (self.running) return;
      if (self.activeShapePoints.length >= 4) {
        self.terminateShape();
      }
    };
  }

  cancelShape() {
    this.viewer.entities.remove(this.floatingPoint);
    this.viewer.entities.remove(this.activeShape);
    this.points.forEach((p) => {
      this.viewer.entities.remove(p);
    });
    // cleaning previous CustomDataSource drawings
    this.viewer.dataSources._dataSources.forEach((ds) => {
      if (ds.name === "volume") {
        ds.entities.removeAll();
      }
    });
    // cleaning previous Entity drawings
    this.viewer.entities.removeAll();

    this.points = [];
    this.activeShapePoints.pop();
    this.activeShapePoints.push(this.activeShapePoints[0]);
    this.floatingPoint = undefined;
    this.activeShape = undefined;
    // document.getElementById("calculatedVolumeHeight").value = 0;
    this.activeShapePoints = [];
  }

  terminateShape() {
    this.viewer.entities.remove(this.floatingPoint);
    this.viewer.entities.remove(this.activeShape);
    this.points.forEach((p) => {
      this.viewer.entities.remove(p);
    });
    // cleaning previous CustomDataSource drawings
    this.viewer.dataSources._dataSources.forEach((ds) => {
      if (ds.name === "volume") {
        ds.entities.removeAll();
      }
    });
    // cleaning previous Entity drawings
    this.viewer.entities.removeAll();

    this.points = [];
    this.activeShapePoints.pop();
    this.activeShapePoints.push(this.activeShapePoints[0]);
    this.floatingPoint = undefined;
    this.activeShape = undefined;
    this.recalculate();
    // document.getElementById("calculatedVolumeHeight").value = 0;
    this.activeShapePoints = [];
  }

  recalculate() {
    const self = this;
    self.running = true;
    document.getElementById("progress").style.display = "block";
    document.getElementById("volume-cancel-registered").style.display = "none";
    this.requestMeasurementCalculation({
      cartesian3Coordinates: this.activeShapePoints,
      topTerrainId: this.topTerrainId,
      baseTerrainId: this.baseTerrainId,
      cleanUpFunction: this.cleanup,
      self,
    });
  }
  _createPoint(worldPosition) {
    const point = this.viewer.entities.add({
      position: worldPosition,
      point: {
        color: Color.WHITE,
        pixelSize: 5,
        heightReference: HeightReference.NONE,
      },
    });
    return point;
  }
}
export { VolumeController };
