import { Button, Form, Input } from "antd";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { createTimelapseLink } from "../../queries/timelapseLinks";

const TimelapseFormList = ({ fetchTimelapseLinks }) => {
  const [timelapseForm] = Form.useForm();
  const { projectId } = useParams();
  const { mutate: saveTimelapse, isLoading } = useMutation(async (data) => {
    await createTimelapseLink({ ...data, projectId });
    timelapseForm.resetFields();
    fetchTimelapseLinks(projectId);
  });

  return (
    <div>
      <Form form={timelapseForm} name="timelapseData" onFinish={saveTimelapse}>
        <div
          style={{
            marginBottom: 8,
          }}
        >
          <Form.Item
            name={"name"}
            rules={[
              {
                required: true,
                message: "Please add a name",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(30%)",
            }}
          >
            <Input style={{ width: "100%" }} placeholder="Name" />
          </Form.Item>
          <Form.Item
            name={"link"}
            rules={[
              {
                required: true,
                message: "Please add a link",
              },
            ]}
            wrapperCol={{ offset: 1 }}
            style={{
              display: "inline-block",
              width: "calc(70%)",
            }}
          >
            <Input placeholder="Link" />
          </Form.Item>
        </div>
        <Form.Item
          style={{
            display: "inline-block",
            width: "calc(100%)",
          }}
        >
          <Button
            htmlType="submit"
            block
            className="login-button"
            loading={isLoading}
          >
            Add Timelapse
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { TimelapseFormList };
