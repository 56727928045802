import { Button, Cascader, Col, Form, Row } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";

const VolumeCompare = ({ threeDPoints, initialVolumeComparison }) => {
  const [form] = Form.useForm();
  const [layerOptions, setLayerOptions] = useState();

  useEffect(() => {
    if (threeDPoints && threeDPoints.length) {
      const terrains = [];
      threeDPoints.forEach((point) => {
        if (point.threeDData && point.threeDData.length) {
          point.threeDData.forEach((src) => {
            const imagery = point.twoDData.filter(
              (twoD) => twoD.sourceType === "IMAGERY"
            );
            const label = `${src.sourceName} | ${moment(
              point.date.toDate()
            ).format("Do MMM YYYY")}`;
            const value = JSON.stringify({ ...src, imagery });
            terrains.push({
              value,
              label,
            });
          });
        }
      });
      setLayerOptions(terrains);
    }
  }, [threeDPoints]);

  const handleVolumeComparisonFinish = () => {
    const { baseTerrainData, topTerrainData } = form.getFieldsValue();
    initialVolumeComparison({
      baseTerrainData: JSON.parse(baseTerrainData),
      topTerrainData: JSON.parse(topTerrainData),
    });
    form.resetFields();
  };

  return (
    <div style={{ padding: 0 }}>
      <Form form={form} onFinish={handleVolumeComparisonFinish}>
        <Row>
          <Col span={12}>
            <label>
              Top Terrain{" "}
              <span style={{ fontSize: 13, color: "grey" }}>
                (Most Recent Terrain)
              </span>
            </label>
            <Form.Item
              name="topTerrainData"
              rules={[
                {
                  required: true,
                  message: "Please select top terrain",
                },
              ]}
            >
              <Cascader
                placeholder="select Top Terrain"
                maxTagCount="responsive"
                style={{ width: "100%" }}
                options={layerOptions}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label style={{ marginLeft: 10 }}>
              Base Terrain{" "}
              <span style={{ fontSize: 13, color: "grey" }}>
                (Older Terrain)
              </span>
            </label>
            <Form.Item
              name="baseTerrainData"
              rules={[
                {
                  required: true,
                  message: "Please select base terrain",
                },
              ]}
            >
              <Cascader
                placeholder="select Base Terrain"
                maxTagCount="responsive"
                style={{ width: "100%", marginLeft: 10 }}
                options={layerOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ marginBottom: 10 }}>
          <Button htmlType="submit" id="compare-button">
            Go{" "}
            <ArrowRightOutlined style={{ position: "relative", bottom: 2 }} />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { VolumeCompare };
