import {
  firestore,
  sendAccountSetupMail,
} from "../configurations/firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  addDoc,
  doc,
  documentId,
} from "firebase/firestore";

const usersRef = collection(firestore, "users");

const generateRandomKey = (keyLength) => {
  let key = "";
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const lettersLength = letters.length;
  for (let i = 0; i < keyLength; i++) {
    key += letters.charAt(Math.floor(Math.random() * lettersLength));
  }
  return key;
};

const findUserByEmail = async (email) => {
  let foundUser;
  const q = query(
    usersRef,
    where("email", "==", String(email).trim().toLowerCase())
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundUser = {
      userId: doc.id,
      ...doc.data(),
    };
  });
  return foundUser;
};

const getUserDocByEmail = async (email) => {
  let foundUser;
  const q = query(
    usersRef,
    where("email", "==", String(email).trim().toLowerCase())
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundUser = doc;
  });
  return foundUser;
};

const updateInitialProfile = async (
  email,
  firstName,
  lastName,
  profilePicture
) => {
  const userDoc = await getUserDocByEmail(String(email).trim().toLowerCase());
  return profilePicture
    ? updateDoc(userDoc.ref, {
        firstName,
        lastName,
        profilePicture,
        token: "",
        isProfileSet: true,
      })
    : updateDoc(userDoc.ref, {
        firstName,
        lastName,
        token: "",
        isProfileSet: true,
      });
};

const updateUserProfile = async ({ userId, ...rest }) => {
  const userDoc = doc(firestore, "users", String(userId).trim());
  return updateDoc(userDoc, {
    ...rest,
  });
};

const createUserByEmailAndRole = async (email, role, organisationId) => {
  const token = generateRandomKey(10);
  const createdUser = (await organisationId)
    ? addDoc(usersRef, {
        email: String(email).trim().toLowerCase(),
        role: String(role).trim().toUpperCase(),
        organisationId,
        isSuspended: false,
        isProfileSet: false,
        token,
      })
    : addDoc(usersRef, {
        email,
        role,
        isSuspended: false,
        isProfileSet: false,
        token,
      });

  const response = await sendAccountSetupMail({
    token,
    toEmailAddress: email,
  });

  if (response.data && response.data && response.data.success) {
    return { emailSent: response.data.success, newUser: createdUser };
  }

  return { emailSent: false, newUser: createdUser };
};

const getAllUsers = async () => {
  let foundUsers = [];
  const q = query(usersRef);
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundUsers.push({
      userId: doc.id,
      ...doc.data(),
    });
  });
  return foundUsers;
};

const getAllUsersByRole = async (role) => {
  let foundUsers = [];
  const q = query(
    usersRef,
    where("role", "==", String(role).trim().toUpperCase())
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundUsers.push({
      userId: doc.id,
      ...doc.data(),
    });
  });
  return foundUsers;
};

const getUsersByOrganisationId = async (organisationId) => {
  const foundUsers = [];
  const q = query(usersRef, where("organisationId", "==", organisationId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundUsers.push({
      userId: doc.id,
      ...doc.data(),
    });
  });
  return foundUsers;
};

const updateUserAccountStatusByEmail = async ({ email, status }) => {
  const userDoc = await getUserDocByEmail(String(email).trim().toLowerCase());
  return status
    ? updateDoc(userDoc.ref, {
        isSuspended: true,
      })
    : updateDoc(userDoc.ref, {
        isSuspended: false,
      });
};

const updateUserCanUploadPermission = async ({ email, canUpload }) => {
  const userDoc = await getUserDocByEmail(String(email).trim().toLowerCase());
  return canUpload
    ? updateDoc(userDoc.ref, {
        canUpload: true,
      })
    : updateDoc(userDoc.ref, {
        canUpload: false,
      });
};

const getUsersByIdArrays = async (usersIdsArray) => {
  const foundUsers = [];
  const spliceableIds = new Array(...usersIdsArray);
  while (spliceableIds.length) {
    const splicedIds = spliceableIds.splice(0, 10);
    const q = query(usersRef, where(documentId(), "in", splicedIds));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      foundUsers.push({
        userId: doc.id,
        ...doc.data(),
        value: doc.id,
        label: doc.data().name,
      });
    });
  }
  return foundUsers;
};

export {
  findUserByEmail,
  updateInitialProfile,
  getUserDocByEmail,
  createUserByEmailAndRole,
  getAllUsers,
  getUsersByOrganisationId,
  updateUserAccountStatusByEmail,
  updateUserCanUploadPermission,
  updateUserProfile,
  getAllUsersByRole,
  getUsersByIdArrays,
};
