import { Alert, Button, Form, Input } from 'antd'
import { useMutation } from 'react-query';
import { updateDownloadLink } from '../../queries/deliverables';

const UploadDriveLink = ({ deliverableId }) => {
    const [form] = Form.useForm();

    const { isLoading, mutate, isSuccess, error } = useMutation(async ({ googleDriveLink }) => {
        await updateDownloadLink({ downloadLink: googleDriveLink, id: deliverableId });

    });

    return (
        <div>
            <p>{error && <Alert type="error" message={error} />}</p>
            <p>{isSuccess && <Alert type="success" message={"link saved successfully"} />}</p>
            <Form className="form" form={form} onFinish={({ googleDriveLink }) => mutate({ googleDriveLink })}>
                <Form.Item
                    name="googleDriveLink"
                    rules={[{ required: true, message: "Please add deliverable name" }]}
                    style={{ display: "inline-block", width: "calc(100%)" }}
                >
                    <Input placeholder="Google Drive Link" />
                </Form.Item>
                <Form.Item>
                    <Button
                        loading={isLoading}
                        style={{ width: "100%" }}
                        className="login-button"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export { UploadDriveLink }