import { Button, Form, Input } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

const ScanDataFormList = ({ setScanDataFormError }) => {
  return (
    <div>
      <Form.List name="scanData">
        {(fields, { add, remove }, { errors }) => (
          <div>
            {fields.map(({ key, name, ...restField }) => (
              <div
                key={key}
                style={{
                  marginBottom: 8,
                }}
              >
                <Form.Item
                  {...restField}
                  name={[name, "name"]}
                  rules={[
                    {
                      required: true,
                      message: "Please add a name",
                      validator: (_, value) => {
                        if (value) {
                          setScanDataFormError("");
                          return Promise.resolve();
                        } else {
                          setScanDataFormError("Please add a name");
                          return Promise.reject("Please add a name");
                        }
                      },
                    },
                  ]}
                  style={{
                    display: "inline-block",
                    width: "calc(30%)",
                  }}
                >
                  <Input style={{ width: "100%" }} placeholder="Name" />
                </Form.Item>
                <Form.Item
                  name={[name, "link"]}
                  rules={[
                    {
                      required: true,
                      message: "Please add a link",
                      validator: (_, value) => {
                        if (value) {
                          setScanDataFormError("");
                          return Promise.resolve();
                        } else {
                          setScanDataFormError("Please add a link");
                          return Promise.reject("Please add a link");
                        }
                      },
                    },
                  ]}
                  wrapperCol={{ offset: 1 }}
                  style={{
                    display: "inline-block",
                    width: "calc(60%)",
                  }}
                >
                  <Input placeholder="Link" />
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    style={{ position: "relative", left: "20px" }}
                    onClick={() => {
                      setScanDataFormError("");
                      remove(name);
                    }}
                  />
                ) : null}
              </div>
            ))}
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(100%)",
              }}
            >
              <Button
                type="dashed"
                onClick={() => add()}
                block
              >
                Add Scan
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </div>
        )}
      </Form.List>
    </div>
  );
};

export { ScanDataFormList };
