import { firestore } from "../configurations/firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  getDoc,
  doc,
  writeBatch,
} from "firebase/firestore";
import { getAllProjectDeliverables } from "./deliverables";

const timelinePointExists = async ({ date, deliverableIdsArray }) => {
  const foundTimelinePoints = [];
  const spliceableIds = new Array(...deliverableIdsArray);
  while (spliceableIds.length) {
    const splicedIds = spliceableIds.splice(0, 10);
    const timelineQuery = query(
      collection(firestore, "timeline_points"),
      where("deliverableId", "in", splicedIds),
      where("date", "==", date)
    );
    const querySnapshot = await getDocs(timelineQuery);
    querySnapshot.forEach((doc) => {
      foundTimelinePoints.push({
        id: doc.id,
        ...doc.data(),
      });
    });
  }
  return foundTimelinePoints;
};

const createTimelinePoint = async ({ date, deliverableId, projectId }) => {
  const deliverables = await getAllProjectDeliverables(projectId);
  const deliverableIdsArray = deliverables.map((deliverable) => deliverable.id);
  const pointExists = await timelinePointExists({ date, deliverableIdsArray });
  if (pointExists && pointExists.length) {
    throw new Error("Timeline point exists already");
  }
  return addDoc(collection(firestore, "timeline_points"), {
    deliverableId: String(deliverableId).trim(),
    date,
  });
};

const getTimelinePointById = async (id) => {
  let foundTimelinePoint;
  const docRef = doc(firestore, "timeline_points", String(id).trim());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    foundTimelinePoint = {
      id: docSnap.id,
      ...docSnap.data(),
    };
  }
  return foundTimelinePoint;
};

const getTimelinePointsByDeliverableId = async (deliverableId) => {
  const foundTimelinePoints = [];
  const q = query(
    collection(firestore, "timeline_points"),
    where("deliverableId", "==", deliverableId)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundTimelinePoints.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return foundTimelinePoints;
};

const createSpatialDatas = async (spatialDatasArray, timelinePointId) => {
  const batch = writeBatch(firestore);
  spatialDatasArray.forEach((spatialData) => {
    const newSpatialDataRef = doc(collection(firestore, "spatial-data"));
    batch.set(newSpatialDataRef, { ...spatialData, timelinePointId });
  });
  return batch.commit();
};

const getSpatialDataByPointIdsArray = async (timelinePointIdsArray) => {
  let foundSpatialData = [];
  const spliceableIds = new Array(...timelinePointIdsArray);
  while (spliceableIds.length) {
    const splicedIds = spliceableIds.splice(0, 10);
    const q = query(
      collection(firestore, "spatial-data"),
      where("timelinePointId", "in", splicedIds)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      foundSpatialData.push({
        id: doc.id,
        ...doc.data(),
        ref: doc.ref,
      });
    });
  }
  return foundSpatialData;
};

const createScanDatas = async (scanDataArray, timelinePointId) => {
  const batch = writeBatch(firestore);
  scanDataArray.forEach((scanData) => {
    const newScanDataRef = doc(collection(firestore, "scan-data"));
    batch.set(newScanDataRef, { ...scanData, timelinePointId });
  });
  return batch.commit();
};

const getScanDatasByPointIdsArray = async (timelinePointIdsArray) => {
  let foundScanData = [];
  const spliceableIds = new Array(...timelinePointIdsArray);
  while (spliceableIds.length) {
    const splicedIds = spliceableIds.splice(0, 10);
    const q = query(
      collection(firestore, "scan-data"),
      where("timelinePointId", "in", splicedIds)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      foundScanData.push({
        id: doc.id,
        ...doc.data(),
        ref: doc.ref,
      });
    });
  }
  return foundScanData;
};

const getProjectTimelinePoints = async (deliverableIdsArray) => {
  const foundTimelinePoints = [];
  const spliceableIds = new Array(...deliverableIdsArray);
  while (spliceableIds.length) {
    const splicedIds = spliceableIds.splice(0, 10);
    const q = query(
      collection(firestore, "timeline_points"),
      where("deliverableId", "in", splicedIds)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      for (let i = 0; i < querySnapshot.docs.length; i++) {
        foundTimelinePoints.push({
          id: querySnapshot.docs[i].id,
          ...querySnapshot.docs[i].data(),
        });
      }
    }
  }
  return foundTimelinePoints;
};

const deleteSpatialDatas = async ({ spatialDataArray }) => {
  try {
    if (spatialDataArray.length) {
      const batch = writeBatch(firestore);
      spatialDataArray.forEach((spatialData) => {
        batch.delete(doc(firestore, "spatial-data", spatialData.id));
      });
      return batch.commit();
    }
  } catch (error) {
    throw error;
  }
};

const deleteScanDatas = async ({ scanDataArray }) => {
  try {
    if (scanDataArray.length) {
      const batch = writeBatch(firestore);
      scanDataArray.forEach((scanData) => {
        batch.delete(doc(firestore, "scan-data", scanData.id));
      });
      return batch.commit();
    }
  } catch (error) {
    throw error;
  }
};

const updateTimelinePoint = async ({
  spatialDatasArray,
  scanDataArray,
  date,
  timelinePoint,
  deletedSpatialDataArray,
  deletedScanDataArray,
}) => {
  const batch = writeBatch(firestore);
  if (date) {
    const pointExists = await timelinePointExists({
      date,
      deliverableIdsArray: [timelinePoint.deliverableId],
    });
    if (pointExists && pointExists.length) {
      throw new Error("Timeline point exists already");
    }
    const timelineRef = doc(firestore, "timeline_points", timelinePoint.id);
    batch.update(timelineRef, { date });
  }

  if (deletedScanDataArray && deletedScanDataArray.length) {
    deletedScanDataArray.forEach((scanData) => {
      batch.delete(scanData.ref);
    });
  }

  if (deletedSpatialDataArray && deletedSpatialDataArray.length) {
    deletedSpatialDataArray.forEach((spatialData) => {
      batch.delete(spatialData.ref);
    });
  }

  if (spatialDatasArray && spatialDatasArray.length) {
    spatialDatasArray.forEach((spatialData) => {
      if (spatialData.id) {
        batch.update(doc(firestore, "spatial-data", spatialData.id), {
          sourceId: spatialData.sourceId,
          sourceName: spatialData.sourceName,
          sourceType: spatialData.sourceType,
          timelinePointId: timelinePoint.id,
        });
      } else {
        const newSpatialDataRef = doc(collection(firestore, "spatial-data"));
        batch.set(newSpatialDataRef, {
          sourceId: spatialData.sourceId,
          sourceName: spatialData.sourceName,
          sourceType: spatialData.sourceType,
          timelinePointId: timelinePoint.id,
        });
      }
    });
  }
  if (scanDataArray && scanDataArray.length) {
    scanDataArray.forEach((scanData) => {
      if (scanData.id) {
        batch.update(doc(firestore, "scan-data", scanData.id), {
          link: scanData.link,
          name: scanData.name,
          timelinePointId: timelinePoint.id,
        });
      } else {
        const newScanDataRef = doc(collection(firestore, "scan-data"));
        batch.set(newScanDataRef, {
          link: scanData.link,
          name: scanData.name,
          timelinePointId: timelinePoint.id,
        });
      }
    });
  }

  return batch.commit();
};

export {
  createTimelinePoint,
  getTimelinePointById,
  getTimelinePointsByDeliverableId,
  createSpatialDatas,
  getSpatialDataByPointIdsArray,
  createScanDatas,
  getScanDatasByPointIdsArray,
  getProjectTimelinePoints,
  updateTimelinePoint,
  deleteSpatialDatas,
  deleteScanDatas,
};
