import { useContext, useState } from "react";
import { Button, Modal } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Alert } from "../alert";
import { updateStatus } from "../../queries/deliverables";
import {
  getOrganisationProjectByProjectId,
  getProjectById,
} from "../../queries/projects";
import { getOrganisationsByIdArray } from "../../queries/organisations";
import {
  sendApprovedDeliverableNotification,
  sendDeliverableRejectionNotification,
  sendNewDeliverableNotification,
} from "../../configurations/firebaseConfig";
import { AuthContext } from "../../contexts";

const DeliverableNavActions = ({ deliverable }) => {
  const { userInfo } = useContext(AuthContext);
  const [actionName, setActionName] = useState("");
  const [showAlertModal, setShowAlertModal] = useState(false);
  const handleCloseAlert = () => setShowAlertModal(false);
  const handleShowAlert = () => setShowAlertModal(true);

  const updateDeliverableStatus = async ({ status, emails }) => {
    await updateStatus(deliverable.id, status);
    if (status === "PENDING") {
      const project = await getProjectById(deliverable.projectId);
      const organisationsProjects = await getOrganisationProjectByProjectId(
        deliverable.projectId
      );
      const organisationIds = [];
      organisationsProjects.forEach((organisationProject) => {
        organisationIds.push(organisationProject.organisationId);
      });
      const organisations = await getOrganisationsByIdArray(organisationIds);
      if (emails && emails.length) {
        await sendNewDeliverableNotification({
          emails: emails,
          submitterName: userInfo.firstName + " " + userInfo.lastName,
          projectName: project.name,
          organisationNames: organisations[0].name,
          deliverableLink: `https://${process.env.REACT_APP_BASE_URL}/supervisorDashboard/organisations/${organisations[0].id
            }/projects/${deliverable.projectId}/deliverables/${deliverable.id
            }/${String(deliverable.name).replace(" ", "%20")}`,
        });
      }
    } else if (status === "APPROVED") {
      const project = await getProjectById(deliverable.projectId);
      const organisationsProjects = await getOrganisationProjectByProjectId(
        deliverable.projectId
      );
      const organisationIds = [];
      organisationsProjects.forEach((organisationProject) => {
        organisationIds.push(organisationProject.organisationId);
      });
      if (emails && emails.length) {
        await sendApprovedDeliverableNotification({
          emails: emails,
          projectName: project.name,
          deliverableLink: `https://${process.env.REACT_APP_BASE_URL}`,
        });
      }
    } else if (status === "REJECTED") {
      const project = await getProjectById(deliverable.projectId);
      const organisationsProjects = await getOrganisationProjectByProjectId(
        deliverable.projectId
      );
      const organisationIds = [];
      organisationsProjects.forEach((organisationProject) => {
        organisationIds.push(organisationProject.organisationId);
      });
      const organisations = await getOrganisationsByIdArray(organisationIds);
      if (emails && emails.length) {
        await sendDeliverableRejectionNotification({
          emails: emails,
          submitterName: userInfo.firstName + " " + userInfo.lastName,
          projectName: project.name,
          organisationNames: organisations[0].name,
          deliverableLink: `https:/${process.env.REACT_APP_BASE_URL}/dataprocessorDashboard/organisations/${organisations[0].id
            }/projects/${deliverable.projectId}/deliverables/${deliverable.id
            }/${String(deliverable.name).replace(" ", "%20")}`,
          deliverableName: deliverable.name,
        });
      }
    }
  };

  return (
    <div>
      <Button
        icon={<CloseCircleOutlined />}
        className="reject-nav-button"
        onClick={() => {
          setActionName("Reject");
          handleShowAlert();
        }}
      >
        Reject
      </Button>
      <Button
        icon={<CheckCircleOutlined />}
        className="approve-nav-button"
        onClick={() => {
          setActionName("Approve");
          handleShowAlert();
        }}
      >
        Approve
      </Button>

      <Modal
        className="delete-popup-container"
        visible={showAlertModal}
        centered
        footer={false}
        onCancel={handleCloseAlert}
        maskClosable={false}
        closable={false}
      >
        <Alert
          handleCloseAlert={handleCloseAlert}
          itemName={deliverable.name}
          deleteDeliverable={undefined}
          updateDeliverableStatus={updateDeliverableStatus}
          actionName={actionName}
        />
      </Modal>
    </div>
  );
};

export { DeliverableNavActions };
