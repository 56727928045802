import { Button, Form, Input } from "antd";
import { CameraFilled } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../configurations/firebaseConfig";
import { updateUserProfile } from "../../queries/users";
import { useMutation } from "react-query";
const EditProfile = ({ selfClose }) => {
  const { userInfo, fetchAndUpdateUserInfo } = useContext(AuthContext);
  const [form] = Form.useForm();

  const [currentImage, setCurrentImage] = useState(
    "https://firebasestorage.googleapis.com/v0/b/intel-construction.appspot.com/o/icons%2Fuser-icon.jpg?alt=media&token=de1df897-6f5d-4c78-aeb6-f5a87afb4414"
  );

  const changeImage = (file) => {
    const fr = new FileReader();
    fr.onload = function (e) {
      document.getElementById("image").src = this.result;
    };
    fr.readAsDataURL(file);
  };

  const handleSubmit = async ({ firstName, lastName }) => {
    if (firstName && firstName.length < 3) {
      throw new Error("First name must be atleast 3 characters long");
    } else if (lastName && lastName.length < 3) {
      throw new Error("Last name must be atleast 3 characters long");
    } else {
      try {
        const newData = {};
        if (typeof currentImage === "object") {
          let firebaseStoragePath = `/profiles/users/${userInfo.userId}`;
          const fileReference = new ref(storage, `${firebaseStoragePath}`);
          const response = await new uploadBytes(fileReference, currentImage);
          const downloadURL = await getDownloadURL(response.ref);
          newData.profilePhoto = downloadURL;
        }
        if (userInfo.firstName !== firstName) {
          newData.firstName = String(firstName).trim();
        }
        if (userInfo.lastName !== lastName) {
          newData.lastName = String(lastName).trim();
        }
        if (Object.keys(newData).length) {
          await updateUserProfile({ userId: userInfo.userId, ...newData });
        }
        fetchAndUpdateUserInfo({ user: userInfo });
        selfClose();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const { isLoading, mutate: updateUserInfo } = useMutation(
    ({ firstName, lastName }) => handleSubmit({ firstName, lastName })
  );

  useEffect(() => {
    if (userInfo) {
      if (userInfo.firstName && userInfo.lastName) {
        form.setFieldsValue({
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
        });
      }
      if (userInfo.profilePhoto) {
        setCurrentImage(userInfo.profilePhoto);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <div>
      <input
        type="file"
        id="profilePhoto"
        onChange={(e) => {
          if (e.target.files[0] !== undefined) {
            changeImage(e.target.files[0]);
            setCurrentImage(e.target.files[0]);
          }
        }}
        style={{ display: "none" }}
        accept="image/png, image/jpeg, image/jpg, image/bmp"
      />
      {userInfo && (
        <Form form={form} onFinish={updateUserInfo}>
          <div className="edit-profile-body">
            <div className="edit-profile-cursor">
              <div className="edit-profile-photo-container">
                <img
                  id={"image"}
                  alt=""
                  src={currentImage}
                  style={{
                    height: 150,
                    width: 150,
                    borderRadius: 100,
                    objectFit: "cover",
                    boxShadow: "0 0 0 1px lightgrey",
                  }}
                />
              </div>
              <div
                className="hover-photo-user"
                onClick={() => {
                  const imageFileInputElement =
                    document.getElementById("profilePhoto");
                  imageFileInputElement?.click();
                }}
              >
                <CameraFilled />
                Change Profile Photo
              </div>
            </div>
          </div>
          <label>First Name</label>
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please add your first name",
              },
              {
                pattern: new RegExp(/^[a-zA-Z ]+$/i),
                message: "no special characters allowed",
              },
              {
                message: "should be atleast 3 characters long",
                validator: (_, value) => {
                  if (
                    value &&
                    value.length &&
                    String(value).trim().length >= 3
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Name not valid");
                  }
                },
              },
            ]}
            style={{
              width: "calc(100%)",
            }}
          >
            <Input />
          </Form.Item>
          <label>Last Name</label>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please add your last name",
              },
              {
                pattern: new RegExp(/^[a-zA-Z ]+$/i),
                message: "no special characters allowed",
              },
              {
                message: "should be atleast 3 characters long",
                validator: (_, value) => {
                  if (
                    value &&
                    value.length &&
                    String(value).trim().length >= 3
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Name not valid");
                  }
                },
              },
            ]}
            style={{
              width: "calc(100%)",
            }}
          >
            <Input />
          </Form.Item>
          <Button
            style={{ width: "100%", marginBottom: 10 }}
            className="login-button"
            htmlType="submit"
            loading={isLoading}
          >
            Update
          </Button>
        </Form>
      )}
    </div>
  );
};

export { EditProfile };
