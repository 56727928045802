import { Row, Skeleton } from "antd";

const OrganisationLoading = () => {
  return (
    <Row>
      <Skeleton.Input active block={true} style={{ backgroundColor: "#fff" }} />
    </Row>
  );
};

export { OrganisationLoading };
