import { firestore } from "../configurations/firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  getOrganisationProjectByProjectId,
  getProjectsByIdArray,
} from "./projects";
import { getOrganisationsByIdArray } from "./organisations";

const createDeliverable = async (name, projectId, uploadedBy) => {
  return addDoc(collection(firestore, "deliverables"), {
    name: String(name).trim().toUpperCase(),
    projectId,
    uploadDate: new Date(),
    status: "NOT-READY",
    uploadedBy,
  });
};

const getDeliverableById = async (id) => {
  let foundDeliverable;
  const docRef = doc(firestore, "deliverables", String(id).trim());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists() && !docSnap.data().isDeleted) {
    foundDeliverable = {
      id: docSnap.id,
      ...docSnap.data(),
    };
  }
  return foundDeliverable;
};

const getAllProjectDeliverables = async (projectId) => {
  let foundDeliverables = [];
  const q = query(
    collection(firestore, "deliverables"),
    where("projectId", "==", String(projectId).trim())
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (!doc.data().isDeleted) {
      foundDeliverables.push({
        id: doc.id,
        ...doc.data(),
      });
    }
  });
  foundDeliverables = foundDeliverables.sort((a, b) => {
    const aDate = new Date(a.uploadDate.toDate());
    const bDate = new Date(b.uploadDate.toDate());
    if (aDate > bDate) {
      return 1;
    }
    return -1;
  });
  return foundDeliverables;
};

const getApprovedDeliverables = async (projectId) => {
  let foundDeliverables = [];
  const q = query(
    collection(firestore, "deliverables"),
    where("projectId", "==", String(projectId).trim()),
    where("status", "==", "APPROVED")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (!doc.data().isDeleted) {
      foundDeliverables.push({
        id: doc.id,
        ...doc.data(),
      });
    }
  });
  foundDeliverables = foundDeliverables.sort((a, b) => {
    const aDate = new Date(a.uploadDate.toDate());
    const bDate = new Date(b.uploadDate.toDate());
    if (aDate > bDate) {
      return 1;
    }
    return -1;
  });
  return foundDeliverables;
};

const getProjectDeliverableByName = async (projectId, name) => {
  let foundDeliverable;
  const q = query(
    collection(firestore, "deliverables"),
    where("name", "==", String(name).trim().toUpperCase()),
    where("projectId", "==", String(projectId))
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (!doc.data().isDeleted) {
      foundDeliverable = {
        id: doc.id,
        ...doc.data(),
      };
    }
  });
  return foundDeliverable;
};

const deleteDeliverableById = async (id) => {
  return updateDoc(doc(firestore, "deliverables", String(id).trim()), {
    isDeleted: true,
  });
};

const updateStatus = async (id, status) => {
  const docRef = doc(firestore, "deliverables", String(id).trim());
  return updateDoc(docRef, {
    status,
  });
};

const updateDownloadLink = async ({ id, downloadLink }) => {
  const docRef = doc(firestore, "deliverables", String(id).trim());
  return updateDoc(docRef, {
    downloadLink,
  });
};

const getAllPendingDeliverables = async () => {
  let foundDeliverables = [];
  const q = query(
    collection(firestore, "deliverables"),
    where("status", "==", "PENDING")
  );
  const querySnapshot = await getDocs(q);

  for (let docu of querySnapshot.docs) {
    const { projectId } = docu.data();
    const organisationsProjects = await getOrganisationProjectByProjectId(
      projectId
    );
    const organisationIds = [];
    const projectIds = [];
    organisationsProjects.forEach((organisationProject) => {
      organisationIds.push(organisationProject.organisationId);
      projectIds.push(organisationProject.projectId);
    });

    const organisations = await getOrganisationsByIdArray(organisationIds);
    const projects = await getProjectsByIdArray(projectIds);
    if (!docu.data().isDeleted) {
      foundDeliverables.push({
        id: docu.id,
        ...docu.data(),
        organisations,
        project: projects[0],
      });
    }
  }
  foundDeliverables = foundDeliverables.sort((a, b) => {
    const aDate = new Date(a.uploadDate.toDate());
    const bDate = new Date(b.uploadDate.toDate());
    if (aDate > bDate) {
      return 1;
    }
    return -1;
  });
  return foundDeliverables;
};

export {
  createDeliverable,
  getDeliverableById,
  getAllProjectDeliverables,
  getProjectDeliverableByName,
  deleteDeliverableById,
  updateStatus,
  getAllPendingDeliverables,
  getApprovedDeliverables,
  updateDownloadLink,
};
