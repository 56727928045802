import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import "./menu.css";

const ProjectMenu = () => {
  return (
    <div className="menu-options">
      <span className="download-button">
        <DownloadOutlined /> Download
      </span>
      <span className="delete-button">
        <DeleteOutlined /> Delete
      </span>
    </div>
  );
};

export { ProjectMenu };
