import { useContext, useState } from "react";
import { Alert, Button, Form, Input, Modal } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { deleteObject, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../configurations/firebaseConfig";
import {
  createDeliverable,
  deleteDeliverableById,
  getProjectDeliverableByName,
} from "../../queries/deliverables";
import "./forms.css";
import { useParams } from "react-router-dom";
import { ProgressBar } from "../loading";
import { AuthContext, OrganisationsContext } from "../../contexts";

const UploadDeliverables = ({ selfClose }) => {
  const { userInfo } = useContext(AuthContext);
  const { fetchProjectDeliverables } = useContext(OrganisationsContext);

  const { projectId } = useParams();
  const [form] = Form.useForm();

  const [showProgressModal, setShowProgressModal] = useState(false);
  const handleCloseProgress = () => setShowProgressModal(false);
  const handleShowProgress = () => setShowProgressModal(true);

  const [files, setFiles] = useState();
  const [filesStatus, setFilesStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentlyUploadingFileName, setCurrentlyUploadingFileName] =
    useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [completionCount, setCompletionCount] = useState(0);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [uploadTask, setUploadTask] = useState();
  const [uploadCancelled, setUploadCancelled] = useState(false);

  const cancelDeliverableUpload = async (newDeliverable, filesToBeUploaded) => {
    setCancelLoading(true);
    try {
      await Promise.all(
        filesToBeUploaded.map((file) => {
          let fileRelativePath = file.webkitRelativePath.substring(
            file.webkitRelativePath.indexOf("/") + 1,
            file.webkitRelativePath.length
          );
          const firebaseStoragePath = `/deliverables/${projectId}/${newDeliverable.id}/${fileRelativePath}`;
          return deleteObject(ref(storage, `${firebaseStoragePath}`));
        })
      );
    } catch (error) { }
    try {
      await deleteDeliverableById(newDeliverable.id);
    } catch (error) { }
    setCancelLoading(false);
    setCurrentlyUploadingFileName("Upload canceled.");
    setUploadPercentage(0);
  };

  const saveDatabaseDeliverable = async (deliverableName) => {
    const deliverableExists = await getProjectDeliverableByName(
      projectId,
      deliverableName
    );
    if (deliverableExists) {
      throw new Error(`Deliverable named ${deliverableName} already exists`);
    }
    return createDeliverable(deliverableName, projectId, userInfo.userId);
  };

  const uploadFiles = async (filesToUpload, newDeliverable) => {
    // calculating total size to be uploaded
    let totalBytesToUpload = 0;
    for (let i = 0; i < filesToUpload.length; i++) {
      totalBytesToUpload += filesToUpload[i].size;
    }
    // uploading files one at a time
    for (let i = 0; i < filesToUpload.length; i++) {
      const updatedFiles = filesStatus.map((file, index) => {
        if (index === i) {
          file.uploadStatus = "uploading";
        }
        return file;
      });

      setFilesStatus(updatedFiles);

      let fileRelativePath = filesToUpload[i].webkitRelativePath.substring(
        filesToUpload[i].webkitRelativePath.indexOf("/") + 1,
        filesToUpload[i].webkitRelativePath.length
      );
      let firebaseStoragePath = `/deliverables/${projectId}/${newDeliverable.id}/${fileRelativePath}`;

      setCurrentlyUploadingFileName(`Uploading ${fileRelativePath}`);
      const fileReference = new ref(storage, `${firebaseStoragePath}`);
      const uploadTask = new uploadBytesResumable(
        fileReference,
        filesToUpload[i]
      );
      setUploadTask(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          switch (snapshot.state) {
            case "paused":
              // handle pause
              break;
            case "running":
              let uploadedBytes = snapshot.bytesTransferred;
              for (let ind = 0; ind < i; ind++) {
                uploadedBytes += filesToUpload[ind].size;
              }
              setUploadPercentage(
                ((uploadedBytes / totalBytesToUpload) * 100).toFixed(2)
              );
              break;
            case "canceled":
              // handle canceled
              break;
            default:
            // default
          }
        },
        async (error) => {
          // Handle unsuccessful uploads
          const updatedFiles = filesStatus.map((file, index) => {
            if (index === i) {
              file.uploadStatus = "failed";
            }
            return file;
          });

          setFilesStatus(updatedFiles);

          setCurrentlyUploadingFileName("Error uploding files! Canceling...");
          cancelDeliverableUpload(newDeliverable, filesStatus);

          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;

            default:
            // handle default
          }
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          if (i === filesToUpload.length - 1) {
            setUploadPercentage(100);
            fetchProjectDeliverables(projectId);
          }
          const updatedFiles = filesStatus.map((file, index) => {
            if (index === i) {
              file.uploadStatus = "uploaded";
              file.storageRef = uploadTask.snapshot.ref;
            }
            return file;
          });

          setFilesStatus(updatedFiles);
        }
      );
      await uploadTask;
      setCompletionCount(i + 1);
    }
  };

  const handleSubmit = async ({ deliverableName }) => {
    setLoading(true);
    setErrorMessage("");
    try {
      if (files && files.length && deliverableName !== "") {
        const newDeliverable = await saveDatabaseDeliverable(deliverableName);
        setLoading(false);
        selfClose();
        form.resetFields();
        setUploadCancelled(false);
        handleShowProgress();

        await uploadFiles(files, newDeliverable);
      } else {
        throw new Error("Please upload a deliverable folder");
      }
    } catch (error) {
      if (
        error.message === `Deliverable named ${deliverableName} already exists`
      ) {
        setErrorMessage(`Deliverable named ${deliverableName} already exists`);
      } else if (error.message === "Please upload a deliverable folder") {
        setErrorMessage("Please upload a deliverable folder");
      }
    }
    setLoading(false);
  };

  return (
    <div>
      <p>{errorMessage && <Alert type="error" message={errorMessage} />}</p>
      <Form className="form" onFinish={handleSubmit} form={form}>
        <input
          type="file"
          id="deliverableUploadBtn"
          onChange={async (e) => {
            if (e.target.files && e.target.files.length) {
              setErrorMessage("");
              setFiles(e.target.files);
              setFilesStatus([...e.target.files]);
            }
          }}
          style={{ display: "none" }}
          multiple
          directory=""
          webkitdirectory="true"
          moxdirectory=""
        />
        <Form.Item
          name="deliverableName"
          rules={[{ required: true, message: "Please add deliverable name" }]}
          style={{ display: "inline-block", width: "calc(70%)" }}
        >
          <Input placeholder="Deliverable name" />
        </Form.Item>
        <Form.Item
          wrapperCol={{ offset: 1 }}
          style={{ display: "inline-block", width: "calc(30%)" }}
        >
          <Button
            onClick={() => {
              document.getElementById("deliverableUploadBtn")?.click();
            }}
            icon={<PaperClipOutlined />}
            style={{ width: "100%" }}
          >
            Upload Folder
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            loading={loading}
            style={{ width: "100%" }}
            className="login-button"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
      <Modal
        centered
        style={{ borderRadius: 40 }}
        visible={showProgressModal}
        footer={false}
        className="progress-bar-modal"
        onCancel={handleCloseProgress}
        maskClosable={false}
        closable={false}
      >
        <ProgressBar
          uploadPercentage={uploadPercentage}
          completionCount={completionCount}
          currentlyUploadingFileName={currentlyUploadingFileName}
          files={filesStatus}
          cancelLoading={cancelLoading}
          selfClose={handleCloseProgress}
          uploadTask={uploadTask}
          uploadCancelled={uploadCancelled}
          setUploadCancelled={setUploadCancelled}
          key="key"
        />
      </Modal>
    </div>
  );
};
export { UploadDeliverables };
