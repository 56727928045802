import { createContext, useCallback, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendSignInLinkToEmail,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  onAuthStateChanged,
  signInAnonymously,
  EmailAuthProvider,
  linkWithCredential,
} from "firebase/auth";
import {
  analytics,
  auth,
  requestApiAuthorization,
} from "../configurations/firebaseConfig";
import { findUserByEmail } from "../queries/users";
import { useQuery } from "react-query";
import { LoadingLayout } from "../components";
import { logEvent } from "firebase/analytics";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  if (window.location.hostname === "intel-construction.web.app") {
    window.location.replace("https://charisanalytics.com/");
  }
  const [userInfo, setUserInfo] = useState();
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [authenticationError, setAuthenticationError] = useState("");

  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const signin = (email, password) => {
    setAuthenticationError("");
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signInAnonymousUser = () => {
    return signInAnonymously(auth);
  };

  const signout = () => {
    signOut(auth);
  };

  const sendSignInLink = (email) => {
    const baseUrl = window.location.href.split(
      `/${window.location.pathname.split("/")[1]}`
    )[0];
    return sendSignInLinkToEmail(email, {
      url: `${baseUrl}/setprofile/${email}`,
      handleCodeInApp: true,
    });
  };

  // to add this object as the last parameter of sendPasswordResetEmail
  // when the app is hosted and has a app-whitelisted domain name
  const actionCodeSettings = {
    // After password reset, the user will be give the ability to go back
    // to this page.
    url: "https://charisanalytics.com/",
    handleCodeInApp: false,
  };

  const sendResetPasswordLink = (email) => {
    return sendPasswordResetEmail(auth, email, actionCodeSettings);
  };

  const isSignInWithEmail = (href) => {
    return isSignInWithEmailLink(href);
  };

  const signInWithEmail = (email, href) => {
    return signInWithEmailLink(email, href);
  };

  const isProfileSetup = async (email, token) => {
    const result = await findUserByEmail(email);
    if (!result) {
      window.location.replace("/login");
    } else {
      const {
        email: registeredEmail,
        token: registeredToken,
        isProfileSet,
      } = result;
      if (registeredEmail !== email || token !== registeredToken) {
        window.location.replace("/login");
      }
      return isProfileSet;
    }
  };

  const convertAnonymousToPermanent = async (email, password) => {
    const credential = EmailAuthProvider.credential(email, password);
    if (auth.currentUser) {
      await linkWithCredential(auth.currentUser, credential);
      await signin(email, password);
    }
    return undefined;
  };

  const fetchAndUpdateUserInfo = async ({ user }) => {
    findUserByEmail(user.email).then((result) => {
      setUserInfo({
        ...user,
        ...result,
      });
      // log authentication event here
      const { firstName, lastName, email, role, organisationId } = result;
      logEvent(analytics, "auth_event", {
        users: JSON.stringify({
          firstName,
          lastName,
          email,
          role,
          organisationId,
        }),
      });
    });
  };

  const redirect = useCallback(() => {
    if (userInfo) {
      if (userInfo.isSuspended) {
        setAuthenticationError(
          `Your account has been suspended!\nPlease contact support.`
        );
        signout();
      } else {
        const { role } = userInfo;
        if (role === "SUPERVISOR") {
          if (!window.location.href.includes("supervisorDashboard")) {
            window.location.replace("/supervisorDashboard");
          }
        } else if (role === "DATA-PROCESSOR") {
          if (!window.location.href.includes("dataprocessorDashboard")) {
            window.location.replace("/dataprocessorDashboard");
          }
        } else if (role === "SENIOR") {
          if (!window.location.href.includes("seniorDashboard")) {
            window.location.replace("/seniorDashboard");
          }
        } else if (role === "SUBORDINATE") {
          if (!window.location.href.includes("subordinateDashboard")) {
            window.location.replace("/subordinateDashboard");
          }
        } else {
          signout();
        }
      }
    }
  }, [userInfo]);

  const getApiAuthorizationToken = async () => {
    try {
      const response = await requestApiAuthorization();
      if (response && response.data && response.data.token) {
        return response.data.token;
      }
    } catch (error) {
      throw new Error("Unable to get api key");
    }
  };

  const {
    data: authorizationToken,
    isLoading: apiKeyLoading,
    error: apiAuthorizationError,
    refetch: refetchApiAuthorization,
  } = useQuery("getApiAuthorizationKey", getApiAuthorizationToken);

  const valuesToPassDown = {
    userInfo,
    signup,
    signin,
    signout,
    sendSignInLink,
    sendResetPasswordLink,
    isSignInWithEmail,
    signInWithEmail,
    setUserInfo,
    signInAnonymousUser,
    isProfileSetup,
    convertAnonymousToPermanent,
    redirect,
    authenticationError,
    fetchAndUpdateUserInfo,
    authorizationToken,
    apiAuthorizationError,
    apiKeyLoading,
    refetchApiAuthorization,
    isAuthLoading,
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email) {
        fetchAndUpdateUserInfo({ user });
      } else {
        if (
          !window.location.href.includes("login") &&
          !window.location.href.includes("setprofile")
        ) {
          window.location.replace("/login");
        }
      }
      setTimeout(() => {
        setIsAuthLoading(false);
      }, 2000);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.role && !userInfo.isAnonymous) {
        redirect();
      }
    }
  }, [redirect, userInfo]);

  useEffect(() => {}, [userInfo]);

  return (
    <AuthContext.Provider value={valuesToPassDown}>
      {isAuthLoading ? <LoadingLayout /> : children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
