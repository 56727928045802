import { Card, Skeleton } from "antd";
import Meta from "antd/lib/card/Meta";

const FileLoading = () => {
  const size = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className="deliverables-container">
      <div>
        <div className="deliverables-list">
          {size.map((el) => (
            <Card
              key={String(el)}
              style={{
                width: 130,
              }}
              cover={
                <Skeleton.Input active style={{ height: 100 }} size={"small"} />
              }
            >
              <Meta title={<Skeleton.Input active size={15} />}></Meta>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export { FileLoading };
