import { Button, Checkbox, Col, Divider, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { OrganisationsContext } from "../../contexts";
import { useParams } from "react-router-dom";
import "./alert.css";
import { DELIVERABLE_STATUS } from "../../enums";
import { useMutation } from "react-query";
import {
  getAllUsersByRole,
  getUsersByOrganisationId,
} from "../../queries/users";
import { getUsersIdsAccessingProject } from "../../queries/userProjectAccess";
const CheckboxGroup = Checkbox.Group;
const Alert = ({
  handleCloseAlert,
  deleteDeliverable,
  itemName,
  updateDeliverableStatus,
  actionName,
  setNeedsRefetch,
  selectedDeliverable,
}) => {
  const { fetchProjectDeliverables } = useContext(OrganisationsContext);
  const { projectId, organisationId } = useParams();

  const [actionLoading, setActionLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [notificationRecepientsConfirmed, setNotificationRecepientsConfirmed] =
    useState(false);

  useEffect(() => {
    if (actionName === "Delete") {
      setNotificationRecepientsConfirmed(true);
    } else {
      setNotificationRecepientsConfirmed(false);
    }
  }, [actionName, itemName]);

  const { isLoading, mutate: fetchEmails } = useMutation(
    async (action) => {
      if (action === "Submit") {
        const accountResults = await getAllUsersByRole("SUPERVISOR");

        let membersArray = [];
        if (accountResults && accountResults.length) {
          accountResults.forEach((user) => {
            if (user.firstName) {
              membersArray.push({
                label: `${user.firstName} ${user.lastName}`,
                value: user.email,
              });
            }
          });
        }
        setEmails(membersArray);
        if (membersArray && membersArray.length === 0) {
          setNotificationRecepientsConfirmed(true);
        }
        return membersArray;
      } else if (action === "Approve") {
        let orgId = organisationId;
        let projId = projectId;
        if (!organisationId) {
          orgId = selectedDeliverable.organisations[0].id;
          projId = selectedDeliverable.project.id;
        }
        const organisationMembers = await getUsersByOrganisationId(orgId);
        const usersAccessingProject = await getUsersIdsAccessingProject({
          projectId: projId,
        });
        let membersArray = [];
        organisationMembers.forEach((member) => {
          if (member.role === "SUBORDINATE") {
            usersAccessingProject.forEach((allowedMember) => {
              if (allowedMember.userId === member.userId) {
                if (member.firstName) {
                  membersArray.push({
                    label: `${member.firstName} ${member.lastName}`,
                    value: member.email,
                  });
                }
              }
            });
          } else {
            if (member.firstName) {
              membersArray.push({
                label: `${member.firstName} ${member.lastName}`,
                value: member.email,
              });
            }
          }
        });
        setEmails(membersArray);
        if (membersArray && membersArray.length === 0) {
          setNotificationRecepientsConfirmed(true);
        }
        return membersArray;
      } else if (action === "Reject") {
        const accountResults = await getAllUsersByRole("DATA-PROCESSOR");

        let membersArray = [];
        if (accountResults && accountResults.length) {
          accountResults.forEach((user) => {
            if (user.firstName) {
              membersArray.push({
                label: `${user.firstName} ${user.lastName}`,
                value: user.email,
              });
            }
          });
        }
        setEmails(membersArray);
        if (membersArray && membersArray.length === 0) {
          setNotificationRecepientsConfirmed(true);
        }
        return membersArray;
      }
    },
    { refetchOnMount: true }
  );

  useEffect(() => {
    if (actionName) {
      fetchEmails(actionName);
    }
  }, [actionName]);

  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < emails.length);
    setCheckAll(list.length === emails.length);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked ? [...emails.map((superv) => superv.value)] : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <div className="delete-alert-container">
      {!notificationRecepientsConfirmed && (
        <div>
          <h4 style={{ color: "#242063", width: "auto" }}>
            Please choose notification recipients
            <Divider />
          </h4>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
              style={{ marginBottom: 10, marginLeft: 3 }}
            >
              Check all
            </Checkbox>
            <CheckboxGroup
              options={[...emails]}
              value={checkedList}
              onChange={onChange}
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 3,
                height: "150px",
                overflow: "auto",
              }}
            />
          </div>
          <Row>
            <Col span={11}>
              <Button
                loading={isLoading}
                type="primary"
                className="login-button"
                style={{ width: "100%" }}
                onClick={() => {
                  if (checkedList && checkedList.length) {
                    setNotificationRecepientsConfirmed(true);
                  } else {
                    if (emails && emails.length === 0) {
                      setNotificationRecepientsConfirmed(true);
                    }
                  }
                }}
              >
                Continue
              </Button>
            </Col>
            <Col span={11} style={{ marginLeft: 39 }}>
              <Button
                type="primary"
                className="delete-alert-button"
                style={{ width: "100%" }}
                onClick={() => {
                  handleCloseAlert();
                  setNotificationRecepientsConfirmed(false);
                  setCheckedList([]);
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      )}
      {notificationRecepientsConfirmed && (
        <div>
          <span>
            Are you sure you want to {String(actionName).toLocaleLowerCase()}{" "}
            {itemName} ?
          </span>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Button
                className="login-button"
                style={{ width: "60%" }}
                loading={actionLoading}
                onClick={async () => {
                  setActionLoading(true);
                  if (actionName === "Delete") {
                    try {
                      await deleteDeliverable();
                      fetchProjectDeliverables(projectId);
                      handleCloseAlert();
                    } catch (error) {}
                  } else if (actionName === "Submit") {
                    try {
                      await updateDeliverableStatus({
                        status: DELIVERABLE_STATUS.PENDING,
                        emails: checkedList,
                      });
                      fetchProjectDeliverables(projectId);
                      if (setNeedsRefetch) {
                        setNeedsRefetch(true);
                      }
                      handleCloseAlert();
                    } catch (error) {}
                  } else if (actionName === "Approve") {
                    await updateDeliverableStatus({
                      status: DELIVERABLE_STATUS.APPROVED,
                      emails: checkedList,
                    });
                    fetchProjectDeliverables(projectId);
                    if (setNeedsRefetch) {
                      setNeedsRefetch(true);
                    }
                    handleCloseAlert();
                  } else if (actionName === "Reject") {
                    await updateDeliverableStatus({
                      status: DELIVERABLE_STATUS.REJECTED,
                      emails: checkedList,
                    });
                    fetchProjectDeliverables(projectId);
                    if (setNeedsRefetch) {
                      setNeedsRefetch(true);
                    }
                    handleCloseAlert();
                  }
                  setActionLoading(false);

                  setNotificationRecepientsConfirmed(false);
                  setCheckedList([]);
                }}
              >
                Yes
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="delete-alert-button"
                style={{ width: "60%" }}
                onClick={() => {
                  handleCloseAlert();
                  if (actionName !== "Delete") {
                    setNotificationRecepientsConfirmed(false);
                  } else {
                    setNotificationRecepientsConfirmed(true);
                  }
                  setCheckedList([]);
                }}
              >
                No
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export { Alert };
