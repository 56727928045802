import { Alert, Button, Form, Input } from "antd";
import { useState } from "react";
import {
  createOrganisationByName,
  findOrganisationByName,
} from "../../queries/organisations";
import { createUserByEmailAndRole, findUserByEmail } from "../../queries/users";
import { ROLES } from "../../enums";
import "./forms.css";

const NewOrganisation = ({ refetch }) => {
  const [form] = Form.useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState();

  const handleSubmit = async ({
    organisationName,
    organisationSeniorEmail,
  }) => {
    setLoading(true);
    try {
      setErrorMessage("");
      setSuccessMessage("");
      // Verifying if organization already exists, or if email address is already in use
      const organisationExists = await findOrganisationByName(organisationName);
      if (organisationExists) {
        throw new Error(`Organisation "${organisationName}" already exists`);
      }
      const userWithEmailExists = await findUserByEmail(
        organisationSeniorEmail
      );
      if (userWithEmailExists) {
        throw new Error(
          `Email address ${organisationSeniorEmail} is already in use`
        );
      }

      const newOrganisation = await createOrganisationByName(organisationName);
      await createUserByEmailAndRole(
        organisationSeniorEmail,
        ROLES.SENIOR,
        newOrganisation.id
      );
      refetch();
      setSuccessMessage("Organisation created successfully!");
      form.resetFields();
    } catch (error) {
      if (
        error.message === `Organisation "${organisationName}" already exists`
      ) {
        setErrorMessage(error.message);
      } else if (
        error.message ===
        `Email address ${organisationSeniorEmail} is already in use`
      ) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(
          "Sorry, we're unable to create the Organisation at the moment."
        );
      }
    }
    setLoading(false);
  };

  return (
    <div>
      <p>
        {errorMessage && <Alert type="error" message={errorMessage} />}
        {successMessage && <Alert type="success" message={successMessage} />}
      </p>
      <Form className="form" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="organisationName"
          label="Organisation's Name"
          rules={[
            {
              required: true,
              message:
                "Organisation's Name must contain more than 2 characters",
              validator: (_, value) => {
                if (value && value.length > 2) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Name not valid");
                }
              },
            },
          ]}
          style={{ width: "calc(100%)" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Organisation's Senior Email"
          name="organisationSeniorEmail"
          rules={[
            {
              required: true,
              type: "email",
              message: "This has to be a valid email address",
            },
          ]}
          style={{
            width: "calc(100%)",
          }}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          style={{
            width: "calc(100%)",
          }}
        >
          <Button
            style={{ width: "100%" }}
            className="login-button"
            loading={loading}
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { NewOrganisation };
