import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const SubordinateDashboard = lazy(() =>
  import("../components/dashboard/SubordinateDashboard").then((modules) => {
    return { default: modules.SubordinateDashboard };
  })
);
const DeliverableContents = lazy(() =>
  import("../views/organisationShared/DeliverableContents").then((modules) => {
    return { default: modules.DeliverableContents };
  })
);
const ProtectedView = lazy(() =>
  import("../views/ProtectedView").then((modules) => {
    return { default: modules.ProtectedView };
  })
);
const OrganisationLevelCesiumTimeline = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.OrganisationLevelCesiumTimeline };
  })
);
const OrganisationScanLevel = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.OrganisationScanLevel };
  })
);
const OrganisaitonTimelapse = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.OrganisaitonTimelapse };
  })
);
const ProjectDeliverables = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.ProjectDeliverables };
  })
);
const ProjectDeliverablesView = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.ProjectDeliverablesView };
  })
);
const SubordinateLanding = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.SubordinateLanding };
  })
);
const Support = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.Support };
  })
);
const SubordinateRoutes = () => {
  return (
    <Routes>
      <Route
        path="/subordinateDashboard"
        element={
          <ProtectedView
            element={
              <Suspense>
                <SubordinateDashboard />
              </Suspense>
            }
          />
        }
      >
        <Route
          index={true}
          element={
            <ProtectedView
              element={
                <Suspense>
                  <SubordinateLanding />
                </Suspense>
              }
            />
          }
        />
        <Route path="projects/:projectId">
          <Route
            path="deliverables"
            element={
              <ProtectedView
                element={
                  <Suspense>
                    <ProjectDeliverablesView />
                  </Suspense>
                }
              />
            }
          >
            <Route
              index={true}
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <ProjectDeliverables />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path=":deliverableId/:contentsPath"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <DeliverableContents />
                    </Suspense>
                  }
                />
              }
            />
          </Route>
          <Route path="timeline">
            <Route
              path="2D"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <OrganisationLevelCesiumTimeline />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="3D"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <OrganisationLevelCesiumTimeline />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="3DScan"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <OrganisationScanLevel />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="timelapse"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <OrganisaitonTimelapse />
                    </Suspense>
                  }
                />
              }
            />
          </Route>
        </Route>
        <Route
          path="support"
          element={
            <ProtectedView
              element={
                <Suspense>
                  <Support />
                </Suspense>
              }
            />
          }
        />
      </Route>
    </Routes>
  );
};

export { SubordinateRoutes };
