import { Button, DatePicker, Form, Tabs, Alert, Badge } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts";
import {
  createScanDatas,
  createSpatialDatas,
  createTimelinePoint,
  updateTimelinePoint,
} from "../../queries/timelinePoints";
import { ScanDataFormList } from "./ScanDataFormList";
import { ThreeDFormList } from "./ThreeDFormList";
import { TwoDFormList } from "./TwoDFormList";
import { TimelapseFormList } from "./TimelapseFormList";

const NewTimelinePoint = ({ deliverable, timelinePoint, refetchProject }) => {
  const [timelinePointForm] = Form.useForm();
  const { userInfo } = useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [twoDFormError, setTwoDFormError] = useState("");
  const [threeDFormError, setThreeDFormError] = useState("");
  const [scanDataFormError, setScanDataFormError] = useState("");
  const [timelapseFormError, setTimelapseFormError] = useState("");
  const [loading, setLoading] = useState(false);

  const saveTimelinePoint = async ({
    date,
    twoDSources,
    threeDSources,
    scanData,
  }) => {
    setSuccessMessage("");
    setErrorMessage("");
    setLoading(true);

    try {
      if (timelinePoint) {
        const spatialDatasArray = [];
        const scanDataArray = [];
        const deletedSpatialDataArray = [];
        const deletedScanDataArray = [];

        if (twoDSources) {
          twoDSources.forEach((twoDSource) => {
            spatialDatasArray.push(twoDSource);
          });
          // find removed twoDSources
          if (timelinePoint.twoDData && timelinePoint.twoDData.length) {
            const removedIds = timelinePoint.twoDData.filter((data) => {
              const stillPresent = twoDSources.filter(
                (presentSource) => presentSource.id === data.id
              );
              return stillPresent && stillPresent.length === 0;
            });
            deletedSpatialDataArray.push(...removedIds);
          }
        } else {
          if (timelinePoint.twoDData && timelinePoint.twoDData.length) {
            spatialDatasArray.push(...timelinePoint.twoDData);
          }
        }
        if (threeDSources) {
          threeDSources.forEach((threeDSource) => {
            spatialDatasArray.push(threeDSource);
          });
          // find removed threeDSources
          if (timelinePoint.threeDData && timelinePoint.threeDData.length) {
            const removedIds = timelinePoint.threeDData.filter((data) => {
              const stillPresent = threeDSources.filter(
                (presentSource) => presentSource.id === data.id
              );
              return stillPresent && stillPresent.length === 0;
            });
            deletedSpatialDataArray.push(...removedIds);
          }
        } else {
          if (timelinePoint.threeDData && timelinePoint.threeDData.length) {
            spatialDatasArray.push(...timelinePoint.threeDData);
          }
        }

        if (scanData) {
          scanData.forEach((scan) => {
            scanDataArray.push(scan);
          });
          if (timelinePoint.scanData && timelinePoint.scanData.length) {
            const removedLinks = timelinePoint.scanData.filter((data) => {
              const stillPresent = scanDataArray.filter(
                (presentSource) => presentSource.link === data.link
              );
              return stillPresent && stillPresent.length === 0;
            });
            deletedScanDataArray.push(...removedLinks);
          }
        } else {
          if (timelinePoint.scanData && timelinePoint.scanData.length) {
            scanDataArray.push(...timelinePoint.scanData);
          }
        }

        if (spatialDatasArray.length || scanDataArray.length) {
          const a = new Date(date.startOf("day")._d).toDateString();
          const b = new Date(timelinePoint.date.toDate()).toDateString();
          if (a === b) {
            await updateTimelinePoint({
              timelinePoint,
              scanDataArray,
              spatialDatasArray,
              deletedSpatialDataArray,
              deletedScanDataArray,
            });
          } else {
            await updateTimelinePoint({
              timelinePoint,
              date: new Date(date.startOf("day")._d),
              scanDataArray,
              spatialDatasArray,
              deletedSpatialDataArray,
              deletedScanDataArray,
            });
          }
          setSuccessMessage("Point updated successfully");
          await refetchProject();
        } else {
          setErrorMessage("Please add atleast one of the three timeline data");
        }
      } else {
        // goes here
        if (
          (twoDSources && twoDSources.length && twoDSources.length > 0) ||
          (threeDSources && threeDSources.length && threeDSources.length > 0) ||
          (scanData && scanData.length && scanData.length > 0)
        ) {
          const newTimelinePoint = await createTimelinePoint({
            date: new Date(date.startOf("day")._d),
            deliverableId: deliverable.id,
            projectId: deliverable.projectId,
          });
          if (
            (twoDSources && twoDSources.length) ||
            (threeDSources && threeDSources.length)
          ) {
            twoDSources && threeDSources
              ? await createSpatialDatas(
                  [...twoDSources, ...threeDSources],
                  newTimelinePoint.id
                )
              : twoDSources
              ? await createSpatialDatas([...twoDSources], newTimelinePoint.id)
              : await createSpatialDatas(
                  [...threeDSources],
                  newTimelinePoint.id
                );
          }
          if (scanData && scanData.length) {
            await createScanDatas(scanData, newTimelinePoint.id);
          }
          setSuccessMessage("Point saved successfully");
          timelinePointForm.resetFields();
          // goes here
        } else {
          setErrorMessage("Please add atleast one of the three timeline data");
        }
      }
    } catch (error) {
      if (error.message === "Timeline point exists already") {
        setErrorMessage(
          "The selected deliverable has another timeline point on the same date."
        );
      } else {
        setErrorMessage("Error saving timeline point");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (timelinePoint) {
      timelinePointForm.setFieldsValue({
        twoDSources: timelinePoint.twoDData ? timelinePoint.twoDData : [],
        threeDSources: timelinePoint.threeDData ? timelinePoint.threeDData : [],
        scanData: timelinePoint.scanData ? timelinePoint.scanData : [],
        date: moment(timelinePoint.date.toDate(), "DD/MM/YYYY"),
      });
    }
  }, [timelinePoint]);

  return (
    <div>
      <p>{errorMessage && <Alert type="error" message={errorMessage} />}</p>
      <p>
        {successMessage && <Alert type="success" message={successMessage} />}
      </p>
      <Form
        form={timelinePointForm}
        onFinish={({ date, twoDSources, threeDSources, scanData }) => {
          if (userInfo && userInfo.role && userInfo.role === "DATA-PROCESSOR") {
            saveTimelinePoint({
              date,
              twoDSources,
              threeDSources,
              scanData,
            });
          }
        }}
      >
        <Form.Item
          name="date"
          label="Date"
          wrapperCol={{ offset: 0 }}
          rules={[{ required: true, message: "Please select a date" }]}
        >
          <DatePicker picker="date" size="default" style={{ width: "50%" }} />
        </Form.Item>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane
            tab={
              <div>
                <span>2D Data</span>
                {twoDFormError && <Badge dot></Badge>}
              </div>
            }
            key="1"
          >
            <div style={{ maxHeight: "200px", overflow: "auto", padding: 5 }}>
              <TwoDFormList
                timelinePointForm={timelinePointForm}
                setTwoDFormError={setTwoDFormError}
                setErrorMessage={setErrorMessage}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div>
                <span>3D Data</span>
                {threeDFormError && <Badge dot></Badge>}
              </div>
            }
            key="2"
          >
            <div style={{ maxHeight: "200px", overflow: "auto", padding: 5 }}>
              <ThreeDFormList
                timelinePointForm={timelinePointForm}
                setThreeDFormError={setThreeDFormError}
                setErrorMessage={setErrorMessage}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div>
                <span>3D Scan</span>
                {scanDataFormError && <Badge dot></Badge>}
              </div>
            }
            key="3"
          >
            <div style={{ maxHeight: "200px", overflow: "auto", padding: 5 }}>
              <ScanDataFormList
                timelinePointForm={timelinePointForm}
                setScanDataFormError={setScanDataFormError}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div>
                <span>Timelapse</span>
                {timelapseFormError && <Badge dot></Badge>}
              </div>
            }
            key="4"
          >
            <div style={{ maxHeight: "200px", overflow: "auto", padding: 5 }}>
              <TimelapseFormList
                timelinePointForm={timelinePointForm}
                setTimelapseFormError={setTimelapseFormError}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
        <div style={{ marginTop: 10 }}>
          <Form.Item>
            <Button
              htmlType="submit"
              id="save-layers"
              className="login-button"
              style={{ width: "100%" }}
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export { NewTimelinePoint };
