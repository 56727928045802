import { firestore } from "../configurations/firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  getDoc,
  setDoc,
  doc,
  documentId,
} from "firebase/firestore";

const projectsRef = collection(firestore, "projects");

const findProjectByName = async (name) => {
  let foundProject;
  const q = query(
    projectsRef,
    where("name", "==", String(name).trim().toUpperCase())
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundProject = {
      id: doc.id,
      ...doc.data(),
    };
  });
  return foundProject;
};

const getProjectDocByName = async (name) => {
  let foundProject;
  const q = query(
    projectsRef,
    where("name", "==", String(name).trim().toUpperCase())
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundProject = doc;
  });
  return foundProject;
};

const createProject = async ({ name, description, type }) => {
  return addDoc(projectsRef, {
    name: String(name).trim().toUpperCase(),
    description,
    type,
  });
};

const getProjectById = async (id) => {
  let foundProject;
  const docRef = doc(firestore, "projects", String(id).trim());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    foundProject = {
      organisationId: docSnap.id,
      ...docSnap.data(),
    };
  }
  return foundProject;
};

const getProjectTypes = async () => {
  const foundProjectTypes = [];
  const q = query(collection(firestore, "project_types"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundProjectTypes.push({
      value: doc.id,
      label: doc.data().typeName,
    });
  });
  return foundProjectTypes;
};

const createProjectType = async (type) => {
  return setDoc(doc(firestore, "project_types", type), {
    typeName: type,
  });
};

const createOrganisationProject = async ({ organisationId, projectId }) => {
  return addDoc(collection(firestore, "organisation_project"), {
    organisationId,
    projectId,
  });
};

const getOrganisationProjectByOrganisationId = async (organisationId) => {
  const foundProjects = [];
  const q = query(
    collection(firestore, "organisation_project"),
    where("organisationId", "==", organisationId)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundProjects.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return foundProjects;
};

const getOrganisationProjectByProjectId = async (projectId) => {
  const foundProjects = [];
  const q = query(
    collection(firestore, "organisation_project"),
    where("projectId", "==", projectId)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundProjects.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return foundProjects;
};

const getOrganisationProjectByIds = async (organisationId, projectId) => {
  const foundProjects = [];
  const q = query(
    collection(firestore, "organisation_project"),
    where("organisationId", "==", organisationId),
    where("projectId", "==", projectId)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundProjects.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return foundProjects;
};

const getProjectsByIdArray = async (projectIdsArray) => {
  const foundProjects = [];
  const spliceableIds = new Array(...projectIdsArray);
  while (spliceableIds.length) {
    const splicedIds = spliceableIds.splice(0, 10);
    const q = query(projectsRef, where(documentId(), "in", splicedIds));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      foundProjects.push({
        id: doc.id,
        ...doc.data(),
        value: doc.id,
        label: doc.data().name,
      });
    });
  }
  return foundProjects;
};

const getOrganisationProjects = async (organisationId) => {
  const associatedProjects = await getOrganisationProjectByOrganisationId(
    organisationId
  );
  const projectIdsArray = associatedProjects.map(
    (orgProj) => orgProj.projectId
  );
  return await getProjectsByIdArray(projectIdsArray);
};

const getOrganisationSingleProject = async (organisationId, projectId) => {
  const associatedProjects = await getOrganisationProjectByIds(
    organisationId,
    projectId
  );
  const projectIdsArray = associatedProjects.map(
    (orgProj) => orgProj.projectId
  );
  return await getProjectsByIdArray(projectIdsArray);
};

export {
  findProjectByName,
  getProjectDocByName,
  createProject,
  getProjectById,
  getProjectTypes,
  createProjectType,
  createOrganisationProject,
  getOrganisationProjectByOrganisationId,
  getOrganisationProjectByProjectId,
  getProjectsByIdArray,
  getOrganisationProjects,
  getOrganisationSingleProject,
};
