import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getApprovedDeliverables } from "../queries/deliverables";
import { getOrganisationById } from "../queries/organisations";
import { getOrganisationProjects } from "../queries/projects";
import {
  getProjectTimelinePoints,
  getScanDatasByPointIdsArray,
  getSpatialDataByPointIdsArray,
} from "../queries/timelinePoints";
import { getUserAccessibleProjects } from "../queries/userProjectAccess";
import {
  getUsersByOrganisationId,
  updateUserAccountStatusByEmail,
} from "../queries/users";
import { AuthContext } from "./AuthContext";
import { getAllProjectTimelapseLinks } from "../queries/timelapseLinks";

const SingleOrganisationContext = createContext();

const SingleOrganisationProvider = ({ children }) => {
  const { userInfo } = useContext(AuthContext);
  const [organisationId, setOrganisationId] = useState();
  const [timelinePoints, setTimelinePoints] = useState();
  const [timelapseData, setTimelapseData] = useState([]);

  const {
    mutate: fetchOrganisationProjects,
    data: projects,
    isLoading: isProjectsLoading,
  } = useMutation((organisationId) => {
    if (userInfo.role === "SENIOR") {
      return getOrganisationProjects(organisationId);
    } else {
      return getUserAccessibleProjects({ userId: userInfo.userId });
    }
  });

  const {
    mutate: fetchProject,
    isLoading: isProjectLoading,
    data: selectedProject,
  } = useMutation((projectId) => {
    if (projects) {
      // resetting timeline points after every project selection change
      // setTimelinePoints();
      setTimelapseData();
      const foundProject = projects.filter((proj) => proj.id === projectId);
      // find timelinePoints and add them to the project object
      return foundProject[0];
    }
  });

  const fetchTimelinePoints = async (deliverablesIdArray) => {
    const timelinePoints = await getProjectTimelinePoints(deliverablesIdArray);
    const timelinePointIdsArray = timelinePoints.map((point) => point.id);
    const spatialData = await getSpatialDataByPointIdsArray(
      timelinePointIdsArray
    );
    const scanData = await getScanDatasByPointIdsArray(timelinePointIdsArray);
    timelinePoints.forEach((timelinePoint) => {
      timelinePoint.twoDData = [];
      timelinePoint.threeDData = [];
      timelinePoint.scanData = [];
      if (spatialData && spatialData.length) {
        spatialData.forEach((sp) => {
          if (sp.timelinePointId === timelinePoint.id) {
            if (sp.sourceType === "KML" || sp.sourceType === "IMAGERY") {
              timelinePoint.twoDData.push(sp);
            }
            if (sp.sourceType === "TERRAIN" || sp.sourceType === "3DTILES") {
              timelinePoint.threeDData.push(sp);
            }
          }
        });
      }

      if (scanData && scanData.length) {
        scanData.forEach((sd) => {
          if (sd.timelinePointId === timelinePoint.id) {
            timelinePoint.scanData.push(sd);
          }
        });
      }
    });
    setTimelinePoints(timelinePoints);
  };

  const {
    mutate: fetchProjectDeliverables,
    isLoading: isProjectDeliverablesLoading,
    data: projectDeliverables,
    error: projectDeliverablesError,
  } = useMutation(async () => {
    if (selectedProject) {
      const deliverables = await getApprovedDeliverables(selectedProject.id);
      const deliverablesIdArray = [];
      deliverables.forEach((deliverable) => {
        deliverablesIdArray.push(deliverable.id);
      });
      fetchTimelinePoints(deliverablesIdArray);
      return deliverables;
    } else {
      throw new Error("cannot find selected project");
    }
  });

  const { mutate: fetchTimelapseLinks } = useMutation(async (projectId) => {
    const timelapseLinks = await getAllProjectTimelapseLinks({ projectId });
    if (timelapseLinks && timelapseLinks.length) {
      setTimelapseData(timelapseLinks);
    }
  });

  const fetchOrganisationMembers = async () => {
    let membersArray = [];
    if (organisationId) {
      const members = await getUsersByOrganisationId(organisationId);
      if (members && members.length) {
        membersArray = members.map((member) => ({
          key: member.userId,
          names: `${member.firstName} ${member.lastName}`,
          role: member.role,
          status: member.isSuspended,
          email: member.email,
          isProfileSet: member.isProfileSet,
          profilePhoto: member.profilePhoto,
          userId: member.userId,
          organisationId: member.organisationId,
        }));
      }
    }
    return membersArray;
  };

  const {
    isLoading: isMembersLoading,
    mutate: fetchMembers,
    error: fetchMembersError,
    data: members,
  } = useMutation(fetchOrganisationMembers);

  const changeAccountSuspendedStatus = async ({ userEmail, status }) => {
    if (userInfo.role === "SENIOR") {
      await updateUserAccountStatusByEmail({ email: userEmail, status });
      fetchMembers();
    }
  };

  const { isLoading: isSuspensionLoading, mutate: changeAccountStatus } =
    useMutation(changeAccountSuspendedStatus);

  const {
    mutate: fetchOrganisationData,
    isLoading: organisationLoading,
    data: organisationData,
  } = useMutation((organisationId) => {
    return getOrganisationById(organisationId);
  });

  useEffect(() => {
    if (userInfo && userInfo.organisationId) {
      setOrganisationId(userInfo.organisationId);
      fetchOrganisationProjects(userInfo.organisationId);
    }
  }, [fetchOrganisationProjects, userInfo]);

  useEffect(() => {
    if (selectedProject) {
      fetchProjectDeliverables();
      fetchTimelapseLinks(selectedProject.id);
    }
  }, [fetchProjectDeliverables, fetchTimelapseLinks, selectedProject]);

  useEffect(() => {
    if (organisationId) {
      fetchMembers();
      fetchOrganisationData(organisationId);
    }
  }, [organisationId]);

  const valuesToPassDown = {
    organisationId,
    fetchOrganisationProjects,
    projects,
    isProjectsLoading,
    fetchProject,
    isProjectLoading,
    selectedProject,
    fetchProjectDeliverables,
    isProjectDeliverablesLoading,
    projectDeliverables,
    projectDeliverablesError,
    fetchMembers,
    isMembersLoading,
    fetchMembersError,
    members,
    changeAccountStatus,
    isSuspensionLoading,
    timelinePoints,
    fetchOrganisationData,
    organisationData,
    organisationLoading,
    timelapseData,
    fetchTimelapseLinks,
  };

  return (
    <SingleOrganisationContext.Provider value={valuesToPassDown}>
      {organisationId && children}
    </SingleOrganisationContext.Provider>
  );
};

export { SingleOrganisationContext, SingleOrganisationProvider };
