import {
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  ColorMaterialProperty,
  defined,
  Color,
  HeightReference,
  GeometryInstance,
  GroundPolylineGeometry,
  ColorGeometryInstanceAttribute,
  GroundPolylinePrimitive,
  PolylineColorAppearance,
  Cartographic,
  Math as CesiumMath,
  sampleTerrainMostDetailed,
  Cartesian3,
  AxisAlignedBoundingBox,
  HeadingPitchRange,
  BoundingSphere,
} from "cesium";
import "cesium/Source/Widgets/widgets.css";
import "../volume/main.css";
import "../volume/loading.svg";
import { lineString, point, distance, along } from "@turf/turf";

class ProfileController {
  constructor({
    viewer,
    mainTerrainName,
    setChartData,
    setProfileController,
    secondaryTerrainProvider,
    secondaryTerrainName,
    setMeasurementReady,
    setDrawingGridPoints,
  }) {
    this.viewer = viewer;
    //secondTerrainProvider for profile comparison
    this.secondaryTerrainProvider = secondaryTerrainProvider;
    this.points = [];

    this.activeShapePoints = [];
    this.running = false;
    this.setChartData = setChartData;
    this.setProfileController = setProfileController;

    this.mainTerrainName = mainTerrainName;
    this.secondaryTerrainName = secondaryTerrainName;
    this.setMeasurementReady = setMeasurementReady;
    this.setDrawingGridPoints = setDrawingGridPoints;

    viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
      ScreenSpaceEventType.LEFT_CLICK
    );
  }

  activate() {
    this.setChartData();
    this.setMeasurementReady(false);
    this.handler = new ScreenSpaceEventHandler(this.viewer.canvas);
    this.handler.setInputAction(
      this._addPoint(this),
      ScreenSpaceEventType.LEFT_CLICK
    );
    this.handler.setInputAction(
      this._mouseMove(this),
      ScreenSpaceEventType.MOUSE_MOVE
    );
    this.handler.setInputAction(
      this._terminate(this),
      ScreenSpaceEventType.RIGHT_CLICK
    );
    // set eventHandler for the escape button
    this._addEscapeKeyListener();
  }

  destroy() {
    this.handler?.destroy();

    this.setMeasurementReady(false);
    this.viewer.entities.remove(this.floatingPoint);
    this.viewer.entities.remove(this.activeShape);
    // this.viewer.dataSources.removeAll();
    this.removeDrawings(this);
    this.setProfileController(undefined);
    this._removeEscapeKeyListener();
  }

  _handleEscapeKey = (event) => {
    if (event.key === "Escape" || event.keyCode === 27) {
      // Your code to handle the "Escape" key press goes here
      this.cancelShape();
    }
  };

  _addEscapeKeyListener() {
    document.addEventListener("keydown", this._handleEscapeKey);
  }

  _removeEscapeKeyListener() {
    document.removeEventListener("keydown", this._handleEscapeKey);
  }

  _drawShape(positionData, perPositionHeight, color) {
    return {
      polygon: {
        hierarchy: positionData,
        material: new ColorMaterialProperty(color),
        perPositionHeight: perPositionHeight,
      },
    };
  }

  _addPoint(self) {
    return (event) => {
      if (self.running) return;
      let earthPosition = self.viewer.scene.pickPosition(event.position);
      /* let cartographicPosition = Cartographic.fromCartesian(earthPosition);
              //height through sampleHeight is a little more precise
              cartographicPosition.height = self.viewer.scene.sampleHeight(cartographicPosition, [], 0.1);
              earthPosition = Cartesian3.fromDegrees(cartographicPosition.longitude * 57.295779513082320876798154814105, cartographicPosition.latitude * 57.295779513082320876798154814105, cartographicPosition.height); */

      if (defined(earthPosition)) {
        if (self.activeShapePoints.length === 0) {
          this.setMeasurementReady(false);
          self.floatingPoint = self._createPoint(earthPosition);
          self.activeShapePoints.push(earthPosition);
          // const dynamicPositions = new CallbackProperty(function () {
          //   return new PolygonHierarchy(self.activeShapePoints);
          // }, false);
          // self.activeShape = self._drawShape(
          //   dynamicPositions,
          //   true,
          //   Color.WHITE.withAlpha(0.7)
          // );
          // self.viewer.entities.add(self.activeShape);
        }
        self.activeShapePoints.push(earthPosition);
        self.points.push(self._createPoint(earthPosition));
        /* if (self.activeShapePoints.length === 5) {
                      self.terminateShape();
                  } */
      }
    };
  }
  _mouseMove(self) {
    return (event) => {
      if (defined(self.floatingPoint)) {
        const newPosition = self.viewer.scene.pickPosition(event.endPosition);
        if (defined(newPosition)) {
          self.floatingPoint.position.setValue(newPosition);
          self.activeShapePoints.pop();
          self.activeShapePoints.push(newPosition);
        }
      }
    };
  }

  cancelShape() {
    this.viewer.entities.remove(this.floatingPoint);
    this.viewer.entities.remove(this.activeShape);
    this.points.forEach((p) => {
      this.viewer.entities.remove(p);
    });
    this.points = [];
    this.activeShapePoints.pop();

    this.floatingPoint = undefined;
    this.activeShape = undefined;

    this.activeShapePoints = [];
  }

  removeDrawings(self) {
    // this.viewer.dataSources.removeAll();
    self.viewer.entities.removeAll();
    const primitives = self.viewer.scene.groundPrimitives._primitives.filter(
      (primi) => primi.constructor.name === "GroundPolylinePrimitive"
    );
    primitives.forEach((primi) => {
      self.viewer.scene.groundPrimitives.remove(primi);
    });
  }

  _terminate(self) {
    return (event) => {
      if (self.running) return;
      if (self.activeShapePoints.length >= 3) {
        self.terminateShape();
      }
    };
  }

  terminateShape() {
    this.viewer.entities.remove(this.floatingPoint);
    this.viewer.entities.remove(this.activeShape);
    this.points.forEach((p) => {
      this.viewer.entities.remove(p);
    });
    this.points = [];
    this.activeShapePoints.pop();

    this.floatingPoint = undefined;
    this.activeShape = undefined;

    // this.viewer.dataSources.removeAll();
    this.removeDrawings(this);

    this.calculateIntervalPoints(this.activeShapePoints, this.viewer, 0.1);
    this.activeShapePoints = [];
  }

  calculateIntervalPoints(activeShapePoints, viewer, sampleSize) {
    const options = { units: "meters" };
    let totalLineDistance = 0;
    const cartographicActiveShapePoints = [];
    const distanceIndices = [];
    const heightIndices = [];
    const secondaryHeightIndices = [];
    const distanceIndicesCoordinates = [];
    const secondaryDistanceIndicesCoordinates = [];

    // loop through picked points to get distance for each interval

    for (let i = 0; i < activeShapePoints.length - 1; i++) {
      const carto1 = Cartographic.fromCartesian(activeShapePoints[i]);
      const carto2 = Cartographic.fromCartesian(activeShapePoints[i + 1]);
      const from = point([
        CesiumMath.toDegrees(carto1.longitude),
        CesiumMath.toDegrees(carto1.latitude),
      ]);
      const to = point([
        CesiumMath.toDegrees(carto2.longitude),
        CesiumMath.toDegrees(carto2.latitude),
      ]);
      const currentDistance = distance(from, to, options);
      totalLineDistance += currentDistance;
      cartographicActiveShapePoints.push(carto1);
      cartographicActiveShapePoints.push(carto2);
    }

    if (totalLineDistance > 0) {
      const line = lineString(
        cartographicActiveShapePoints.map((carto) => [
          CesiumMath.toDegrees(carto.longitude),
          CesiumMath.toDegrees(carto.latitude),
        ])
      );
      for (
        let currentIntervalDistance = 0;
        currentIntervalDistance <= totalLineDistance;
        currentIntervalDistance += sampleSize
      ) {
        const alongCoordinate = along(line, currentIntervalDistance, options);
        distanceIndicesCoordinates.push(
          Cartographic.fromDegrees(
            alongCoordinate.geometry.coordinates[0],
            alongCoordinate.geometry.coordinates[1]
          )
        );
        if (this.secondaryTerrainProvider) {
          secondaryDistanceIndicesCoordinates.push(
            Cartographic.fromDegrees(
              alongCoordinate.geometry.coordinates[0],
              alongCoordinate.geometry.coordinates[1]
            )
          );
        }
        distanceIndices.push(currentIntervalDistance);
      }

      sampleTerrainMostDetailed(
        viewer.terrainProvider,
        distanceIndicesCoordinates
      ).then((results) => {
        const drawingPoints = [];
        const lineCartesianPoints = results.map((res) => {
          heightIndices.push(res.height);
          drawingPoints.push({
            longitude: CesiumMath.toDegrees(res.longitude),
            latitude: CesiumMath.toDegrees(res.latitude),
            height: res.height,
          });
          return Cartesian3.fromDegrees(
            CesiumMath.toDegrees(res.longitude),
            CesiumMath.toDegrees(res.latitude),
            res.height
          );
        });

        if (this.secondaryTerrainProvider) {
          sampleTerrainMostDetailed(
            this.secondaryTerrainProvider,
            secondaryDistanceIndicesCoordinates
          ).then((secondResults) => {
            secondResults.map((res) => {
              secondaryHeightIndices.push(res.height);
              return Cartesian3.fromDegrees(
                CesiumMath.toDegrees(res.longitude),
                CesiumMath.toDegrees(res.latitude),
                res.height
              );
            });
            this.setChartData({
              distanceIndices,
              heightIndices,
              secondaryHeightIndices,
            });
          });
        } else {
          this.setChartData({
            distanceIndices,
            heightIndices,
          });
        }

        this.setMeasurementReady(true);
        this.setDrawingGridPoints(drawingPoints);
        const geometryInstance = new GeometryInstance({
          geometry: new GroundPolylineGeometry({
            positions: lineCartesianPoints,
            width: 4.0,
          }),
          attributes: {
            color: ColorGeometryInstanceAttribute.fromColor(Color.ORANGE),
          },
        });
        const groundPolyline = new GroundPolylinePrimitive({
          geometryInstances: geometryInstance,
          appearance: new PolylineColorAppearance(),
        });
        viewer.scene.groundPrimitives.add(groundPolyline);

        var boundingBox =
          AxisAlignedBoundingBox.fromPoints(lineCartesianPoints);
        var boundingSphere = BoundingSphere.fromCornerPoints(
          boundingBox.minimum,
          boundingBox.maximum
        );
        viewer.camera.flyToBoundingSphere(boundingSphere, {
          offset: new HeadingPitchRange(
            Math.PI / 2,
            -Math.PI / 3,
            boundingSphere.radius * 14
          ),
        });
      });
    }
  }

  _createPoint(worldPosition) {
    const point = this.viewer.entities.add({
      position: worldPosition,
      point: {
        color: Color.WHITE,
        pixelSize: 5,
        heightReference: HeightReference.NONE,
      },
    });
    return point;
  }
}
export { ProfileController };
