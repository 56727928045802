import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const Login = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.Login };
  })
);

const ResetPassword = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.ResetPassword };
  })
);

const SetProfile = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.SetProfile };
  })
);

const AuthRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/login"
        element={
          <Suspense>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/resetpassword"
        element={
          <Suspense>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route
        path="/setprofile/:email/:token"
        element={
          <Suspense>
            <SetProfile />
          </Suspense>
        }
      />
    </Routes>
  );
};

export { AuthRoutes };
