import { firestore } from "../configurations/firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  doc,
  writeBatch,
} from "firebase/firestore";

const getAllProjectTimelapseLinks = async ({ projectId }) => {
  let foundTimelapseLinks = [];
  const q = query(
    collection(firestore, "timelapse_links"),
    where("projectId", "==", String(projectId).trim())
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (!doc.data().isDeleted) {
      foundTimelapseLinks.push({
        id: doc.id,
        ...doc.data(),
        ref: doc.ref,
      });
    }
  });
  return foundTimelapseLinks;
};

const createTimelapseLink = async ({ name, link, projectId }) => {
  return addDoc(collection(firestore, "timelapse_links"), {
    name: String(name).trim(),
    projectId,
    link,
  });
};

const updateTimelapseLink = async ({
  timelapseDataArray,
  deletedTimelapseDataArray,
  projectId,
}) => {
  const batch = writeBatch(firestore);
  if (deletedTimelapseDataArray && deletedTimelapseDataArray.length) {
    deletedTimelapseDataArray.forEach((timelapseData) => {
      batch.delete(timelapseData.ref);
    });
  }
  if (timelapseDataArray && timelapseDataArray.length) {
    timelapseDataArray.forEach((timelapseData) => {
      if (timelapseData.id) {
        batch.update(doc(firestore, "timelapse_links", timelapseData.id), {
          link: timelapseData.link,
          name: timelapseData.name,
        });
      } else {
        const newTimelapseRef = doc(collection(firestore, "timelapse_links"));
        batch.set(newTimelapseRef, {
          link: timelapseData.link,
          name: timelapseData.name,
          projectId,
        });
      }
    });
  }
  return batch.commit();
};

export {
  getAllProjectTimelapseLinks,
  createTimelapseLink,
  updateTimelapseLink,
};
