import { firestore } from "../configurations/firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  addDoc,
  getDoc,
  doc,
  documentId,
} from "firebase/firestore";

const organisationsRef = collection(firestore, "organisations");

const findOrganisationByName = async (name) => {
  let foundOrganisation;
  const q = query(
    organisationsRef,
    where("name", "==", String(name).trim().toUpperCase())
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundOrganisation = {
      id: doc.id,
      ...doc.data(),
    };
  });
  return foundOrganisation;
};

const getOrganisationDocByName = async (name) => {
  let foundOrganisation;
  const q = query(
    organisationsRef,
    where("name", "==", String(name).trim().toUpperCase())
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundOrganisation = doc;
  });
  return foundOrganisation;
};

const updateProfileByName = async (name, profilePicture) => {
  const organisationDoc = await getOrganisationDocByName(
    String(name).trim().toUpperCase()
  );
  return profilePicture
    ? updateDoc(organisationDoc.ref, {
        name: String(name).trim().toUpperCase(),
        profilePicture,
      })
    : updateDoc(organisationDoc.ref, {
        name: String(name).trim().toUpperCase(),
      });
};

const updateOrganisationProfile = async ({ organisationId, ...rest }) => {
  const organisationDoc = doc(
    firestore,
    "organisations",
    String(organisationId).trim()
  );
  return updateDoc(organisationDoc, {
    ...rest,
  });
};

const createOrganisationByName = async (name) => {
  return addDoc(organisationsRef, {
    name: String(name).trim().toUpperCase(),
    isSuspended: false,
  });
};

const getAllOrganisations = async () => {
  let foundOrganisations = [];
  const q = query(organisationsRef);
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    foundOrganisations.push({
      id: doc.id,
      value: doc.id,
      label: doc.data().name,
      ...doc.data(),
    });
  });
  foundOrganisations = foundOrganisations.sort((org1, org2) => {
    if (org1.label > org2.label) {
      return 1;
    }
    return -1;
  });
  return foundOrganisations;
};

const getOrganisationById = async (id) => {
  let foundOrganisation;
  const docRef = doc(firestore, "organisations", String(id).trim());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    foundOrganisation = {
      id: docSnap.id,
      ...docSnap.data(),
    };
  }
  return foundOrganisation;
};

const getOrganisationsByIdArray = async (organisationIdsArray) => {
  const foundOrganisations = [];
  const spliceableIds = new Array(...organisationIdsArray);
  while (spliceableIds.length) {
    const splicedIds = spliceableIds.splice(0, 10);
    const q = query(
      collection(firestore, "organisations"),
      where(documentId(), "in", splicedIds)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      foundOrganisations.push({
        id: doc.id,
        ...doc.data(),
      });
    });
  }
  return foundOrganisations;
};

export {
  findOrganisationByName,
  updateProfileByName,
  getOrganisationDocByName,
  createOrganisationByName,
  getAllOrganisations,
  getOrganisationById,
  getOrganisationsByIdArray,
  updateOrganisationProfile,
};
