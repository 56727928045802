import { useContext, useEffect, useState } from "react";
import { Col, Menu, Modal, Row } from "antd";
import {
  FileAddOutlined,
  FolderAddOutlined,
  AimOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { AuthContext, OrganisationsContext } from "../../contexts";
import { deleteObject, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../configurations/firebaseConfig";
import { AddProgressBar } from "../loading";
import { NewTimelinePoint } from "../forms";

const AddMenu = ({ deliverable }) => {
  const { deliverablesData, fetchProjectDeliverables } =
    useContext(OrganisationsContext);
  const { userInfo } = useContext(AuthContext);
  const { projectId, contentsPath, deliverableId } = useParams();

  const [showProgressModal, setShowProgressModal] = useState(false);
  const handleCloseProgress = () => setShowProgressModal(false);
  const handleShowProgress = () => setShowProgressModal(true);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [files, setFiles] = useState();
  const [filesStatus, setFilesStatus] = useState([]);
  const [currentlyUploadingFileName, setCurrentlyUploadingFileName] =
    useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [completionCount, setCompletionCount] = useState(0);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [uploadTask, setUploadTask] = useState();
  const [uploadCancelled, setUploadCancelled] = useState(false);

  const cancelDeliverableUpload = async (newDeliverable, filesToBeUploaded) => {
    setCancelLoading(true);
    try {
      await Promise.all(
        filesToBeUploaded.map((file) => {
          let fileRelativePath = file.webkitRelativePath.substring(
            file.webkitRelativePath.indexOf("/") + 1,
            file.webkitRelativePath.length
          );
          const firebaseStoragePath = `/deliverables/${projectId}/${newDeliverable.id}/${fileRelativePath}`;
          return deleteObject(ref(storage, `${firebaseStoragePath}`));
        })
      );
    } catch (error) { }
    setCancelLoading(false);
    setCurrentlyUploadingFileName("Upload canceled.");
    setUploadPercentage(0);
  };

  const uploadFiles = async (filesToUpload) => {
    try {
      // calculating total size to be uploaded
      let totalBytesToUpload = 0;
      for (let i = 0; i < filesToUpload.length; i++) {
        totalBytesToUpload += filesToUpload[i].size;
      }
      const foundDeliverable = deliverablesData.filter(
        (deliverable) => deliverable.id === deliverableId
      );

      if (foundDeliverable && foundDeliverable.length) {
        // uploading files one at a time
        for (let i = 0; i < filesToUpload.length; i++) {
          const updatedFiles = filesStatus.map((file, index) => {
            if (index === i) {
              file.uploadStatus = "uploading";
            }
            return file;
          });

          setFilesStatus(updatedFiles);

          let newContentsPath = "";
          contentsPath
            .split(">")
            .filter((el, i) => i > 0 && el)
            .forEach((directory) => {
              newContentsPath += "/" + directory;
            });

          let fileRelativePath = filesToUpload[i].webkitRelativePath
            ? newContentsPath + "/" + filesToUpload[i].webkitRelativePath
            : newContentsPath + "/" + filesToUpload[i].name;

          let firebaseStoragePath = `/deliverables/${projectId}/${foundDeliverable[0].id}/${fileRelativePath}`;

          setCurrentlyUploadingFileName(`Uploading ${fileRelativePath}`);
          const fileReference = new ref(storage, `${firebaseStoragePath}`);
          const uploadTask = new uploadBytesResumable(
            fileReference,
            filesToUpload[i]
          );
          setUploadTask(uploadTask);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              switch (snapshot.state) {
                case "paused":
                  // handle pause
                  break;
                case "running":
                  let uploadedBytes = snapshot.bytesTransferred;
                  for (let ind = 0; ind < i; ind++) {
                    uploadedBytes += filesToUpload[ind].size;
                  }
                  setUploadPercentage(
                    ((uploadedBytes / totalBytesToUpload) * 100).toFixed(2)
                  );
                  break;
                case "canceled":
                  // handle canceled
                  break;
                default:
                // default
              }
            },
            async (error) => {
              // Handle unsuccessful uploads
              const updatedFiles = filesStatus.map((file, index) => {
                if (index === i) {
                  file.uploadStatus = "failed";
                }
                return file;
              });

              setFilesStatus(updatedFiles);

              setCurrentlyUploadingFileName("Error uploding files! Canceling...");
              cancelDeliverableUpload(foundDeliverable[0], filesStatus);

              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case "storage/unauthorized":
                  // User doesn't have permission to access the object
                  break;
                case "storage/canceled":
                  // User canceled the upload
                  break;

                // ...

                case "storage/unknown":
                  // Unknown error occurred, inspect error.serverResponse
                  break;

                default:
                // handle default
              }
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              if (i === filesToUpload.length - 1) {
                setUploadPercentage(100);
                fetchProjectDeliverables(projectId);
              }
              const updatedFiles = filesStatus.map((file, index) => {
                if (index === i) {
                  file.uploadStatus = "uploaded";
                  file.storageRef = uploadTask.snapshot.ref;
                }
                return file;
              });

              setFilesStatus(updatedFiles);
            }
          );
          await uploadTask;
          setCompletionCount(i + 1);
        }
      }
    } catch (error) {
      console.log('Error adding files');
    }
  };

  useEffect(() => {
    if (files && files.length) {
      handleShowProgress();
      uploadFiles(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentsPath, files]);

  return (
    <div>
      <Menu
        items={[
          {
            label: (
              <div className="add-dropdown-container">
                <div
                  className="upload-hover"
                  onClick={() => {
                    document.getElementById("fileUploadBtn")?.click();
                  }}
                >
                  <Row style={{ padding: 5 }}>
                    <Col span={6}>
                      <FileAddOutlined className="file-icon-hover" />
                    </Col>
                    <Col span={18} style={{ textAlign: "start" }}>
                      File
                    </Col>
                  </Row>
                </div>
                <div
                  className="upload-hover"
                  onClick={() => {
                    document.getElementById("folderUploadBtn")?.click();
                  }}
                >
                  <Row style={{ padding: 5 }}>
                    <Col span={6}>
                      <FolderAddOutlined className="folder-icon-hover" />
                    </Col>
                    <Col span={18} style={{ textAlign: "left" }}>
                      Folder
                    </Col>
                  </Row>
                </div>
                {userInfo &&
                  userInfo.role &&
                  userInfo.role === "DATA-PROCESSOR" && (
                    <div
                      className="upload-hover"
                      onClick={() => {
                        handleShow();
                      }}
                    >
                      <Row style={{ padding: 5 }}>
                        <Col span={6}>
                          <AimOutlined className="timeline-icon-hover" />
                        </Col>
                        <Col span={18} style={{ textAlign: "left" }}>
                          Timeline Point
                        </Col>
                      </Row>
                    </div>
                  )}
              </div>
            ),
          },
        ]}
      />
      <input
        type="file"
        id="fileUploadBtn"
        onClick={(e) => {
          e.target.value = "";
        }}
        onChange={(e) => {
          if (e.target.files && e.target.files.length) {
            setFiles(e.target.files);
            setFilesStatus([...e.target.files]);
          }
        }}
        style={{ display: "none" }}
        multiple
      />
      <input
        type="file"
        id="folderUploadBtn"
        onClick={(e) => {
          e.target.value = "";
        }}
        onChange={(e) => {
          if (e.target.files && e.target.files.length) {
            setFiles(e.target.files);
            setFilesStatus([...e.target.files]);
          }
        }}
        style={{ display: "none" }}
        multiple
        directory=""
        webkitdirectory="true"
        moxdirectory=""
      />

      <Modal
        centered
        style={{ borderRadius: 40 }}
        visible={showProgressModal}
        footer={false}
        className="progress-bar-modal"
        onCancel={handleCloseProgress}
        maskClosable={false}
        closable={false}
      >
        <AddProgressBar
          uploadPercentage={uploadPercentage}
          completionCount={completionCount}
          currentlyUploadingFileName={currentlyUploadingFileName}
          files={filesStatus}
          cancelLoading={cancelLoading}
          selfClose={handleCloseProgress}
          uploadTask={uploadTask}
          uploadCancelled={uploadCancelled}
          setUploadCancelled={setUploadCancelled}
          key="addMenuKey"
        />
      </Modal>
      <Modal
        title="New Timeline Point"
        className="new-timeline-point-modal"
        open={showModal}
        footer={false}
        onCancel={handleClose}
        maskClosable={false}
      >
        <NewTimelinePoint deliverable={deliverable} />
      </Modal>
    </div>
  );
};

export { AddMenu };
