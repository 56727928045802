import { useContext, useEffect, useState } from "react";
import { Card, Col, Popover, Row, Image } from "antd";
import { AuthContext } from "../../contexts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MoreOutlined } from "@ant-design/icons";
import { DeliverableMenu } from "../menu";
import folderIcon from "../../images/folder-icon.png";
import building from "../../images/building.png";

const { Meta } = Card;

const DeliverableCard = ({ deliverable }) => {
  const navigate = useNavigate();
  const { userInfo } = useContext(AuthContext);
  let { organisationId, projectId } = useParams();
  const { pathname } = useLocation();
  const [organisation, setOrganisation] = useState();

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    if (
      deliverable &&
      deliverable.organisations &&
      deliverable.organisations.length
    ) {
      setOrganisation(deliverable.organisations[0]);
    }
  }, [deliverable]);

  return (
    <Card
      onDoubleClick={() => {
        if (!projectId) {
          if (deliverable.project && deliverable.project.id) {
            projectId = deliverable.project.id;
          }
        }
        if (!organisationId) {
          if (
            deliverable.organisations &&
            deliverable.organisations[0] &&
            deliverable.organisations[0].id
          ) {
            organisationId = deliverable.organisations[0].id;
          }
        }
        if (userInfo.role === "DATA-PROCESSOR") {
          navigate(
            `/dataprocessorDashboard/organisations/${organisationId}/projects/${projectId}/deliverables/${deliverable.id}/${deliverable.name}`
          );
        } else if (userInfo.role === "SUPERVISOR") {
          navigate(
            `/supervisorDashboard/organisations/${organisationId}/projects/${projectId}/deliverables/${deliverable.id}/${deliverable.name}`
          );
        } else if (
          userInfo.role === "SENIOR" ||
          userInfo.role === "SUBORDINATE"
        ) {
          navigate(`${pathname}/${deliverable.id}/${deliverable.name}`);
        }
      }}
      style={{
        width: 130,
      }}
      cover={
        <Image
          preview={false}
          src={folderIcon}
          style={{ height: 100, objectFit: "cover" }}
        />
      }
    >
      <Row>
        <Col
          span={22}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Meta
            title={<span title={deliverable.name}>{deliverable.name}</span>}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          />
        </Col>

        <Col
          span={2}
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
          id="deliverables-step"
        >
          <Popover
            placement="bottom"
            content={
              <div
                onClick={() => {
                  hide();
                }}
              >
                <DeliverableMenu
                  storagePath={`deliverables/${projectId}/${deliverable.id}`}
                  menuLevel="deliverable"
                  selectedDeliverable={deliverable}
                  itemName={deliverable.name}
                />
              </div>
            }
            trigger="click"
            onOpenChange={handleOpenChange}
            open={open}
          >
            <MoreOutlined className="option-button" />
          </Popover>
        </Col>
        {pathname &&
          pathname.endsWith("pendingDeliverables") &&
          organisation &&
          organisation.name && (
            <Col span={24} style={{ marginTop: 5 }}>
              <Meta
                avatar={
                  <Image
                    src={building}
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 100,
                      objectFit: "contain",
                      marginRight: -15,
                      marginLeft: -3,
                    }}
                  />
                }
                title={
                  <span style={{ color: "#BABABA" }} title={organisation.name}>
                    {organisation.name}
                  </span>
                }
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "end",
                  fontSize: "10px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              />
            </Col>
          )}
      </Row>
    </Card>
  );
};

export { DeliverableCard };
