const LoadingLayout = () => {
  return (
    <div className="loading-container">
      <div className="loading-content">
        <lottie-player
          src="https://firebasestorage.googleapis.com/v0/b/intel-construction.appspot.com/o/gifs%2FReal%20Loading.json?alt=media&token=5ab27720-395c-41be-bd5b-2f68662cdd14"
          background="transparent"
          speed="1.2"
          autoplay
        ></lottie-player>
      </div>
    </div>
  );
};

export { LoadingLayout };
