import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Image } from "antd";
import { useLocation } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import testImage from "../images/platform-login.png";
import { Steps } from "intro.js-react";

const commonStepsMap = new Map([
  [
    "welcomePage",
    {
      intro: (
        <div style={{ textAlign: "center" }}>
          <h4>Let's begin our Charis Analytics journey!</h4>,
          <Image
            src={testImage}
            preview={false}
            height={150}
            width={300}
            style={{ objectFit: "cover" }}
          />
        </div>
      ),
      title: "Welcome!",
      tooltipClass: "cssClassName1",
    },
  ],
  [
    "support",
    {
      intro: (
        <span style={{ fontSize: 14 }}>Send us your feedback and reviews.</span>
      ),
      element: "#third-step",
      title: "Support Page",
      position: "right",
    },
  ],
  [
    "profile",
    {
      intro: (
        <span style={{ fontSize: 14 }}>View and Update your profile info</span>
      ),
      title: "Profile Setup",
      element: "#fifth-step",
    },
  ],
  [
    "organisationProfile",
    {
      intro: (
        <span style={{ fontSize: 14 }}>
          View and Update your organisation profile info
        </span>
      ),
      title: "Organization Profile Setup",
      element: "#fourth-step",
    },
  ],
]);

const supervisorSeniorStepsMap = new Map([
  [
    "accounts",
    {
      intro: (
        <span style={{ fontSize: 14 }}>
          View and Manage Organisation Accounts
        </span>
      ),
      element: "#second-step",
      title: "Accounts",
      position: "right",
    },
  ],
]);

const seniorSubordinateStepsMaps = new Map([
  [
    "projects",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>Quick access to your projects</p>
        </div>
      ),
      element: "#first-step",
      title: "Projects",
      position: "right",
    },
  ],
]);
const seniorDeliverablesStepsMaps = new Map([
  [
    "navigations",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>
            While going through your project's deliverable files and folders,
            quickly navigate back by clicking on the path link
          </p>
        </div>
      ),
      element: "#navigation-step",
      title: "Navigation",
    },
  ],
  [
    "timelinesNavigation",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>
            View project timeline in 2D, 3D or 3D scan timelines
          </p>
        </div>
      ),
      element: "#view-timeline-step",
      title: "View Timelines",
    },
  ],
  [
    "deliverablesMenus",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>
            You can view more options such as downloading
          </p>
        </div>
      ),
      element: "#deliverables-step",
      title: "Deliverables Menu",
    },
  ],
  [
    "detailsMenus",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>
            Check the project's informations and details
          </p>
        </div>
      ),
      element: "#details-menu-step",
      title: "Project Details",
    },
  ],
]);

const timelineStepsMaps = new Map([
  [
    "measurementsThreeD",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>Have access to all 3D measuring tools</p>
        </div>
      ),
      element: "#threeD-measurements-steps",
      title: "Measurements",
    },
  ],
  [
    "measurementsTwoD",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>Have access to all 2D measuring tools</p>
        </div>
      ),
      element: "#threeD-measurements-steps",
      title: "Measurements",
    },
  ],
  [
    "layers",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>
            View layers, saved measurements and comments under the current
            timeline point and navigate to other timelines
          </p>
        </div>
      ),
      element: "#layer-steps",
      title: "Layers Group",
    },
  ],
  [
    "timelines",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>
            View project changes over time by clicking on a timeline point
          </p>
        </div>
      ),
      element: "#timeline-steps",
      title: "Timeline",
    },
  ],
]);

const seniorAccountsMaps = new Map([
  [
    "searchUsers",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>Search users by name or email</p>
        </div>
      ),
      element: "#account-search-step",
      title: "Search Users",
    },
  ],
  [
    "accountCreation",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>
            Add new seniors or surbodinates accounts to your organisation
          </p>
        </div>
      ),
      element: "#account-creation-step",
      title: "New Accounts",
    },
  ],
  [
    "accountAction",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>You can suspend or activate accounts</p>
        </div>
      ),
      element: ".account-switch",
      title: "Account Actions",
    },
  ],
  [
    "accountAssigment",
    {
      intro: (
        <div>
          <p style={{ fontSize: 14 }}>
            You can assign projects to Subordinates under the{" "}
            <span style={{ fontWeight: "bold" }}>Assigned Projects</span> column
          </p>
        </div>
      ),
      element: ".assign-account-button",
      title: "View and Assign Projects",
    },
  ],
]);

const seniorDashboardSteps = [
  commonStepsMap.get("welcomePage"),
  seniorSubordinateStepsMaps.get("projects"),
  supervisorSeniorStepsMap.get("accounts"),
  commonStepsMap.get("support"),
  commonStepsMap.get("organisationProfile"),
  commonStepsMap.get("profile"),
];

const subordinateDashboardSteps = [
  commonStepsMap.get("welcomePage"),
  seniorSubordinateStepsMaps.get("projects"),
  commonStepsMap.get("support"),
  commonStepsMap.get("profile"),
];

const organisationDeliverablesSteps = [
  seniorDeliverablesStepsMaps.get("navigations"),
  seniorDeliverablesStepsMaps.get("timelinesNavigation"),
  seniorDeliverablesStepsMaps.get("deliverablesMenus"),
  // seniorDeliverablesStepsMaps.get("detailsMenus"),
];

const timelineThreeDSteps = [
  timelineStepsMaps.get("measurementsThreeD"),
  timelineStepsMaps.get("layers"),
  timelineStepsMaps.get("timelines"),
];

const timelineTwoDSteps = [
  timelineStepsMaps.get("measurementsTwoD"),
  timelineStepsMaps.get("layers"),
  timelineStepsMaps.get("timelines"),
];
const timelineScanSteps = [
  timelineStepsMaps.get("layers"),
  timelineStepsMaps.get("timelines"),
];
const accountSteps = [
  seniorAccountsMaps.get("searchUsers"),
  seniorAccountsMaps.get("accountCreation"),
  seniorAccountsMaps.get("accountAction"),
  seniorAccountsMaps.get("accountAssigment"),
];
const othersDashboardSteps = [
  commonStepsMap.get("welcomePage"),
  commonStepsMap.get("support"),
  commonStepsMap.get("profile"),
];

const JoyContext = createContext();

const JoyProvider = ({ children }) => {
  const { userInfo } = useContext(AuthContext);
  const { pathname } = useLocation();
  const [steps, setSteps] = useState([]);
  const [initialStep, setInitialStep] = useState(0);
  const [enabled, setEnabled] = useState(true);
  const [runningGuide, setRunningGuide] = useState("");

  const onExit = useCallback(() => {
    if (runningGuide) {
      const onBoardingPages = localStorage.getItem("onBoadingPages")
        ? JSON.parse(localStorage.getItem("onBoadingPages"))
        : {};

      onBoardingPages[runningGuide] = true;

      localStorage.setItem("onBoadingPages", JSON.stringify(onBoardingPages));
      setRunningGuide("");
    }
    setEnabled(false);
  }, [runningGuide]);
  useEffect(() => {
    if (userInfo && userInfo.role) {
      const onBoardingPages = JSON.parse(
        localStorage.getItem("onBoadingPages")
      );
      if (
        pathname.endsWith("seniorDashboard") &&
        (!onBoardingPages || !onBoardingPages.dashboardIntroduced)
      ) {
        // setSteps(seniorDashboardSteps);
        // setEnabled(true);
        // setRunningGuide("dashboardIntroduced");
      } else if (
        pathname.endsWith("subordinateDashboard") &&
        (!onBoardingPages || !onBoardingPages.dashboardIntroduced)
      ) {
        // setSteps(subordinateDashboardSteps);
        // setEnabled(true);
        // setRunningGuide("dashboardIntroduced");
      } else if (
        pathname.endsWith("deliverables") &&
        (!onBoardingPages || !onBoardingPages.deliverablesIntroduced)
      ) {
        // setSteps(organisationDeliverablesSteps);
        // setEnabled(true);
        // setRunningGuide("deliverablesIntroduced");
      } else if (
        pathname.endsWith("accounts") &&
        (!onBoardingPages || !onBoardingPages.accountsIntroduced)
      ) {
        // setSteps(accountSteps);
        // setEnabled(true);
        // setRunningGuide("accountsIntroduced");
      } else if (
        pathname.endsWith("3D") &&
        (!onBoardingPages || !onBoardingPages.timelines3DIntroduced)
      ) {
        // setSteps(timelineThreeDSteps);
        // setEnabled(true);
        // setRunningGuide("timelines3DIntroduced");
      } else if (
        pathname.endsWith("2D") &&
        (!onBoardingPages || !onBoardingPages.timelines2DIntroduced)
      ) {
        // setSteps(timelineTwoDSteps);
        // setEnabled(true);
        // setRunningGuide("timelines2DIntroduced");
      } else if (
        pathname.endsWith("3DScan") &&
        (!onBoardingPages || !onBoardingPages.timelineScanIntroduced)
      ) {
        // setSteps(timelineScanSteps);
        // setEnabled(true);
        // setRunningGuide("timelinesScanIntroduced");
      }
    }
  }, [userInfo, pathname]);

  const valuesToPassDown = {
    steps,
    setSteps,
    setEnabled,
    setRunningGuide,
    runningGuide,
    organisationDeliverablesSteps,
    seniorDashboardSteps,
    accountSteps,
    timelineThreeDSteps,
    timelineTwoDSteps,
    timelineScanSteps,
    subordinateDashboardSteps,
  };
  return (
    <JoyContext.Provider value={valuesToPassDown}>
      <Steps
        enabled={enabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          exitOnOverlayClick: false,
          hidePrev: true,
        }}
      />
      {children}
    </JoyContext.Provider>
  );
};

export { JoyProvider, JoyContext };
