import {
  Image,
  Button,
  Row,
  Col,
  Modal,
  Form,
  Input,
  Alert,
  Spin,
  Space,
  Upload,
  Cascader,
  Dropdown,
  Menu,
} from "antd";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { VolumeController } from "../../helpers/volume/VolumeController";
import { ProfileController } from "../../helpers/profile/ProfilerController";
import { CrossSectionCompare, VolumeCompare } from "../forms";
import "./measurements.css";
import {
  Cartesian3,
  Cartographic,
  CesiumTerrainProvider,
  Color,
  IonResource,
  Math as CesiumMath,
  IonImageryProvider,
  SplitDirection,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
} from "cesium";
import { UploadOutlined, SaveOutlined, DownOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import axios, { CancelToken } from "axios";
import Papa from "papaparse";
import { createMeasurement } from "../../queries/measurements";
import { AuthContext } from "../../contexts";
import { ProfileChart } from "./ProfileChart";
import { LayerComparisonForm } from "../forms/LayerComparisonForm";
import { TimelineMenu } from "../menu";

const MeasurementType = {
  STOCKPILE: "STOCKPILE",
  VOLUME_COMPARISON: "VOLUME_COMPARISON",
  SINGLE_PROFILE: "SINGLE_PROFILE",
  PROFILE_COMPARISON: "PROFILE_COMPARISON",
};

const TwoDMeasurementOption = {
  SAVE: "SAVE",
  SAVE_AND_DOWNLOAD: "SAVE_AND_DOWNLOAD",
  DOWNLOAD: "DOWNLOAD",
};

const Measurements = ({
  viewer,
  activeTerrain,
  setActiveTerrain,
  threeDPoints,
  twoDPoints,
  layerComparisonActive,
  setLayerComparisonActive,
  setLayerNames,
  removePointDataFromMap,
  add3DDataToMap,
  add2DDataToMap,
  timelinePoint,
  fetchMeasurements,
  fetchTwoDMeasurements,
  removeComment,
  volumeController,
  setVolumeController,
  setProfileController,
  stockpileActive,
  setStockpileActive,
  volumeComparisonActive,
  setVolumeComparisonActive,
  singleProfileActive,
  setSingleProfileActive,
  doubleProfileActive,
  setDoubleProfileActive,
  deactivateMeasurement,
  resetMapToPointData,
  currentTimelinePointIndex,
  twoDMeasurementReady,
  mapRef,
  saveTwoDMeasurement,
  isTwoDMeasurementSavingLoading,
  isTwoDMeasurementSuccess,
  twoDMeasurementError,
}) => {
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const [twoDMeasurementForm] = Form.useForm();
  const [csvForm] = Form.useForm();
  const { userInfo, authorizationToken, apiAuthorizationError } =
    useContext(AuthContext);

  const [netVolume, setNetVolume] = useState(0);
  const [cutVolume, setCutVolume] = useState(0);
  const [fillVolume, setFillVolume] = useState(0);
  const [volumeProgress, setVolumeProgress] = useState(0);
  const [chartData, setChartData] = useState();
  const [secondaryTerrainId, setSecondaryTerrainId] = useState();
  const [secondTerrainName, setSecondTerrainName] = useState("");
  const [measurementReady, setMeasurementReady] = useState(false);
  const [measurementSaved, setMeasurementSaved] = useState(false);
  const [drawingGridPoints, setDrawingGridPoints] = useState();
  const [measurementTerrainData, setMeasurementTerrainData] = useState();
  const [cancelTokenSource, setCancelTokenSource] = useState();
  const [columnLabelOptions, setColumnLabelOptions] = useState([]);
  const [selectedCsvFile, setSelectedCsvFile] = useState();
  const [twoDLayerComparisonData, setTwoDLayerComparisonData] = useState([]);

  // volume comparison
  const [showCompareModal, setShowCompareModal] = useState(false);
  const handleCompareClose = () => setShowCompareModal(false);
  const handleCompareShow = () => setShowCompareModal(true);

  // cross-section profile difference
  const [showCrossSectionModal, setShowCrossSectionModal] = useState(false);
  const handleCrossSectionClose = () => setShowCrossSectionModal(false);
  const handleCrossSectionShow = () => setShowCrossSectionModal(true);

  // save measurements popup
  const [showSaveMeasurementModal, setShowMeasurementModal] = useState(false);

  const [showUploadModal, setshowUploadModal] = useState(false);
  const handleUploadClose = () => setshowUploadModal(false);
  const handleUploadShow = () => setshowUploadModal(true);

  // layer comparison tool
  const [showLayerComparisonModal, setshowLayerComparisonModal] =
    useState(false);
  const handleLayerComparisonClose = () => setshowLayerComparisonModal(false);
  const handleLayerComparisonShow = () => setshowLayerComparisonModal(true);

  const {
    error,
    isLoading,
    mutate: saveMeasurement,
    isSuccess,
    reset,
  } = useMutation(async ({ ...measurementData }) => {
    if (
      userInfo &&
      userInfo.role &&
      (userInfo.role === "SENIOR" || userInfo.role === "SUBORDINATE")
    ) {
      await createMeasurement(measurementData);
      fetchMeasurements(timelinePoint.id);
    }
    form.resetFields();
    setMeasurementReady(false);
    setMeasurementSaved(true);
  });

  const {
    isLoading: measurementLoading,
    mutate: requestMeasurementCalculation,
    isError: isMeasurementRequestError,
    error: measurementError,
  } = useMutation(
    async ({
      cartesian3Coordinates,
      topTerrainId,
      baseTerrainId,
      cleanUpFunction,
      self,
      csvFile,
      latitudeColumnName,
      longitudeColumnName,
    }) => {
      document.getElementById("progress").style.display = "block";
      try {
        let requestBody;
        if (baseTerrainId && csvFile) {
          requestBody = new FormData();
          requestBody.append("csvFile", csvFile[0]);
          requestBody.append("baseTerrainId", baseTerrainId);
          requestBody.append("topTerrainId", topTerrainId);
          requestBody.append("stepSize", 0.3);
          requestBody.append("latitudeColumnName", latitudeColumnName);
          requestBody.append("longitudeColumnName", longitudeColumnName);
        } else if (!baseTerrainId && csvFile) {
          requestBody = new FormData();
          requestBody.append("csvFile", csvFile[0]);
          requestBody.append("topTerrainId", topTerrainId);
          requestBody.append("stepSize", 0.3);
          requestBody.append("latitudeColumnName", latitudeColumnName);
          requestBody.append("longitudeColumnName", longitudeColumnName);
        } else if (baseTerrainId && !csvFile) {
          requestBody = {
            cartesian3Coordinates,
            topTerrainId,
            baseTerrainId,
            stepSize: 0.3,
            latitudeColumnName,
            longitudeColumnName,
          };
        } else {
          requestBody = {
            cartesian3Coordinates,
            topTerrainId,
            stepSize: 0.3,
            latitudeColumnName,
            longitudeColumnName,
          };
        }

        const requestUrl =
          baseTerrainId && !csvFile
            ? `https://${process.env.REACT_APP_API_URL}/api/measurements/volume/volumeComparison`
            : baseTerrainId && csvFile
              ? `https://${process.env.REACT_APP_API_URL}/api/measurements/volume/volumeComparison/csv/decimal-degrees`
              : csvFile && !baseTerrainId
                ? `https://${process.env.REACT_APP_API_URL}/api/measurements/volume/stockpile/csv/decimal-degrees`
                : `https://${process.env.REACT_APP_API_URL}/api/measurements/volume/stockpile`;
        const source = CancelToken.source();
        setCancelTokenSource(source);

        const response = await axios.post(requestUrl, requestBody, {
          headers: {
            authorization: authorizationToken,
            "Content-Type": csvFile
              ? "multipart/form-data"
              : "application/json",
          },
          timeout: 780000,
          maxContentLength: 100000000,
          maxBodyLength: 1000000000,
          cancelToken: source.token,
        });
        if (response && response.data && response.data.data) {
          const {
            cut,
            volume,
            fill,
            cartesian3Coordinates: drawingCartesian3Coordinates,
          } = response.data.data;
          const drawingPositions = drawingCartesian3Coordinates.map((pstn) => {
            const element = Cartographic.fromCartesian(pstn);
            return Cartesian3.fromDegrees(
              CesiumMath.toDegrees(element.longitude),
              CesiumMath.toDegrees(element.latitude),
              element.height
            );
          });
          viewer.entities.add({
            polygon: {
              hierarchy: {
                positions: drawingPositions,
              },
              material: Color.BLUE.withAlpha(0.5),
            },
          });
          // setting drawing points to used when saving the measurement
          setDrawingGridPoints(drawingCartesian3Coordinates);
          setVolumeProgress(0);
          setNetVolume(new Intl.NumberFormat().format(volume));
          setCutVolume(new Intl.NumberFormat().format(cut));
          setFillVolume(new Intl.NumberFormat().format(fill));
          setMeasurementReady(true);
          cleanUpFunction && cleanUpFunction(self);
        }
      } catch (error) {
        if (error.message !== "Request canceled by user.") {
          cleanUpFunction && cleanUpFunction(self);
          throw error.message;
        }
        cleanUpFunction && cleanUpFunction(self);
        throw error;
      }
      // cleaning the csvForm
      setColumnLabelOptions([]);
      csvForm.resetFields(["longitudeColumnName", "latitudeColumnName"]);
      document.getElementById("progress").style.display = "none";
    }
  );

  const handleSaveMeasurementClose = () => {
    setMeasurementSaved(false);
    setShowMeasurementModal(false);
  };
  const handleSaveMeasurementShow = () => {
    form.resetFields();
    reset();
    setShowMeasurementModal(true);
  };

  const handleSaveTwoDMeasurement = async (data) => {
    const { measurementName, preferredAction } = data;
    if (
      twoDPoints[currentTimelinePointIndex].twoDData.length &&
      twoDPoints[currentTimelinePointIndex].twoDData[0].sourceName
    ) {
      await saveTwoDMeasurement({
        currentMap: mapRef.current,
        measurementName,
        orthophotoName:
          twoDPoints[currentTimelinePointIndex].twoDData[0].sourceName,
        deliverableId: timelinePoint.deliverableId,
        timelinePointId: timelinePoint.id,
        preferredAction,
      });
      twoDMeasurementForm.resetFields();
    }
  };

  const activateMeasurement = ({
    topTerrainData,
    measurementType,
    viewer,
    baseTerrainData,
  }) => {
    removeComment && removeComment();
    deactivateMeasurement && deactivateMeasurement();
    setChartData();
    // passed a type of measurement
    if (measurementType === MeasurementType.STOCKPILE) {
      if (!stockpileActive) {
        resetMapToPointData();
        const volumeController = new VolumeController({
          tileset: null,
          viewer,
          setVolumeProgress,
          authorizationToken,
          topTerrainId: topTerrainData.sourceId,
          baseTerrainId: null,
          setNetVolume,
          setCutVolume,
          setFillVolume,
          setMeasurementReady,
          setDrawingGridPoints,
          requestMeasurementCalculation,
        });
        setVolumeController(volumeController);
        volumeController.activate();
        setStockpileActive(true);
      } else {
        resetMapToPointData();
      }
    }
    if (measurementType === MeasurementType.VOLUME_COMPARISON) {
      if (!volumeComparisonActive) {
        const newVolumeController = new VolumeController({
          tileset: null,
          viewer,
          setVolumeProgress,
          authorizationToken,
          topTerrainId: topTerrainData.sourceId,
          baseTerrainId: baseTerrainData.sourceId,
          setNetVolume,
          setCutVolume,
          setFillVolume,
          setMeasurementReady,
          setDrawingGridPoints,
          requestMeasurementCalculation,
        });
        setVolumeController(newVolumeController);
        newVolumeController.activate();
        setVolumeComparisonActive(true);
      } else {
        resetMapToPointData();
      }
    }
    if (measurementType === MeasurementType.SINGLE_PROFILE) {
      if (!singleProfileActive) {
        resetMapToPointData();
        const newProfileController = new ProfileController({
          viewer,
          mainTerrainName: activeTerrain ? activeTerrain : null,
          setChartData,
          setProfileController,
          secondaryTerrainProvider: null,
          secondaryTerrainName: null,
          setMeasurementReady,
          setDrawingGridPoints,
        });
        setProfileController(newProfileController);
        newProfileController.activate();
        setSingleProfileActive(true);
      } else {
        resetMapToPointData();
        viewer.scene.groundPrimitives.removeAll();
      }
    }
    if (measurementType === MeasurementType.PROFILE_COMPARISON) {
      if (!doubleProfileActive) {
        const newProfileController = new ProfileController({
          viewer,
          mainTerrainName: activeTerrain,
          setChartData,
          setProfileController,
          secondaryTerrainProvider: new CesiumTerrainProvider({
            url: IonResource.fromAssetId(baseTerrainData.sourceId),
          }),
          secondaryTerrainName: baseTerrainData.sourceName,
          setMeasurementReady,
          setDrawingGridPoints,
        });
        setSecondTerrainName(baseTerrainData.sourceName);
        setProfileController(newProfileController);
        newProfileController.activate();
        setDoubleProfileActive(true);
      } else {
        resetMapToPointData();
      }
    }
  };

  const addComparisonLayers = ({ baseTerrainData, topTerrainData }) => {
    // removing existing layers from the main view
    removePointDataFromMap();
    // adding top layers
    add3DDataToMap({ threeDData: [topTerrainData], viewer });
    if (topTerrainData.imagery) {
      add2DDataToMap({
        imageryOnly: true,
        twoDData: topTerrainData.imagery,
        viewer,
      });
    }
    setSecondaryTerrainId(baseTerrainData.sourceId);
    return baseTerrainData.sourceId;
  };

  const initializeLayerComparison = ({ leftLayer, rightLayer }) => {
    removePointDataFromMap();
    handleLayerComparisonClose();
    const {
      sourceId: leftSideId,
      sourceName: leftSideSourceName,
      date: leftSideDate,
    } = leftLayer;
    const {
      sourceId: rightSideId,
      sourceName: rightSideSourceName,
      date: rightSideDate,
    } = rightLayer;

    setLayerNames({
      leftSideSourceName,
      leftSideDate,
      rightSideSourceName,
      rightSideDate,
    });

    const ImageryProvider1 = new IonImageryProvider({
      assetId: leftSideId,
    });
    const ImageryProvider2 = new IonImageryProvider({
      assetId: rightSideId,
    });

    const leftSideImagery =
      viewer.imageryLayers.addImageryProvider(ImageryProvider1);
    const rightSideImagery =
      viewer.imageryLayers.addImageryProvider(ImageryProvider2);

    setTwoDLayerComparisonData([leftSideImagery, rightSideImagery]);

    viewer.flyTo(rightSideImagery);

    rightSideImagery.splitDirection = SplitDirection.RIGHT; // Only show to the left of the slider.
    // layers.add(rightSideImagery);

    leftSideImagery.splitDirection = SplitDirection.LEFT; // Only show to the left of the slider.
    // layers.add(leftSideImagery);

    // Sync the position of the slider with the split position
    const slider = document.getElementById("slider");
    slider.style.display = "flex";
    viewer.scene.splitPosition =
      slider.offsetLeft / slider.parentElement.offsetWidth;

    const handler = new ScreenSpaceEventHandler(slider);

    let moveActive = false;

    function move(movement) {
      if (!moveActive) {
        return;
      }

      const relativeOffset = movement.endPosition.x;
      const splitPosition =
        (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
      slider.style.left = `${100.0 * splitPosition}%`;
      viewer.scene.splitPosition = splitPosition;
    }

    handler.setInputAction(function () {
      moveActive = true;
    }, ScreenSpaceEventType.LEFT_DOWN);
    handler.setInputAction(function () {
      moveActive = true;
    }, ScreenSpaceEventType.PINCH_START);

    handler.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
    handler.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);

    handler.setInputAction(function () {
      moveActive = false;
    }, ScreenSpaceEventType.LEFT_UP);
    handler.setInputAction(function () {
      moveActive = false;
    }, ScreenSpaceEventType.PINCH_END);
  };

  const initialVolumeComparison = ({ baseTerrainData, topTerrainData }) => {
    let {
      imagery: baseTerrainImagery,
      sourceId: baseTerrainSourceId,
      sourceType: baseTerrainSourceType,
      sourceName: baseTerrainSourceName,
    } = baseTerrainData;
    if (baseTerrainImagery && baseTerrainImagery.length) {
      baseTerrainImagery = baseTerrainImagery.map((imagery) => ({
        sourceId: imagery.sourceId,
        sourceType: imagery.sourceType,
        sourceName: imagery.sourceName,
      }));
    }

    let {
      imagery: topTerrainImagery,
      sourceId: topTerrainSourceId,
      sourceType: topTerrainSourceType,
      sourceName: topTerrainSourceName,
    } = topTerrainData;
    if (topTerrainImagery && topTerrainImagery.length) {
      topTerrainImagery = topTerrainImagery.map((imagery) => ({
        sourceId: imagery.sourceId,
        sourceType: imagery.sourceType,
        sourceName: imagery.sourceName,
      }));
    }

    setMeasurementTerrainData({
      topTerrainData: {
        imagery: topTerrainImagery,
        sourceId: topTerrainSourceId,
        sourceType: topTerrainSourceType,
        sourceName: topTerrainSourceName,
      },
      baseTerrainData: {
        imagery: baseTerrainImagery,
        sourceId: baseTerrainSourceId,
        sourceType: baseTerrainSourceType,
        sourceName: baseTerrainSourceName,
      },
    });

    addComparisonLayers({
      baseTerrainData,
      topTerrainData,
    });
    activateMeasurement({
      baseTerrainData,
      topTerrainData,
      measurementType: MeasurementType.VOLUME_COMPARISON,
      viewer,
      secondaryTerrain: baseTerrainData,
    });
    handleCompareClose();
  };

  const initiateProfileComparison = ({ baseTerrainData, topTerrainData }) => {
    let {
      imagery: baseTerrainImagery,
      sourceId: baseTerrainSourceId,
      sourceType: baseTerrainSourceType,
      sourceName: baseTerrainSourceName,
    } = baseTerrainData;
    if (baseTerrainImagery && baseTerrainImagery.length) {
      baseTerrainImagery = baseTerrainImagery.map((imagery) => ({
        sourceId: imagery.sourceId,
        sourceType: imagery.sourceType,
        sourceName: imagery.sourceName,
      }));
    }

    let {
      imagery: topTerrainImagery,
      sourceId: topTerrainSourceId,
      sourceType: topTerrainSourceType,
      sourceName: topTerrainSourceName,
    } = topTerrainData;
    if (topTerrainImagery && topTerrainImagery.length) {
      topTerrainImagery = topTerrainImagery.map((imagery) => ({
        sourceId: imagery.sourceId,
        sourceType: imagery.sourceType,
        sourceName: imagery.sourceName,
      }));
    }

    setMeasurementTerrainData({
      topTerrainData: {
        imagery: topTerrainImagery,
        sourceId: topTerrainSourceId,
        sourceType: topTerrainSourceType,
        sourceName: topTerrainSourceName,
      },
      baseTerrainData: {
        imagery: baseTerrainImagery,
        sourceId: baseTerrainSourceId,
        sourceType: baseTerrainSourceType,
        sourceName: baseTerrainSourceName,
      },
    });

    addComparisonLayers({
      baseTerrainData,
      topTerrainData,
    });
    activateMeasurement({
      baseTerrainData,
      topTerrainData,
      measurementType: MeasurementType.PROFILE_COMPARISON,
      viewer,
      secondaryTerrain: baseTerrainData,
    });
    setActiveTerrain(topTerrainData.sourceName);
    handleCrossSectionClose();
  };

  const handleCSVData = (result) => {
    // The parsed CSV data is available in the 'result' variable
    if (result.data.length) {
      if (Object.keys(result.data[0]).length) {
        setColumnLabelOptions(
          Object.keys(result.data[0]).map((label) => {
            return { label: label, value: label };
          })
        );
      }
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setSelectedCsvFile(e.target.files);
    Papa.parse(file, {
      complete: handleCSVData,
      header: true, // Set this to true if your CSV file has a header row
    });
  };

  return (
    <div>
      <div style={{ position: "absolute" }}>
        <div className="buttons-threeD" id="threeD-measurements-steps">
          {pathname && pathname.endsWith("2D") && (
            <Button
              className="volume-measuring-button"
              title="Layer Comparison Tool"
              style={{
                backgroundColor: layerComparisonActive ? "#48b" : "#e2e2e2",
              }}
              onClick={() => {
                if (!layerComparisonActive) {
                  if (twoDPoints[currentTimelinePointIndex].twoDData.length) {
                    handleLayerComparisonShow();
                  }
                } else {
                  const slider = document.getElementById("slider");
                  slider.style.display = "none";
                  setLayerComparisonActive(false);
                  viewer.imageryLayers.remove(twoDLayerComparisonData[0]);
                  viewer.imageryLayers.remove(twoDLayerComparisonData[1]);
                  resetMapToPointData();
                }
              }}
            >
              <Image
                preview={false}
                className="twoD-layer-comparoson-icon"
                src="https://cdn-icons-png.flaticon.com/256/56/56843.png"
                sty
                alt=""
              />
            </Button>
          )}
          {pathname && pathname.endsWith("3D") && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                className="volume-measuring-button"
                title="Stockpile Volume Measurement"
                style={{
                  backgroundColor: stockpileActive ? "#48b" : "#e2e2e2",
                }}
                onClick={() => {
                  if (
                    threeDPoints[currentTimelinePointIndex].threeDData.length
                  ) {
                    activateMeasurement({
                      measurementType: MeasurementType.STOCKPILE,
                      viewer,
                      topTerrainData:
                        threeDPoints[currentTimelinePointIndex].threeDData[0],
                    });
                  }
                }}
              >
                <Image
                  preview={false}
                  className="twoD-layer-comparoson-icon"
                  src="https://static.thenounproject.com/png/4353-200.png"
                  alt=""
                />
              </Button>
              <Button
                className="volume-compare-button"
                title="Volume Comparison"
                style={{
                  backgroundColor: volumeComparisonActive ? "#48b" : "#e2e2e2",
                }}
                onClick={() => {
                  if (!volumeComparisonActive) {
                    handleCompareShow();
                  } else {
                    // this has to be changed in future iterations
                    // there will be no need to call activateMeasurement in order to deactivate measurements
                    activateMeasurement({
                      measurementType: MeasurementType.VOLUME_COMPARISON,
                      viewer,
                      secondaryTerrainId,
                    });
                  }
                }}
              >
                <Image
                  preview={false}
                  className="twoD-layer-comparoson-icon"
                  src="https://static.thenounproject.com/png/303256-200.png"
                  alt=""
                />
              </Button>
              <Button
                className="single-cross-section-button"
                title="Single Terrain Cross-Section Profile"
                style={{
                  backgroundColor: singleProfileActive ? "#48b" : "#e2e2e2",
                }}
                onClick={() => {
                  activateMeasurement({
                    measurementType: MeasurementType.SINGLE_PROFILE,
                    viewer,
                  });
                }}
              >
                <Image
                  preview={false}
                  className="volume-measuring-button-icon"
                  src="https://static.thenounproject.com/png/1181534-200.png"
                  alt=""
                />
              </Button>
              <Button
                className="cross-section-button"
                title="Terrain Cross-Section Profile"
                style={{
                  backgroundColor: doubleProfileActive ? "#48b" : "#e2e2e2",
                }}
                onClick={() => {
                  if (!doubleProfileActive) {
                    handleCrossSectionShow();
                  } else {
                    // this has to be changed in future iterations
                    // there will be no need to call activateMeasurement in order to deactivate measurements
                    activateMeasurement({
                      measurementType: MeasurementType.PROFILE_COMPARISON,
                      viewer,
                      secondaryTerrainId,
                    });
                  }
                }}
              >
                <Image
                  preview={false}
                  className="volume-measuring-button-icon"
                  src="https://static.thenounproject.com/png/2129518-200.png"
                  alt=""
                />
              </Button>
            </div>
          )}
          <div id="measureContainer"></div>
        </div>
        {/* stockpileActive or volumeComparisonActive */}
        {(stockpileActive || volumeComparisonActive) && (
          <div className="volume-container">
            <div className="volume-container">
              <div style={{ margin: "2%" }}>
                {measurementReady && volumeComparisonActive && (
                  <div>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#242063",
                      }}
                    >
                      Net Volume = Fill Volume - Cut Volume
                    </span>
                    <hr />
                  </div>
                )}
                {!measurementReady &&
                  !measurementLoading &&
                  !isMeasurementRequestError &&
                  (volumeComparisonActive || stockpileActive) && (
                    <div>
                      <span
                        style={{
                          fontSize: 12,
                          color: "#242063",
                        }}
                      >
                        Click around the area of interest to place polygon
                        points, and right click when done. Or, choose the option
                        below to use predefined polygon points.
                      </span>
                      <p>
                        <span
                          style={{
                            fontSize: 12,
                            color: "#242063",
                          }}
                        >
                          Press the escape button to start over and place new
                          points.
                        </span>
                      </p>
                      <Button
                        onClick={() => {
                          handleUploadShow();
                        }}
                        type="primary"
                        className="login-button"
                        style={{ width: "100%", fontSize: 13 }}
                      >
                        Use CSV File Instead
                      </Button>
                    </div>
                  )}
                {isMeasurementRequestError && (
                  <div>
                    <span
                      style={{
                        fontSize: 13,
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {measurementError}
                    </span>
                  </div>
                )}
                {/* {apiAuthorizationError && (
                  <div>
                    <span
                      style={{
                        fontSize: 13,
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {apiAuthorizationError}
                    </span>
                  </div>
                )} */}
                {measurementReady && (
                  <div>
                    {volumeComparisonActive && (
                      <div>
                        <Row style={{ margin: 4 }}>
                          <Col span={2} style={{ borderRadius: 5 }}>
                            <div
                              style={{ borderRadius: "12px", height: 10 }}
                            ></div>
                          </Col>
                          <Col span={22}>
                            <div
                              style={{
                                fontSize: 12,
                                color: "#242063",
                              }}
                            >
                              Cut Volume: <span id="cutValue">{cutVolume}</span>{" "}
                              m³
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ margin: 4 }}>
                          <Col span={2} style={{ borderRadius: 5 }}>
                            <div
                              style={{ borderRadius: "12px", height: 10 }}
                            ></div>
                          </Col>
                          <Col span={22}>
                            <div
                              style={{
                                fontSize: 12,
                                color: "#242063",
                              }}
                            >
                              Fill Volume:{" "}
                              <span id="fillValue">{fillVolume}</span> m³
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                    <Row style={{ padding: 5 }}>
                      <Col span={2}>
                        <div style={{ height: 10 }}></div>
                      </Col>
                      <Col span={22}>
                        <div
                          style={{
                            fontSize: 14,
                            color: "#242063",
                            fontWeight: "bold",
                          }}
                        >
                          Net Volume: <span id="volumeValue">{netVolume}</span>{" "}
                          m³
                        </div>
                      </Col>
                    </Row>
                    <Button
                      className="login-button"
                      type="primary"
                      style={{
                        fontSize: 13,
                        padding: "5px 15px",
                        height: "auto",
                        width: "100%",
                      }}
                      onClick={() => {
                        handleUploadShow();
                      }}
                    >
                      Use CSV File Instead
                    </Button>
                  </div>
                )}

                {userInfo &&
                  userInfo.role &&
                  (userInfo.role === "SENIOR" ||
                    userInfo.role === "SUBORDINATE") &&
                  measurementReady && (
                    <Button
                      onClick={() => {
                        handleSaveMeasurementShow();
                      }}
                      className="login-button"
                      style={{ width: "100%", marginTop: 6, fontSize: 13 }}
                    >
                      Save Measurement
                    </Button>
                  )}

                <div id="progress" style={{ display: "none" }}>
                  <div
                    className="progress-bar stripes animated reverse slower"
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                    }}
                  >
                    <span
                      className="progress-bar-inner"
                      id="volume-progress"
                    ></span>
                    <Spin
                      tip={
                        <span style={{ color: "#242063" }}>
                          Calculating ...
                        </span>
                      }
                      spinning={measurementLoading}
                    />
                  </div>
                  <p></p>
                  <Button
                    type="primary"
                    // id="volume-cancel"
                    className="login-button"
                    style={{ width: "100%" }}
                    onClick={() => {
                      if (cancelTokenSource) {
                        cancelTokenSource.cancel("Request canceled by user.");
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <span
                    id="volume-cancel-registered"
                    style={{
                      color: "#242063",
                      display: "none",
                      fontWeight: "bold",
                    }}
                  >
                    cancelling...
                  </span>
                </div>
              </div>
            </div>
            <div className="cancel-volume-button"></div>
          </div>
        )}

        {(singleProfileActive || doubleProfileActive) && (
          <div className="volume-container">
            <div className="volume-container">
              <div style={{ margin: "5%" }}>
                {!measurementReady && (
                  <div>
                    <span
                      style={{
                        fontSize: 13,
                        color: "#242063",
                      }}
                    >
                      Click on the map to place your points, and right click
                      when done.
                    </span>
                    <p>
                      <span
                        style={{
                          fontSize: 13,
                          color: "#242063",
                        }}
                      >
                        Press the escape button to start over and place new
                        points.
                      </span>
                    </p>
                  </div>
                )}

                {userInfo &&
                  userInfo.role &&
                  (userInfo.role === "SENIOR" ||
                    userInfo.role === "SUBORDINATE") &&
                  measurementReady && (
                    <Button
                      onClick={() => {
                        handleSaveMeasurementShow();
                      }}
                      className="login-button"
                      style={{ width: "100%", fontSize: 13 }}
                    >
                      Save Measurement
                    </Button>
                  )}
              </div>
            </div>
          </div>
        )}

        {twoDMeasurementReady && (
          <div className="volume-container">
            <div className="volume-container">
              <div style={{ margin: "5%" }}>
                <Form
                  form={twoDMeasurementForm}
                  onFinish={handleSaveTwoDMeasurement}
                >
                  {isTwoDMeasurementSuccess && (
                    <p>
                      <Alert
                        type="success"
                        message="Measurement successfully saved"
                      />
                    </p>
                  )}
                  {twoDMeasurementError && (
                    <p>
                      <Alert type="error" message={twoDMeasurementError} />
                    </p>
                  )}
                  <Form.Item
                    name="measurementName"
                    rules={[
                      {
                        required: true,
                        message: "Please provide a measurement name",
                      },
                    ]}
                    style={{
                      marginBottom: 10,
                      width: "calc(100%)",
                    }}
                  >
                    <Input placeholder="Measurement Name" />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    name="preferredAction"
                  >
                    <Dropdown
                      trigger="click"
                      overlay={
                        <div>
                          <Menu
                            items={[
                              {
                                label: (
                                  <div className="timeline-menu-container">
                                    {/* <div>
                                      <Button
                                        type="text"
                                        className="timeline-button"
                                        onClick={() => {
                                          twoDMeasurementForm.setFieldValue(
                                            "preferredAction",
                                            TwoDMeasurementOption.SAVE
                                          );
                                          twoDMeasurementForm.submit();
                                        }}
                                      >
                                        Save to Measurements
                                      </Button>
                                    </div> */}
                                    <div>
                                      <Button
                                        type="text"
                                        className="timeline-button"
                                        onClick={() => {
                                          twoDMeasurementForm.setFieldsValue({
                                            preferredAction:
                                              TwoDMeasurementOption.DOWNLOAD,
                                          });
                                          twoDMeasurementForm.submit();
                                        }}
                                      >
                                        Download PDF
                                      </Button>
                                    </div>
                                    {userInfo &&
                                      userInfo.role &&
                                      (userInfo.role === "SENIOR" ||
                                        userInfo.role === "SUBORDINATE") && (
                                        <div>
                                          <Button
                                            type="text"
                                            className="timeline-button"
                                            onClick={() => {
                                              twoDMeasurementForm.setFieldsValue(
                                                {
                                                  preferredAction:
                                                    TwoDMeasurementOption.SAVE_AND_DOWNLOAD,
                                                }
                                              );
                                              twoDMeasurementForm.submit();
                                            }}
                                          >
                                            Save & Download PDF
                                          </Button>
                                        </div>
                                      )}
                                  </div>
                                ),
                              },
                            ]}
                          />
                        </div>
                      }
                    >
                      <Button
                        style={{ width: "100%" }}
                        id="view-timeline-step"
                        icon={<SaveOutlined />}
                        className="view-timeline-button"
                        loading={isTwoDMeasurementSavingLoading}
                      >
                        Save Measurement
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        )}

        <Modal
          title="Compare volume"
          open={showCompareModal}
          footer={false}
          onCancel={handleCompareClose}
          maskClosable={false}
        >
          <VolumeCompare
            threeDPoints={threeDPoints}
            initialVolumeComparison={initialVolumeComparison}
          />
        </Modal>
        <Modal
          title="CSV File"
          open={showUploadModal}
          footer={false}
          onCancel={handleUploadClose}
          maskClosable={false}
        >
          <Form
            form={csvForm}
            layout="vertical"
            onFinish={() => {
              const { latitudeColumnName, longitudeColumnName } =
                csvForm.getFieldsValue();
              requestMeasurementCalculation({
                csvFile: selectedCsvFile,
                latitudeColumnName: latitudeColumnName[0],
                longitudeColumnName: longitudeColumnName[0],
                topTerrainId: volumeController.topTerrainId,
                baseTerrainId: volumeController.baseTerrainId,
              });
              csvForm.resetFields();
              handleUploadClose();
              setMeasurementReady(false);
            }}
          >
            <Space
              direction="vertical"
              style={{
                width: "100%",
              }}
              size="large"
            >
              <Form.Item name="csvFile" onChange={handleFileInputChange}>
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  maxCount={1}
                  accept=".csv"
                  style={{ width: "100%" }}
                  onRemove={() => {
                    setColumnLabelOptions([]);
                    csvForm.resetFields([
                      "longitudeColumnName",
                      "latitudeColumnName",
                    ]);
                  }}
                >
                  <Button icon={<UploadOutlined />} style={{ width: "100%" }}>
                    Upload CSV file
                  </Button>
                </Upload>
              </Form.Item>
            </Space>
            <Row style={{ marginTop: 12 }}>
              <Col span={12}>
                {/* <label>Longitude Column</label> */}
                <Form.Item
                  label="Latitude Column Label"
                  name="longitudeColumnName"
                  rules={[
                    {
                      required: true,
                      message: "Please select a longitude column label",
                    },
                  ]}
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  <Cascader
                    placeholder="select Longitude Column label"
                    maxTagCount="responsive"
                    style={{ width: "100%" }}
                    options={columnLabelOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Longitude Column Label"
                  name="latitudeColumnName"
                  rules={[
                    {
                      required: true,
                      message: "Please select a latitude column label",
                    },
                  ]}
                  style={{ display: "inline-block", width: "calc(96%)" }}
                  wrapperCol={{ offset: 1 }}
                  labelCol={{ offset: 1 }}
                >
                  <Cascader
                    placeholder="select Latitude Column label"
                    maxTagCount="responsive"
                    style={{
                      width: "100%",
                    }}
                    options={columnLabelOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                htmlType="submit"
                type="primary"
                className="login-button"
                style={{ width: "100%" }}
                loading={measurementLoading}
              >
                Calculate
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Cross Section Layer Comparison"
          open={showCrossSectionModal}
          footer={false}
          onCancel={handleCrossSectionClose}
          maskClosable={false}
        >
          <CrossSectionCompare
            threeDPoints={threeDPoints}
            initiateProfileComparison={initiateProfileComparison}
          />
        </Modal>
        <Modal
          title="Layer Comparison"
          open={showLayerComparisonModal}
          footer={false}
          onCancel={handleLayerComparisonClose}
          maskClosable={false}
        >
          <LayerComparisonForm
            twoDPoints={twoDPoints}
            initializeLayerComparison={initializeLayerComparison}
            setLayerComparisonActive={setLayerComparisonActive}
          />
        </Modal>
        <Modal
          title="Save New Measurement"
          open={showSaveMeasurementModal}
          footer={false}
          onCancel={handleSaveMeasurementClose}
          maskClosable={false}
        >
          {error && <Alert type="error" message={error.message} />}
          {isSuccess && (
            <Alert type="success" message={"Measurement successfully saved"} />
          )}
          {!measurementSaved && (
            <Form
              form={form}
              onFinish={({ measurementName }) => {
                let measurementData;
                if (stockpileActive) {
                  measurementData = {
                    name: measurementName,
                    type: "STOCKPILE",
                    timelinePointId: timelinePoint.id,
                    drawingPoints: drawingGridPoints,
                    values: {
                      netVolume,
                    },
                    date: new Date(),
                    measurementTerrainData: {
                      topTerrainData: {
                        sourceName: activeTerrain,
                      },
                    },
                  };
                }
                if (volumeComparisonActive) {
                  measurementData = {
                    name: measurementName,
                    type: "VOLUME-COMPARISON",
                    timelinePointId: timelinePoint.id,
                    drawingPoints: drawingGridPoints,
                    values: {
                      netVolume,
                      cutVolume,
                      fillVolume,
                    },
                    measurementTerrainData,
                    date: new Date(),
                  };
                }
                if (singleProfileActive) {
                  measurementData = {
                    name: measurementName,
                    type: "SINGLE-PROFILE",
                    timelinePointId: timelinePoint.id,
                    drawingPoints: drawingGridPoints,
                    values: chartData,
                    date: new Date(),
                    measurementTerrainData: {
                      topTerrainData: {
                        sourceName: activeTerrain,
                      },
                    },
                  };
                }
                if (doubleProfileActive) {
                  measurementData = {
                    name: measurementName,
                    type: "DOUBLE-PROFILE",
                    timelinePointId: timelinePoint.id,
                    drawingPoints: drawingGridPoints,
                    values: chartData,
                    measurementTerrainData,
                    date: new Date(),
                  };
                }
                if (Object.keys(measurementData).length) {
                  saveMeasurement(measurementData);
                }
              }}
            >
              <Form.Item
                name="measurementName"
                rules={[
                  {
                    required: true,
                    message: "Please add a name",
                  },
                ]}
              >
                <Input placeholder="Measurement Name" />
              </Form.Item>
              <Form.Item>
                <Button
                  className="login-button"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          )}
        </Modal>
      </div>
      {chartData && (singleProfileActive || doubleProfileActive) && (
        <ProfileChart
          activeTerrain={activeTerrain}
          chartData={chartData}
          secondTerrainName={secondTerrainName}
          drawingPoints={drawingGridPoints}
          viewer={viewer}
        />
      )}
    </div>
  );
};

export { Measurements };
