import { useEffect, useRef, useState } from "react";
import { Card, Col, Image, Modal, Popover, Row } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { getDownloadURL } from "firebase/storage";
import { useLocation, useNavigate } from "react-router-dom";
import { DeliverableMenu } from "../menu";
import folder from "../../images/folder.png";
import noImage from "../../images/no-image.png";
import noVideo from "../../images/no-video.png";
import file from "../../images/file-icon.png";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

const { Meta } = Card;

const FileFolderCard = ({ content, deliverable, imagesArray }) => {
  const videoRef = useRef(null);
  const [playerRef, setPlayerRef] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [visible, setVisible] = useState(false);
  const [downloadURL, setDownloadURL] = useState("");
  const [showVideoModal, setShowVideoModal] = useState(false);
  const handleVideoClose = () => setShowVideoModal(false);
  const handleVideoShow = () => setShowVideoModal(true);
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const getFileLink = async (fileRef) => {
    try {
      const downloadURL = await getDownloadURL(fileRef);
      setDownloadURL(downloadURL);
    } catch (error) {}
  };

  useEffect(() => {
    if (
      content &&
      content.ref &&
      (content.format === "image" || content.format === "video")
    ) {
      getFileLink(content.ref);
    }
  }, [content]);

  useEffect(() => {
    let newPlayerRef;
    if (!playerRef && videoRef.current && showVideoModal) {
      newPlayerRef = new Plyr(videoRef.current);
      setPlayerRef(newPlayerRef);
    }
  }, [downloadURL, showVideoModal, videoRef, playerRef]);

  return (
    <div>
      <Card
        style={{
          width: 130,
        }}
        onDoubleClick={() => {
          if (content.type === "folder") {
            navigate(`${pathname}>${content.ref.name}`);
          }
        }}
        cover={
          content.type === "folder" ? (
            <Image
              preview={false}
              src={folder}
              style={{ height: 100, objectFit: "cover" }}
            />
          ) : content.type === "file" && content.format === "image" ? (
            <>
              <Image
                preview={{
                  visible: false,
                  maskClosable: false,
                }}
                src={downloadURL ? downloadURL : noImage}
                style={{ height: 100, objectFit: "fill" }}
                onClick={() => {
                  setVisible(true);
                }}
              />
              <div
                style={{
                  display: "none",
                }}
              >
                {(!imagesArray ||
                  (imagesArray && imagesArray.length === 0)) && (
                  <Image.PreviewGroup
                    preview={{
                      maskClosable: false,
                      visible,
                      onVisibleChange: (vis) => setVisible(vis),
                    }}
                  >
                    <Image src={downloadURL} />
                  </Image.PreviewGroup>
                )}
                {imagesArray && imagesArray.length > 0 && downloadURL && (
                  <Image.PreviewGroup
                    preview={{
                      maskClosable: false,
                      visible,
                      onVisibleChange: (vis) => setVisible(vis),
                      current: imagesArray.findIndex(
                        (url) => url === downloadURL
                      ),
                    }}
                  >
                    {imagesArray &&
                      imagesArray.length > 0 &&
                      imagesArray.map((imageUrl) => <Image src={imageUrl} />)}
                  </Image.PreviewGroup>
                )}
              </div>
            </>
          ) : content.type === "file" && content.format === "video" ? (
            <Image
              onClick={() => {
                handleVideoShow();
              }}
              preview={{
                visible,
              }}
              src={noVideo}
              style={{ height: 100, objectFit: "cover" }}
            />
          ) : (
            <Image
              preview={false}
              src={file}
              style={{ height: 100, objectFit: "cover" }}
            />
          )
        }
      >
        <Row>
          <Col
            span={22}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Meta
              className="file-name"
              title={<span title={content.ref.name}>{content.ref.name}</span>}
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            />
          </Col>
          <Col
            span={2}
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Popover
              placement="bottom"
              className="popover-menu"
              content={
                <div
                  onClick={() => {
                    hide();
                  }}
                >
                  <DeliverableMenu
                    menuLevel={content.type}
                    storagePath={content.ref.fullPath}
                    selectedDeliverable={deliverable}
                    itemName={content.ref.name}
                  />
                </div>
              }
              open={open}
              onOpenChange={handleOpenChange}
              trigger="click"
            >
              <MoreOutlined className="option-button" />
            </Popover>
          </Col>
        </Row>
      </Card>
      <Modal
        open={showVideoModal}
        footer={false}
        onCancel={() => {
          handleVideoClose();
          if (playerRef) {
            playerRef.stop();
          }
        }}
        maskClosable={false}
        width={800}
        centered
        className="videoPlayerContainer"
      >
        <div style={{ backgroundColor: "black", height: 450 }}>
          {downloadURL && (
            <video ref={videoRef} className="plyr__video-embed">
              <source src={downloadURL} />
            </video>
          )}
        </div>
      </Modal>
    </div>
  );
};

export { FileFolderCard };
