import { Button, Col, Menu, Progress, Row, Tag } from "antd";
import { CheckOutlined, SyncOutlined } from "@ant-design/icons";
import "./loading.css";
const { SubMenu } = Menu;

const ProgressBar = ({
  files,
  uploadPercentage,
  currentlyUploadingFileName,
  completionCount,
  cancelLoading,
  selfClose,
  uploadTask,
  uploadCancelled,
  setUploadCancelled,
}) => {
  return (
    <div className="progress-bar-container"
    >
      {uploadPercentage === 100 ? (
        <span>Done!</span>
      ) : (
        <span>{currentlyUploadingFileName}</span>
      )}
      <Progress percent={uploadPercentage} status="active" />
      <Row style={{ marginTop: 10 }}>
        <Col
          span={20}
          style={{
            textAlign: "start",
            display: "flex",
            alignItems: "end",
          }}
        >
          {!uploadCancelled && completionCount !== undefined && completionCount >= 0 && (
            <span>
              {completionCount} of {files.length} Complete{" "}
            </span>
          )}
        </Col>
        <Col span={4} style={{ textAlign: "end" }}>
          {uploadPercentage === 100 || uploadCancelled ? (
            <Button
              className="cancel-upload"
              style={{ width: "100%" }}
              onClick={selfClose}
            >
              Close
            </Button>
          ) : (
            <Button
              className="cancel-upload"
              style={{ width: "100%" }}
              loading={cancelLoading}
              onClick={() => {
                setUploadCancelled(true);
                uploadTask?.cancel();
                if (!completionCount) {
                  selfClose();
                }
              }}
            >
              Cancel
            </Button>
          )}
        </Col>
      </Row>
      {completionCount !== undefined && completionCount >= 0 && (
        <Col span={24}>
          <div className="moreInfo-arrow-icon" >
            <Menu mode="inline">
              <SubMenu key="sub1" title="More">
                <div className="uploaded-files-list">
                  {!uploadCancelled &&
                    files &&
                    files.length > 0 &&
                    files.map((file) => (
                      <Menu.Item
                        key={file.webkitRelativePath}
                        style={{ padding: 5 }}
                      >
                        <Row>
                          <Col
                            span={16}
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {file.webkitRelativePath.substring(
                              file.webkitRelativePath.indexOf("/") + 1,
                              file.webkitRelativePath.length
                            )}
                          </Col>
                          <Col span={6} style={{ textAlign: "center" }}>
                            <Tag
                              color={
                                file.uploadStatus === "uploading"
                                  ? "blue"
                                  : file.uploadStatus === "uploaded"
                                    ? "green"
                                    : file.uploadStatus === "failed"
                                      ? "red"
                                      : "grey"
                              }
                            >
                              {file.uploadStatus
                                ? file.uploadStatus
                                : `waiting`}
                            </Tag>
                          </Col>
                          {file.uploadStatus &&
                            file.uploadStatus === "uploaded" && (
                              <Col
                                span={2}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <CheckOutlined
                                  style={{
                                    fontSize: "15px",
                                  }}
                                />
                              </Col>
                            )}
                          {file.uploadStatus &&
                            file.uploadStatus === "failed" && (
                              <Col span={2}>
                                <SyncOutlined style={{ fontSize: "15px" }} />
                              </Col>
                            )}
                        </Row>
                      </Menu.Item>
                    ))}
                </div>
              </SubMenu>
            </Menu>

          </div>
        </Col>
      )}
    </div>
  );
};

export { ProgressBar };
