import { Divider } from "antd";

const ProjectInfo = ({ selectedProject }) => {
  return (
    <div className="details-container">
      {selectedProject && (
        <div>
          <p>Name: {selectedProject.name}</p>
          <p>Type: {selectedProject.type}</p>
          <span style={{ fontWeight: "bold" }}>Description</span>
          <Divider />
          <div className="description-box">
            <p>{selectedProject.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export { ProjectInfo };
