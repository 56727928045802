import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

const details = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(details);
const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);
const analytics = getAnalytics(firebaseApp);

const sendAccountSetupMail = httpsCallable(functions, "sendAccountSetupMail");
const sendNewDeliverableNotification = httpsCallable(
  functions,
  "sendNewDeliverableNotification"
);
const sendApprovedDeliverableNotification = httpsCallable(
  functions,
  "sendApprovedDeliverableNotification"
);

const sendDeliverableRejectionNotification = httpsCallable(
  functions,
  "sendDeliverableRejectionNotification"
);

const sendSupportMessage = httpsCallable(functions, "sendSupportMessage");

const requestApiAuthorization = httpsCallable(
  functions,
  "requestApiAuthorization"
);

const generateToken = httpsCallable(functions, "generateToken");
const getProjectFolderId = httpsCallable(functions, "getProjectFolderId");

export {
  firebaseApp,
  auth,
  firestore,
  storage,
  functions,
  sendAccountSetupMail,
  sendNewDeliverableNotification,
  sendApprovedDeliverableNotification,
  sendDeliverableRejectionNotification,
  sendSupportMessage,
  requestApiAuthorization,
  analytics,
  generateToken,
  getProjectFolderId,
};
