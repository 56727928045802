import { createContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getAllProjectDeliverables } from "../queries/deliverables";
import {
  getAllOrganisations,
  getOrganisationById,
} from "../queries/organisations";
import {
  getOrganisationProjects,
  getOrganisationSingleProject,
} from "../queries/projects";
import {
  getProjectTimelinePoints,
  getScanDatasByPointIdsArray,
  getSpatialDataByPointIdsArray,
} from "../queries/timelinePoints";
import { getAllProjectTimelapseLinks } from "../queries/timelapseLinks";

const OrganisationsContext = createContext();

const OrganisationsProvider = ({ children }) => {
  const [selectedProject, setSelectedProject] = useState();
  const [deliverablesData, setDeliverablesData] = useState([]);
  const [timelinePoints, setTimelinePoints] = useState();
  const [timelapseData, setTimelapseData] = useState([]);

  const {
    mutate: fetchOrganisations,
    data: organisations,
    isLoading: isOrganisationsLoading,
  } = useMutation(() => getAllOrganisations());

  const {
    mutate: fetchOrganisation,
    isLoading: organisationLoading,
    data: organisationData,
  } = useMutation((organisationId) => {
    setDeliverablesData([]);
    if (!organisations) {
      return getOrganisationById(organisationId);
    } else {
      const foundOrganisation = organisations.filter(
        (organisation) => organisation.id === organisationId
      );
      return foundOrganisation && foundOrganisation[0]
        ? foundOrganisation[0]
        : null;
    }
  });

  const {
    mutate: fetchOrganisationProjects,
    isLoading: isProjectsLoading,
    data: organisationProjects,
    status: organisationProjectsStatus,
  } = useMutation(() => {
    setSelectedProject();
    return getOrganisationProjects(organisationData.id);
  });

  const { mutate: fetchProject, isLoading: isProjectLoading } = useMutation(
    async (projectId) => {
      setDeliverablesData([]);
      const foundProject = await getOrganisationSingleProject(
        organisationData.id,
        projectId
      );
      if (foundProject && foundProject.length) {
        setTimelapseData();
        setTimelinePoints();
        setSelectedProject(foundProject[0]);
        return foundProject[0];
      }
      return undefined;
    }
  );

  const fetchTimelinePoints = async (deliverablesIdArray) => {
    const timelinePoints = await getProjectTimelinePoints(deliverablesIdArray);
    const timelinePointIdsArray = timelinePoints.map((point) => point.id);
    const spatialData = await getSpatialDataByPointIdsArray(
      timelinePointIdsArray
    );
    const scanData = await getScanDatasByPointIdsArray(timelinePointIdsArray);
    timelinePoints.forEach((timelinePoint) => {
      timelinePoint.twoDData = [];
      timelinePoint.threeDData = [];
      timelinePoint.scanData = [];
      if (spatialData && spatialData.length) {
        spatialData.forEach((sp) => {
          if (sp.timelinePointId === timelinePoint.id) {
            if (sp.sourceType === "KML" || sp.sourceType === "IMAGERY") {
              timelinePoint.twoDData.push(sp);
            }
            if (sp.sourceType === "TERRAIN" || sp.sourceType === "3DTILES") {
              timelinePoint.threeDData.push(sp);
            }
          }
        });
      }

      if (scanData && scanData.length) {
        scanData.forEach((sd) => {
          if (sd.timelinePointId === timelinePoint.id) {
            timelinePoint.scanData.push(sd);
          }
        });
      }
    });
    setTimelinePoints(timelinePoints);
  };

  const { isLoading: isTimelinePointsLoading, mutate: startFetchingPoints } =
    useMutation((deliverablesIdArray) =>
      fetchTimelinePoints(deliverablesIdArray)
    );

  const {
    mutate: fetchProjectDeliverables,
    isLoading: isDeliverablesLoading,
    status: deliverablesStatus,
    reset: resetProjectDeliverables,
  } = useMutation(async (projectId) => {
    setDeliverablesData([]);
    if (selectedProject && selectedProject.id === projectId) {
      const deliverables = await getAllProjectDeliverables(projectId);
      const deliverablesIdArray = [];
      deliverables.forEach((deliverable) => {
        deliverablesIdArray.push(deliverable.id);
      });
      startFetchingPoints(deliverablesIdArray);

      setDeliverablesData(deliverables);
    } else if (organisationProjects) {
      const projectsFound = organisationProjects.filter(
        (project) => project.id === projectId
      );
      if (projectsFound && projectsFound.length) {
        setSelectedProject(projectsFound[0]);
        setDeliverablesData(await getAllProjectDeliverables(projectId));
      }
    } else {
      fetchProject(projectId);
    }
  });

  const { mutate: fetchTimelapseLinks } = useMutation(async (projectId) => {
    const timelapseLinks = await getAllProjectTimelapseLinks({ projectId });
    if (timelapseLinks && timelapseLinks.length) {
      setTimelapseData(timelapseLinks);
    }
  });

  useEffect(() => {
    if (organisationData) {
      resetProjectDeliverables();
      fetchOrganisationProjects();
    }
  }, [fetchOrganisationProjects, organisationData]);

  useEffect(() => {
    if (selectedProject) {
      fetchProjectDeliverables(selectedProject.id);
      fetchTimelapseLinks(selectedProject.id);
    }
  }, [fetchProjectDeliverables, fetchTimelapseLinks, selectedProject]);

  const valuesToPassDown = {
    organisations,
    isOrganisationsLoading,
    fetchOrganisations,
    organisationData,
    organisationLoading,
    fetchOrganisation,
    organisationProjects,
    isProjectsLoading,
    organisationProjectsStatus,
    fetchOrganisationProjects,
    deliverablesData,
    isDeliverablesLoading,
    deliverablesStatus,
    fetchProjectDeliverables,
    selectedProject,
    isProjectLoading,
    fetchProject,
    timelinePoints,
    isTimelinePointsLoading,
    timelapseData,
    fetchTimelapseLinks,
  };

  return (
    <OrganisationsContext.Provider value={valuesToPassDown}>
      {children}
    </OrganisationsContext.Provider>
  );
};

export { OrganisationsContext, OrganisationsProvider };
