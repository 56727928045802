import { Button, Cascader, Form, Input, Alert } from "antd";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../contexts";
import { ROLES } from "../../enums";
import { getAllOrganisations } from "../../queries/organisations";
import { createUserByEmailAndRole, findUserByEmail } from "../../queries/users";
import "./forms.css";

const NewAccount = ({ refetch }) => {
  const [form] = Form.useForm();
  const { userInfo } = useContext(AuthContext);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState();
  const [chosenRole, setChosenRole] = useState("");
  const [accountTypes, setAccountTypes] = useState([]);

  const { isLoading: loadingOrganizations, data: organisations } = useQuery(
    "allOrganisationsNewAccount",
    getAllOrganisations,
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleSubmit = async ({ role, email, organisationId }) => {
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    if (
      userInfo &&
      userInfo.role &&
      (userInfo.role === "SUPERVISOR" || userInfo.role === "SENIOR")
    ) {
      if (userInfo && userInfo.role && userInfo.role === "SENIOR") {
        organisationId = userInfo.organisationId;
      }
      if (organisationId && organisationId.forEach) {
        organisationId = organisationId[0];
      }
      try {
        const userWithEmailExists = await findUserByEmail(email);
        if (userWithEmailExists) {
          throw new Error(`Email address ${email} is already in use`);
        }
        const response = organisationId
          ? await createUserByEmailAndRole(email, role[0], organisationId)
          : await createUserByEmailAndRole(email, role[0]);
        if (response.emailSent) {
          setSuccessMessage(
            `User created successfully & an account registration email was sent to ${email}`
          );
        } else {
          setSuccessMessage(
            `User created successfully, but the account registration email was not sent`
          );
        }
        refetch();
        form.resetFields();
      } catch (error) {
        if (error.message === `Email address ${email} is already in use`) {
          setErrorMessage(error.message);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userInfo && userInfo.role) {
      if (userInfo.role === "SUPERVISOR") {
        setAccountTypes([
          {
            value: ROLES.SUPERVISOR,
            label: "SUPERVISOR",
          },
          {
            value: ROLES.DATAPROCESSOR,
            label: "DATA-PROCESSOR",
          },
          {
            value: ROLES.SENIOR,
            label: "SENIOR",
          },
          {
            value: ROLES.SUBORDINATE,
            label: "SUBORDINATE",
          },
        ]);
      } else if (userInfo.role === "SENIOR") {
        setAccountTypes([
          {
            value: ROLES.SENIOR,
            label: "SENIOR",
          },
          {
            value: ROLES.SUBORDINATE,
            label: "SUBORDINATE",
          },
        ]);
      }
    }
  }, [userInfo]);

  return (
    <div>
      <p>
        {errorMessage && <Alert type="error" message={errorMessage} />}
        {successMessage && <Alert type="success" message={successMessage} />}
      </p>
      <Form className="form" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="role"
          rules={[
            { required: true, message: "Please select the account type" },
          ]}
        >
          <Cascader
            options={accountTypes}
            placeholder="Please select an account"
            style={{ width: "calc(100%)" }}
            onChange={(e) => {
              if (e) {
                setChosenRole(e[0]);
              } else {
                setChosenRole("");
              }
            }}
          />
        </Form.Item>
        {userInfo &&
          userInfo.role &&
          userInfo.role === "SUPERVISOR" &&
          chosenRole &&
          (chosenRole === "SENIOR" || chosenRole === "SUBORDINATE") && (
            <Form.Item
              name="organisationId"
              rules={[
                { required: true, message: "Please select an organisation" },
              ]}
            >
              <Cascader
                loading={loadingOrganizations}
                options={organisations}
                showSearch={true}
                placeholder="Please select an organisation"
                style={{ width: "calc(100%)" }}
              />
            </Form.Item>
          )}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input a valid email",
              type: "email",
            },
          ]}
          style={{
            width: "calc(100%)",
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{
            width: "calc(100%)",
          }}
        >
          <Button
            style={{ width: "100%" }}
            className="login-button"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { NewAccount };
