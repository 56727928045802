import { Row, Skeleton } from "antd";

const ProjectBarLoading = () => {
  return (
    <Row>
      <Skeleton.Input
        active
        block={true}
        style={{ backgroundColor: "#fff", marginTop: 3 }}
      />
    </Row>
  );
};

export { ProjectBarLoading };
