import { Card, Skeleton } from "antd";
import Meta from "antd/lib/card/Meta";

const ProjectLoading = () => {
  const size = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div>
      <div className="projects-list">
        {size.map((el) => (
          <Card
            key={String(el)}
            style={{
              width: 130,
            }}
            cover={
              <Skeleton.Image active style={{ height: 120, width: 130 }} />
            }
          >
            <Meta
              title={
                <Skeleton.Input
                  active
                  size={18}
                  style={{ margin: 2, borderRadius: 4 }}
                />
              }
            ></Meta>
          </Card>
        ))}
      </div>
    </div>
  );
};

export { ProjectLoading };
