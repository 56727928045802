import { useContext, useState } from "react";
import {
  QuestionCircleOutlined,
  DashboardOutlined,
  MenuOutlined,
  CaretDownFilled,
} from "@ant-design/icons";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Dropdown, Space, Image, Layout, Menu } from "antd";
import "./dashboard.css";
import { Profile } from "../profile";
import { AuthContext } from "../../contexts";
import headerLogo from "../../images/header-logo.png";
const { Content, Sider, Header } = Layout;

const Dashboard = ({ homeLink, menuInterface, organizationInterface }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  const { userInfo } = useContext(AuthContext);

  return (
    <Layout style={{ minHeight: "100vh" }} className="tour-element" id='parent-dashboard'>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="dashboard-sider"
      >
        {organizationInterface}
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item style={{ cursor: "default" }} title={false}>
            <p className="side-menu-headers">Core</p>
          </Menu.Item>
          <Menu.Item key="1" icon={<DashboardOutlined />}>
            <Link to={homeLink} style={{ textDecoration: "none" }} >
              Dashboard
            </Link>
          </Menu.Item>
          <Menu.Item style={{ cursor: "default" }} title={false}>
            <p className="side-menu-headers">Interface</p>
          </Menu.Item>
          {menuInterface}
          <Menu.Item key="5" icon={<QuestionCircleOutlined />} id="third-step">
            <Link to={`${homeLink}/support`} style={{ textDecoration: "none" }} >
              Support
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <div className="header-content">
            {collapsed ? (
              <MenuOutlined
                className="trigger"
                onClick={() => setCollapsed(!collapsed)}
              />
            ) : (
              <MenuOutlined
                className="trigger"
                onClick={() => setCollapsed(!collapsed)}
              />
            )}
            <div className="header-logo">
              <img src={headerLogo} className="middle-logo" alt="" />
            </div>
            <Dropdown overlay={<Profile />} trigger={["click"]}>
              <Space>
                <Image
                  id="fifth-step"
                  preview={false}
                  src={
                    userInfo.profilePhoto
                      ? userInfo.profilePhoto
                      : "https://firebasestorage.googleapis.com/v0/b/intel-construction.appspot.com/o/icons%2Fuser-icon.jpg?alt=media&token=de1df897-6f5d-4c78-aeb6-f5a87afb4414"
                  }
                  style={{
                    height: 30,
                    width: 30,
                    borderRadius: 50,
                    boxShadow: "0 0 0 1px lightgrey",
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                />
                <CaretDownFilled style={{ color: "grey" }} />
              </Space>
            </Dropdown>
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            height: "100%",
          }}
        >
          <Outlet key={`${pathname}`} />
        </Content>
      </Layout>
    </Layout>
  );
};

export { Dashboard };
