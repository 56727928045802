import { Button, Form, Input, InputNumber } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { IonResource } from "cesium";

const TwoDFormList = ({
  timelinePointForm,
  setTwoDFormError,
  setErrorMessage,
}) => {
  return (
    <div>
      <Form.List name="twoDSources">
        {(fields, { add, remove }, { errors }) => (
          <div>
            {fields.map(({ key, name, ...restField }) => (
              <div
                key={key}
                style={{
                  marginBottom: 8,
                }}
              >
                <Form.Item
                  {...restField}
                  name={[name, "sourceId"]}
                  rules={[
                    {
                      required: true,
                      message: "Please add a source-layer ID",
                      validator: (_, value) => {
                        if (value) {
                          setTwoDFormError("");
                          return Promise.resolve();
                        } else {
                          setTwoDFormError(
                            "Please add a valid source-layer ID"
                          );
                          return Promise.reject(
                            "Please add a valid source-layer ID"
                          );
                        }
                      },
                    },
                  ]}
                  style={{
                    display: "inline-block",
                    width: "calc(20%)",
                  }}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    type="number"
                    placeholder="Source ID"
                    onChange={async (value) => {
                      setErrorMessage("");
                      setTwoDFormError("");
                      if (value && value !== "") {
                        let { twoDSources, ...rest } =
                          timelinePointForm.getFieldsValue();
                        try {
                          const resource = await IonResource.fromAssetId(value);
                          const { type } = resource._ionEndpoint;

                          if (type === "TERRAIN" || type === "3DTILES") {
                            setTwoDFormError(
                              "Non-2D-data was found! Only KML or IMAGERY can be added as 2D data"
                            );
                            setErrorMessage(
                              "Non-2D-data was found! Only KML or IMAGERY can be added as 2D data"
                            );
                            throw new Error(
                              "Only KML or IMAGERY can be added as 2D data"
                            );
                          }

                          const layerExists = twoDSources.filter(
                            (lyr) => lyr?.sourceId === value
                          );
                          if (layerExists && layerExists.length === 1) {
                            twoDSources.forEach((source, index) => {
                              if (source.sourceId === value) {
                                twoDSources[index].sourceType = type;
                              }
                            });
                            timelinePointForm.setFieldsValue({
                              twoDSources,
                              ...rest,
                            });
                          } else {
                            setTwoDFormError("Duplicate Ids found!");
                            setErrorMessage("Duplicate Ids found!");
                          }
                        } catch (error) {
                          twoDSources.forEach((source, index) => {
                            if (source.sourceId === value) {
                              twoDSources[index].sourceType = null;
                            }
                          });
                          timelinePointForm.setFieldsValue({
                            twoDSources,
                            ...rest,
                          });
                        }
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "sourceType"]}
                  wrapperCol={{ offset: 1 }}
                  style={{
                    display: "inline-block",
                    width: "calc(30%)",
                  }}
                  rules={[
                    {
                      required: true,
                      message:
                        "A valid source type is required for non-duplicate ids",
                      validator: (_, value) => {
                        if (value) {
                          setTwoDFormError("");
                          return Promise.resolve();
                        } else {
                          setTwoDFormError(
                            "A valid source type is required for non-duplicate ids"
                          );
                          return Promise.reject(
                            "A valid source type is required for non-duplicate ids"
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input disabled suffix={""} />
                </Form.Item>
                <Form.Item
                  name={[name, "sourceName"]}
                  rules={[
                    {
                      required: true,
                      message: "Please add the source's name",
                      validator: (_, value) => {
                        if (value) {
                          setTwoDFormError("");
                          return Promise.resolve();
                        } else {
                          setTwoDFormError("Please add the source's name");
                          return Promise.reject("Please add the source's name");
                        }
                      },
                    },
                  ]}
                  wrapperCol={{ offset: 1 }}
                  style={{
                    display: "inline-block",
                    width: "calc(40%)",
                  }}
                >
                  <Input placeholder="Source Name" />
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    style={{ position: "relative", left: "20px" }}
                    onClick={() => {
                      setTwoDFormError("");
                      setErrorMessage("");
                      remove(name);
                    }}
                  />
                ) : null}
              </div>
            ))}
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(100%)",
              }}
            >
              <Button
                type="dashed"
                onClick={() => add()}
                block
              >
                Add 2D Layer
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </div>
        )}
      </Form.List>
    </div>
  );
};

export { TwoDFormList };
