import { Button, Form, Input, InputNumber } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { IonResource } from "cesium";

const ThreeDFormList = ({
  timelinePointForm,
  setThreeDFormError,
  setErrorMessage,
}) => {
  return (
    <div>
      <Form.List name="threeDSources">
        {(fields, { add, remove }, { errors }) => (
          <div>
            {fields.map(({ key, name, ...restField }) => (
              <div
                key={key}
                style={{
                  marginBottom: 8,
                }}
              >
                <Form.Item
                  {...restField}
                  name={[name, "sourceId"]}
                  rules={[
                    {
                      required: true,
                      message: "Please add a source-layer ID",
                      validator: (_, value) => {
                        if (value) {
                          setThreeDFormError("");
                          return Promise.resolve();
                        } else {
                          setThreeDFormError("Please add a source-layer ID");
                          return Promise.reject("Please add a source-layer ID");
                        }
                      },
                    },
                  ]}
                  style={{
                    display: "inline-block",
                    width: "calc(20%)",
                  }}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    type="number"
                    placeholder="Source ID"
                    onChange={async (value) => {
                      setThreeDFormError("");
                      setErrorMessage("");
                      if (value && value !== "") {
                        let { threeDSources, ...rest } =
                          timelinePointForm.getFieldsValue();
                        try {
                          const resource = await IonResource.fromAssetId(value);
                          const { type } = resource._ionEndpoint;

                          if (type === "KML" || type === "IMAGERY") {
                            setThreeDFormError(
                              "Non-3D-data was found! Only TERRAIN or 3DTILES can be added as 3D data"
                            );
                            setErrorMessage(
                              "Non-3D-data was found! Only TERRAIN or 3DTILES can be added as 3D data"
                            );
                            throw new Error(
                              "Non-3D-data was found! Only TERRAIN or 3DTILES can be added as 3D data"
                            );
                          }

                          const layerExists = threeDSources.filter(
                            (lyr) => lyr?.sourceId === value
                          );
                          if (layerExists && layerExists.length === 1) {
                            threeDSources.forEach((source, index) => {
                              if (source.sourceId === value) {
                                threeDSources[index].sourceType = type;
                              }
                            });
                            timelinePointForm.setFieldsValue({
                              threeDSources,
                              ...rest,
                            });
                          } else {
                            setThreeDFormError("Duplicate Ids found!");
                            setErrorMessage("Duplicate Ids found!");
                          }
                        } catch (error) {
                          threeDSources.forEach((source, index) => {
                            if (source.sourceId === value) {
                              threeDSources[index].sourceType = null;
                            }
                          });
                          timelinePointForm.setFieldsValue({
                            threeDSources,
                            ...rest,
                          });
                        }
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "sourceType"]}
                  wrapperCol={{ offset: 1 }}
                  style={{
                    display: "inline-block",
                    width: "calc(30%)",
                  }}
                  rules={[
                    {
                      required: true,
                      message:
                        "A valid source type is required for non-duplicate ids",
                      validator: (_, value) => {
                        if (value) {
                          setThreeDFormError("");
                          return Promise.resolve();
                        } else {
                          setThreeDFormError(
                            "A valid source type is required for non-duplicate ids"
                          );
                          return Promise.reject(
                            "A valid source type is required for non-duplicate ids"
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input disabled suffix={""} />
                </Form.Item>
                <Form.Item
                  name={[name, "sourceName"]}
                  rules={[
                    {
                      required: true,
                      message: "Please add the source's name",
                      validator: (_, value) => {
                        if (value) {
                          setThreeDFormError("");
                          return Promise.resolve();
                        } else {
                          setThreeDFormError("Please add the source's name");
                          return Promise.reject("Please add the source's name");
                        }
                      },
                    },
                  ]}
                  wrapperCol={{ offset: 1 }}
                  style={{
                    display: "inline-block",
                    width: "calc(40%)",
                  }}
                >
                  <Input placeholder="Source Name" />
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    style={{ position: "relative", left: "20px" }}
                    onClick={() => {
                      setErrorMessage("");
                      setThreeDFormError("");
                      remove(name);
                    }}
                  />
                ) : null}
              </div>
            ))}
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(100%)",
              }}
            >
              <Button
                type="dashed"
                onClick={() => add()}
                block
              >
                Add 3D Layer
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </div>
        )}
      </Form.List>
    </div>
  );
};

export { ThreeDFormList };
