import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const DataProcessorDashboard = lazy(() =>
  import("../components/dashboard").then((modules) => {
    return { default: modules.DataProcessorDashboard };
  })
);

const AllDeliverables = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.AllDeliverables };
  })
);
const DataProcessorLanding = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.DataProcessorLanding };
  })
);
const DeliverableContents = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.DeliverableContents };
  })
);
const OrganisationProjects = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.OrganisationProjects };
  })
);
const SuperLevelCesiumTimeline = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.SuperLevelCesiumTimeline };
  })
);
const SuperScanLevel = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.SuperScanLevel };
  })
);
const Timelapse = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.Timelapse };
  })
);
const Support = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.Support };
  })
);
const ProtectedView = lazy(() =>
  import("../views/ProtectedView").then((modules) => {
    return { default: modules.ProtectedView };
  })
);
const OrganisationProjectsOutlet = lazy(() =>
  import("../views/shared").then((modules) => {
    return { default: modules.OrganisationProjectsOutlet };
  })
);
const ProjectDeliverables = lazy(() =>
  import("../views/shared").then((modules) => {
    return { default: modules.ProjectDeliverables };
  })
);

const DataProcessorRoutes = () => {
  return (
    <Routes>
      <Route
        path="/dataprocessorDashboard"
        element={
          <ProtectedView
            element={
              <Suspense>
                <DataProcessorDashboard />
              </Suspense>
            }
          />
        }
      >
        <Route
          index={true}
          element={
            <ProtectedView
              element={
                <Suspense>
                  <DataProcessorLanding />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="organisations/:organisationId/projects"
          element={
            <ProtectedView
              element={
                <Suspense>
                  <OrganisationProjects />
                </Suspense>
              }
            />
          }
        >
          <Route
            index={true}
            element={
              <ProtectedView
                element={
                  <Suspense>
                    <OrganisationProjectsOutlet />
                  </Suspense>
                }
              />
            }
          />
          <Route path=":projectId">
            <Route
              path="deliverables"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <AllDeliverables />
                    </Suspense>
                  }
                />
              }
            >
              <Route
                index={true}
                element={
                  <ProtectedView
                    element={
                      <Suspense>
                        <ProjectDeliverables />
                      </Suspense>
                    }
                  />
                }
              />
              <Route
                path=":deliverableId/:contentsPath"
                element={
                  <ProtectedView
                    element={
                      <Suspense>
                        <DeliverableContents />
                      </Suspense>
                    }
                  />
                }
              />
            </Route>
          </Route>
        </Route>
        <Route path="organisations/:organisationId/projects/:projectId">
          <Route path="timeline">
            <Route
              path="2D"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <SuperLevelCesiumTimeline />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="3D"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <SuperLevelCesiumTimeline />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="3DScan"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <SuperScanLevel />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="timelapse"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <Timelapse />
                    </Suspense>
                  }
                />
              }
            />
          </Route>
        </Route>
        <Route
          path="support"
          element={
            <ProtectedView
              element={
                <Suspense>
                  <Support />
                </Suspense>
              }
            />
          }
        />
      </Route>
    </Routes>
  );
};

export { DataProcessorRoutes };
