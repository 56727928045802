import { Menu, Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts";
import { EditProfile } from "../forms";

const Profile = () => {
  const { userInfo, signout } = useContext(AuthContext);
  const [showEditInfoModal, setShowEditInfoModal] = useState(false);
  const handleEditInfoClose = () => setShowEditInfoModal(false);
  const handleEditInfoShow = () => setShowEditInfoModal(true);

  return (
    <Menu
      items={[
        {
          key: "profile-image",
          label: (
            <div
              className="user-detail-dropdown"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                alt=""
                src={
                  userInfo.profilePhoto
                    ? userInfo.profilePhoto
                    : "https://firebasestorage.googleapis.com/v0/b/intel-construction.appspot.com/o/icons%2Fuser-icon.jpg?alt=media&token=de1df897-6f5d-4c78-aeb6-f5a87afb4414"
                }
                style={{
                  height: 150,
                  width: 150,
                  borderRadius: 100,
                  objectFit: "cover",
                  boxShadow: "0 0 0 1px lightgrey",
                }}
              />
              <h4>
                {`${userInfo.firstName} ${userInfo.lastName}`}{" "}
                <EditOutlined
                  className="edit-profile"
                  onClick={(e) => {
                    handleEditInfoShow();
                  }}
                />
              </h4>
              <p>{userInfo.role}</p>
            </div>
          ),
        },
        {
          key: "profile-divider",
          type: "divider",
        },
        {
          key: "logout-btn",
          label: (
            <div className="logout-button" onClick={signout}>
              Logout
            </div>
          ),
          key: "3",
        },
        {
          key: "edit-profile",
          label: (
            <Modal
              open={showEditInfoModal}
              footer={false}
              onCancel={handleEditInfoClose}
              maskClosable={false}
              width={300}
              zIndex={10000}
              className="edit-profile-container"
            >
              <EditProfile selfClose={handleEditInfoClose} />
            </Modal>
          ),
        },
      ]}
    />
  );
};

export { Profile };
